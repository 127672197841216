<template>
    <div>
        <div style="loading-button" v-if="loading">
            <div class="loading"></div>
        </div>
        <button v-else :class="['obutton', buttonStyle]" spacing="default" type="button" :disabled="disabled">
            <span style="align-self: center; display: inline-flex; flex-wrap: nowrap; max-width: 100%; position: relative;">
                <span style="align-items: center; align-self: center; flex: 1 1 auto; margin: 0px 4px; max-width: 100%; overflow: hidden; text-overflow: ellipsis; white-space: nowrap; transition: opacity 0.3s ease 0s; opacity: 1;">
                    <span class="content"><slot></slot></span>
                </span>
            </span>
        </button>
    </div>
</template>

<script>
import Vue from 'vue';

export default {
  name: 'OButton',
  props: {
    buttonStyle: {
        default: 'primary',
        type: String
    },
    disabled: {
        default: false,
        type: Boolean
    },
    loading: {
        default: false,
        type: Boolean
    }
  },
  components: {
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
.loading-button {
    height: 2.28571em;
    width: 32px;
    box-sizing: border-box;
    line-height: 2.28571em;
    margin: 0px;
    max-width: 100%;
    background-color: #fff !important;
}
.obutton {
    -moz-box-align: baseline;
    align-items: baseline;
    background: rgb(240, 240, 240) none repeat scroll 0% 0%;
    border-radius: 4px;
    border-width: 0px;
    box-sizing: border-box;
    color: rgb(51, 51, 51) !important;
    cursor: default;
    display: block;
    box-sizing: border-box;
    font-size: inherit;
    font-style: normal;
    height: 2.28571em;
    line-height: 2.28571em;
    max-width: 100%;
    outline: currentcolor none medium !important;
    padding: 0px 8px;
    text-align: center;
    text-decoration: none;
    transition: background 0.1s ease-out 0s, box-shadow 0.15s cubic-bezier(0.47, 0.03, 0.49, 1.38) 0s;
    vertical-align: middle;
    white-space: nowrap;
    width: 100%;
    .content {
        display: inline-block;
        padding: 0px 0px;
        font-size: 16px;
        font-weight: bolder;
    }
    .feather {
        top: 4px;
        position: relative;
    }
}

.obutton:disabled {
    opacity: 0.6;
    cursor: not-allowed !important;
}

.obutton:hover {
    background: rgb(220, 220, 220) none repeat scroll 0% 0%;
    cursor: pointer;
}

.obutton:active {
    background: rgb(200, 200, 200) none repeat scroll 0% 0%;
}

.primary, .primary-icon {
    background: rgb(246,214,88) none repeat scroll 0% 0%;
    color: black !important;
}

.primary:hover, .primary-icon:hover {
    background: darken(rgb(246,214,88), 5%) none repeat scroll 0% 0%;
    cursor: pointer;
}

.primary:active, .primary-icon:active {
    background: rgb(0, 61, 204) none repeat scroll 0% 0%;
}

.primary:disabled {
    background: rgb(0, 82, 204) none repeat scroll 0% 0%;
}

.icon {
    background: none;
    color: rgb(0, 82, 204) !important;
    height: 36px;
    width: 36px;
    padding: 0px;
    margin: 0px;
    .content {
        padding: 4px 0px 0px 0px;
    }
}

.icon:hover {
    background: none;
    color: rgb(0, 0, 0) !important;
    cursor: pointer;
}

.primary-icon {
    padding-left: 26px;
    padding-right: 0px;
    .feather {
        color: rgb(255, 255, 255);
        height: 34px;
        width: 34px;
        padding: 4px;
        top: 1px;
        left: -24px;
        position: absolute;
    }
}

</style>
