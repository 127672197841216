<template>
  <v-container>
    <v-app-bar app color="black" flat>
      <v-container class="py-0 fill-height">
        <a href="https://app.bounce.page" title="BouncePage">
          <v-img
            max-height="80"
            max-width="200"
            src="../assets/img/mark-circle.svg"
          ></v-img>
        </a>
        <v-spacer></v-spacer>
        <v-btn text color="primary" :to="{ name: 'signup' }"> Sign up </v-btn>
      </v-container>
    </v-app-bar>
    <v-container class="mt-15">
      <v-row class="justify-center d-flex">
        <v-col class="login">
          <v-card shaped>
            <v-card-title> Sign in to your account </v-card-title>
            <v-card-text class="mt-2">
              <div id="google-signin"></div>
            </v-card-text>
            <v-row wrap no-gutters class="mt-6">
              <v-col>
                <v-divider class="mt-2" />
              </v-col>
              <v-col class="text-center"> or login with email/password </v-col>
              <v-col>
                <v-divider class="mt-2" />
              </v-col>
            </v-row>
            <v-card-text>
              <v-alert border="left" dense type="error" v-show="error"
                >Invalid username or password.</v-alert
              >
              <v-form ref="form" v-on:submit.prevent="login">
                <v-text-field
                  v-model="data.body.username"
                  label="Email Address"
                  required
                  autocomplete="username"
                  type="email"
                  :rules="usernameRules"
                ></v-text-field>

                <v-text-field
                  v-model="data.body.password"
                  label="Password"
                  type="password"
                  autocomplete="current-password"
                  v-on:keyup.enter="login"
                ></v-text-field>
                <v-card-actions class="d-flex justify-end">
                  <v-btn
                    text
                    small
                    color="secondary"
                    @click="() => this.$router.push({ name: 'forgot' })"
                  >
                    Forgot your password?
                  </v-btn>
                  <v-btn color="primary" @click="login" :disabled="!valid">
                    Log in
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text>
            <v-overlay absolute :value="isLoading">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Vue from "vue";

const emailPattern = new RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

export default {
  name: "Login",
  data() {
    return {
      context: "login",
      data: {
        body: {
          username: "",
          password: "",
        },
      },
      isLoading: false,
      error: null,
      usernameRules: [
        (v) =>
          (v && emailPattern.test(v)) || "Please enter a valid email address.",
      ],
    };
  },
  computed: {
    valid: function () {
      return this.data.body.username !== "" && this.data.body.password !== "";
    },
  },
  mounted() {
    if (this.$auth.check()) {
      console.log("authentication successful");
      this.$router.push({ name: "editor_list" });
    } else {
      // mount google auth
      this.$nextTick(() => {
        (async() => {
          // eslint-disable-next-line
          while(!!window && !window.hasOwnProperty("google"))
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.mountGoogle();
        })();
      });
    }
  },
  methods: {
    mountGoogle() {
      window.google.accounts.id.initialize({
        client_id:
          "787550464232-pviup6i15r5e3humsl1igeri0vmotsg6.apps.googleusercontent.com",
        callback: this.gSignin,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("google-signin"),
        { theme: "outline", size: "large", shape: "pill" }
      );
      window.google.accounts.id.prompt(); // also display the One Tap dialog
    },
    login() {
      this.isLoading = true;
      this.$auth
        .login({
          body: this.data.body,
          data: this.data.body,
          redirect: "/dashboard/editor",
          fetchUser: true,
        })
        .then(
          () => {
            this.isLoading = false;
            console.log("success " + this.context);
          },
          (res) => {
            this.isLoading = false;
            console.log(res);
            console.log("error " + this.context);
            this.error = res;
          }
        );
    },
    gSignin(response) {
      this.isLoading = true;
      console.log("signing in with google");
      Vue.axios
        .post("/auth/logingoogle", {
          token: response.credential,
        })
        .then((res) => {
          this.$auth.token(null, res.data.accessToken);
          this.isLoading = false;
          window.location.reload();
        })
        .catch((err) => {
          this.isLoading = false;
          this.error = err;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  max-width: 600px;
}
$primary-color: #001f3a !default;
$primary-color-light: lighten($primary-color, 45%) !default;
a {
  color: $primary-color-light;
}
</style>
