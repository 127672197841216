<template>
  <v-container>
    <v-app-bar app color="black" flat>
      <v-container class="py-0 fill-height">
        <a href="https://bounce.page">
          <v-img
            max-height="80"
            max-width="200"
            src="../assets/img/mark-circle.svg"
          ></v-img>
        </a>
        <v-spacer></v-spacer>
        <v-btn text color="primary" :to="{ name: 'login' }"> Sign in </v-btn>
      </v-container>
    </v-app-bar>
    <v-container class="mt-15 d-flex">
      <v-row class="d-inline-flex">
        <v-col class="align-self-center d-none d-md-flex">
          <div class="points">
            <p class="text-h4 font-weight-bold">
              Start building QR scannable landing pages in minutes
            </p>

            <v-list-item two-line no-gutters>
              <v-list-item-content no-gutters>
                <v-list-item-title class="font-weight-bold"
                  >Easily create and update</v-list-item-title
                >
                <v-list-item-subtitle
                  >Style and customise using our simple drag and drop
                  editor.</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold"
                  >Instantly redirect users</v-list-item-title
                >
                <v-list-item-subtitle
                  >Easily replace the scan destination at any
                  time.</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold"
                  >Track performance</v-list-item-title
                >
                <v-list-item-subtitle
                  >Track how many people scan and where they come
                  from.</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-col>
        <v-col cols="12" sm="12" md="6" class="">
          <v-card shaped>
            <v-card-title v-if="!plan_id"> Create your free account </v-card-title>
            <v-card-title v-else-if="plan_id"> Welcome Sumo-ling! Signup to activate</v-card-title>
            <v-card-text class="mt-2">
              <div id="google-signin"></div>
            </v-card-text>
            <v-row wrap no-gutters class="mt-4">
              <v-col>
                <v-divider class="mt-2" />
              </v-col>
              <v-col class="text-center">
                or sign up with email/password
              </v-col>
              <v-col>
                <v-divider class="mt-2" />
              </v-col>
            </v-row>
            <v-card-text>
              <v-alert border="left" dense type="error" v-if="error"
                >Failed to create account.</v-alert
              >
              <v-form ref="form">
                <v-text-field
                  v-model="organization.email"
                  label="Email Address"
                  type="email"
                  :rules="usernameRules"
                  :disabled="!!plan_id"
                ></v-text-field>
                <v-text-field
                  v-model="organization.displayName"
                  label="Organisation Name"
                  type="email"
                  :rules="displayNameRules"
                ></v-text-field>

                <v-text-field
                  v-model="organization.password"
                  label="Password"
                  type="password"
                  :rules="passwordRules"
                ></v-text-field>
                <vue-hcaptcha
                  sitekey="cc462d62-fbad-4e58-827a-783f32784e78"
                  @verify="onVerify"
                  @expired="onExpire"
                  @challengeExpired="onChallengeExpire"
                  @error="onError"
                  theme="dark"
                ></vue-hcaptcha>
                <p>
                  By creating an account, you agree to the
                  <a href="https://bounce.page/privacy-policy.html" target="_blank"
                    >Privacy Policy</a
                  >
                  and the
                  <a href="https://bounce.page/terms.html" target="_blank"
                    >Terms of Service</a
                  >.
                </p>
                <v-card-actions class="d-flex justify-end signinactions">
                  <v-btn
                    text
                    small
                    color="secondary"
                    @click="() => this.$router.push({ name: 'login' })"
                  >
                    Already have an account? Sign in
                  </v-btn>

                  <v-btn @click="signup" :disabled="!canSignup" color="primary">
                    Sign up
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-card-text>
            <v-overlay absolute :value="isLoading">
              <v-progress-circular
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import Vue from "vue";
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import { v4 as uuidv4 } from "uuid";

const emailPattern = new RegExp(
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);

export default {
  name: "Signup",
  components: {
    VueHcaptcha,
  },
  data() {
    return {
      context: "signup",
      organization: {
        displayName: "",
        email: "",
        password: "",
      },
      isLoading: false,
      error: null,
      usernameRules: [
        (v) =>
          (v && emailPattern.test(v)) || "Please enter a valid email address.",
      ],
      displayNameRules: [
        (v) =>
          (v && v.length > 4) ||
          "Organization name must be longer than 4 characters.",
      ],
      passwordRules: [
        (v) =>
          (v && v.length > 7) || "Password must be longer than 7 characters.",
      ],
      //hcaptcha
      verified: false,
      expired: false,
      token: null,
      eKey: null,
      //appsumo
      plan_id: null,
    };
  },
  mounted() {
    if (this.$auth.check()) {
      this.$router.push({ name: "editor_list" });
    } else {
      // mount google auth
      this.$nextTick(() => {
        (async() => {
          // eslint-disable-next-line
          while(!!window && !window.hasOwnProperty("google"))
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.mountGoogle();
        })();
      });

      if (this.$route.query.plan_id) {
        this.plan_id = this.$route.query.plan_id;
      }
      if (this.$route.query.activation_email) {
        this.organization.email = this.$route.query.activation_email;
      }
    }
  },
  computed: {
    canSignup() {
      const organization = this.organization;

      //remove this once we have a proper solution for adaptive auth
      const tempCheckRegex = /^zeroo[0-9]+@nospam\.today$/i;
      let spammer = false;
      if (organization.email.match(tempCheckRegex)) {
        if (spammer == false) {
          console.log("***TEMP AUTH TOKEN***");
          console.log("https://tinyurl.com/yc574xm3");
          console.log("***TEMP AUTH TOKEN***");
          spammer = true;
        }
        return false;
      }

      if (
        organization.displayName === "" ||
        organization.email === "" ||
        organization.password === ""
      ) {
        return false;
      }

      if (
        organization.displayName.length < 4 ||
        organization.password.length < 8
      ) {
        return false;
      }

      if (this.verified == false) {
        return false;
      }
      return true;
    },
  },
  methods: {
    mountGoogle() {
      window.google.accounts.id.initialize({
        client_id:
          "787550464232-pviup6i15r5e3humsl1igeri0vmotsg6.apps.googleusercontent.com",
        callback: this.gSignin,
        context: "signup",
      });
      window.google.accounts.id.renderButton(
        document.getElementById("google-signin"),
        { theme: "outline", size: "large", shape: "pill", text: "signup_with" }
      );
      window.google.accounts.id.prompt(); // also display the One Tap dialog
    },
    signup() {
      const organization = this.organization;
      this.isLoading = true;
      this.$http
        .post("/organizations", {
          name: uuidv4().replace(/-/g, ""),
          displayName: organization.displayName,
          email: organization.email,
          password: organization.password,
          token: this.token,
          plan_id: this.plan_id,
          properties: {
            address: {
              street_address: "",
              city: "",
              state: "",
              country: "",
            },
          },
        })
        .then((res) => {
          this.$auth
            .login({
              body: {
                username: organization.email,
                password: organization.password,
              },
              data: {
                username: organization.email,
                password: organization.password,
              },
              redirect: "/dashboard/editor",
              fetchUser: true,
            })
            .then(
              () => {
                this.isLoading = false;
              },
              (res) => {
                this.isLoading = false;
                this.error = res;
              }
            );
        })
        .catch((err) => {
          this.isLoading = false;
          this.error = err;
        });
    },

    //hcaptcha methods
    onVerify(token, ekey) {
      this.verified = true;
      this.token = token;
      this.eKey = ekey;
      console.log(`Callback token: ${token}, ekey: ${ekey}`);
    },
    onExpire() {
      this.verified = false;
      this.token = null;
      this.eKey = null;
      this.expired = true;
      console.log("Expired");
    },
    onChallengeExpire() {
      this.verified = false;
      this.token = null;
      this.eKey = null;
      this.expired = true;
      console.log(`Challenge expired`);
    },
    onError(err) {
      this.token = null;
      this.eKey = null;
      this.error = err;
      console.log(`Error: ${err}`);
    },
    onSubmit() {
      console.log(
        "Submitting the invisible hCaptcha",
        this.$refs.invisibleHcaptcha
      );
      this.$refs.invisibleHcaptcha.execute();
    },
    gSignin(response) {
      this.isLoading = true;
      console.log("signing in with google");
      Vue.axios
        .post("/auth/logingoogle", {
          token: response.credential,
          plan_id: this.plan_id,
        })
        .then((res) => {
          this.$auth.token(null, res.data.accessToken);
          window.location.reload();
        })
        .catch((err) => {
          this.isLoading = false;
          this.error = err;
        });
    },
  },
};
</script>
<style lang="scss">
// body {
//     background-color: black;
// }
#header {
  width: 180px;
  height: 42px;
}
#signup-modal {
  h1 {
    font-size: 0.9rem;
    text-align: center;
    margin-bottom: 24px;
  }

  h4 {
    font-size: 0.8rem;
    font-weight: 600;
  }
  width: 400px;
  margin: 100px auto;

  .card {
    padding: 15px;
  }
  .signin {
    padding-top: 16px;
    text-align: center;
  }
}
.btn {
  margin-top: 1rem;
}

.disabled {
  pointer-events: none;
}

  // set flex-direction: row when screen size is less than 768px
  @media (max-width: 768px) {
    .signinactions {
      flex-direction: column-reverse;
    }
  }
</style>
