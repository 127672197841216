<template>
    <div class="container grid-lg">
        <modals-container>
        </modals-container>
        <div class="columns">
            <div class="column col-12">
                <div class="card">
                    <div class="content" v-if="loaded">
                        <div class="columns">
                            <div class="col-12 button-top">
                                <button class="button-tool" ><plus-circle-icon class="icon"></plus-circle-icon> Undetermined Admin Functionality</button>
                            </div>
                        </div>
                    </div>
                    <div v-if="!loaded" class="loading loading-lg"></div>
                    <div v-else>
                        <vue-good-table
                        theme="nocturnal" 
                        :columns="columns"
                        :rows="admin"
                        :search-options="{
                            enabled: true
                        }"
                        :pagination-options="{
                            enabled: true
                        }"
                        @on-row-click="openAdmin"
                        >
                        <template slot="table-row" slot-scope="props">
                            {{props.formattedRow[props.column.field]}}
                        </template>
                        <div slot="emptystate">
                            No orgs found. (this is probably an error, or it's been like 50 years and one of us is spinning up the dashboard for old times' sake)
                        </div>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { PlusCircleIcon } from 'vue-feather-icons';

import bugsnagClient from '../utils/bugsnag';

export default {
    name: 'Admin',
    components: {
        PlusCircleIcon
    },
    data() {
        return {
            columns: [
                {
                    label: 'Name',
                    field: 'name'
                },
                {
                    label: 'Display Name',
                    field: 'displayName'
                },
                {
                    label: 'Created On',
                    field: 'createdAt',
                    type: 'date',
                    dateInputFormat: 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSS\'Z\'',
                    dateOutputFormat: 'MMM dd, yyyy HH:mm'
                }
            ],
            loaded: false,
            params: {},
        };
    },
    computed: {
        ...mapGetters('admin', {
            admin: 'admin'
        })
    },
    methods: {
        ...mapActions('admin', [
          'loadAdmin'
        ]),
        openAdmin(params) {
            this.$router.push({ name: 'adminOrgs', params: { id: params.row.id } })
        }
    },
    created() {
        this.loadAdmin().then(() => {
            this.loaded = true;
        }).catch((err) => {
            bugsnagClient.notify(err);
            this.$notify({
                type: 'error',
                title: 'Failed to retrieve orgs.',
                text: 'Please try again later.'
            })
        });
    }
}
</script>