<template>
  <v-app>
    <notifications position="bottom right" width="300px">
      <template slot="body" slot-scope="{ item, close }">
        <v-alert
          dense
          :type="item.type"
          @input="close"
        >
          {{ item.title }}
        </v-alert>
      </template>
    </notifications>
    <router-view></router-view>
  </v-app>
</template>

<script>
import './assets/scss/main.scss';
import 'vue-good-table/dist/vue-good-table.css';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import 'shepherd.js/dist/css/shepherd.css';

export default {
  name: 'App',
  components: {},
  methods: {
    logout: function(e){
      this.$auth.logout({
        redirect: '/login'
      });
    }
  }
};
</script>
