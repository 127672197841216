<template>
<v-dialog
    v-model="dialog"
    max-width="700"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="primary"
      >
        {{ !redirectionEnabled ? 'Redirect page' : 'Redirect settings' }}
      </v-btn>
    </template>
    <v-card :loading="!loaded">
      <v-card-title>
        Redirect this page
        <v-spacer />
        <v-chip
          v-if="!redirectionEnabled"
          color="red"
        >Redirection turned off</v-chip>
        <v-chip
          v-else
          color="green"
        >Redirection enabled</v-chip>
      </v-card-title>
      <v-card-text>
        Page redirection bypasses your Bounce Page and sends your visitors directly to a link of your choosing.
        <v-form ref="form" class="mt-2">
          <v-text-field
            v-model="link.uri"
            :rules="uriRules"
            label="Default redirect destination for Page"
            type="uri"
          ></v-text-field>
          <v-banner
            single-line
          >
            <v-icon
              slot="icon"
              color="warning"
              size="36"
            >
              mdi-clipboard-list
            </v-icon>
            Link rules are executed in order from top to bottom
          </v-banner>
          <draggable
            class="rules"
            :list="draftLink.rules"
            handle=".handle"
          >
            <v-card
              v-for="(rule, ruleIdx) in draftLink.rules"
              :key="'rule'+ruleIdx"
            > 
              <v-card-title class="overline">
                  Link Rule {{ruleIdx+1}}
                  <v-spacer />

                  <delete-dialog
                    @finished="() => removeRule(ruleIdx)"
                  />
                  <v-icon small class="handle">mdi-drag-variant</v-icon>
              </v-card-title>
              <v-card-text>
                <v-text-field
                    label="Destination if all link conditions are met"
                    type="uri"
                    :rules="uriRules"
                    v-model="rule.uri"
                  ></v-text-field>
                <v-list>
                  <v-list-item
                    v-for="(condition, conditionIdx) in rule.conditions"
                    :key="conditionIdx"
                  >
                    <v-select
                      :items="conditionOptions(rule.conditions)"
                      item-text="label"
                      item-value="value"
                      label="Condition Type"
                      v-model="condition.condition"
                      hint="Select the link condition"
                      dense
                      @change="() => clearCondition(condition)"
                    ></v-select>

                    <v-select
                      v-if="condition.condition == 'locale'"
                      :items="locales"
                      item-text="language"
                      item-value="code"
                      label="Browser language"
                      v-model="condition.locale"
                      hint="Target the visitor's browser language"
                      dense
                    ></v-select>

                    <v-select
                      v-if="condition.condition == 'geo'"
                      :items="countries"
                      item-text="country"
                      item-value="code"
                      label="Visitor IP Location"
                      v-model="condition.country"
                      hint="Target the visitor's IP location"
                      dense
                    ></v-select>

                    <v-select
                      v-if="condition.condition == 'os'"
                      :items="os"
                      item-text="os"
                      item-value="code"
                      label="Visitor Operating System"
                      v-model="condition.os"
                      hint="Target the visitor's Operating System"
                      dense
                    ></v-select>

                    <v-list-item-action>
                      <delete-dialog
                        @finished="() => removeCondition(conditionIdx, rule)"
                      />
                    </v-list-item-action>
                  </v-list-item>
                </v-list>
                <v-btn text small @click="() => addCondition(rule)" v-if="checkCanAddCondition(rule.conditions)">
                  <v-icon>mdi-plus</v-icon>
                  Add Condition to Rule
                </v-btn>
              </v-card-text>
            </v-card>
            </draggable>
            <div v-if="!draftLink.rules || draftLink.rules.length == 0" class="pa-2">
              There are no configured link rules. This page will redirect to the default destination.
            </div>
            <v-btn text small @click="addRule">
              <v-icon>mdi-plus</v-icon>
              Add Link Rule
            </v-btn>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="secondary"
          text
          @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          :color="!redirectionEnabled ? 'primary' : 'red'"
          text
          @click="finished"
        >
          {{redirectionEnabled ? 'Turn off redirection' : 'Turn on redirection'}}
        </v-btn>
                <v-btn
          v-if="redirectionEnabled"
          color="primary"
          text
          @click="dialog = false"
        >
          Save rules
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>

<script>
import draggable from 'vuedraggable';
import { ValidationProvider, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

import DeleteDialog from "./DeleteDialog.vue";

extend('required', {
  ...required,
  message: 'This field is required'
});

const uriPattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|'+ // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
  '(\\#[-a-z\\d_]*)?$','i');

extend('url', {
    validate: (str) => {
      var pattern = new RegExp(/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i,'i');
      return !!pattern.test(str);
    },
    message: 'This is not a valid URL (must start with http:// or https://'
})

import countries from '../../countries.json';
import locales from '../../locales.json';
import os from '../../os.json';

export default {
  name: "LinkRules",
  props: ["link", "enableRedirection", "redirectionEnabled"],
  components: {
    draggable, ValidationProvider, DeleteDialog
  },
  data() {
    return {
      dialog: false,
      draftLink: this.link,
      loaded: true,
      uriRules: [
        v => (v && uriPattern.test(v)) || "Name must be a valid URL.",
      ],
      countries: countries,
      locales: locales,
      os: os
    }
  },
  watch: {
    draftLink: {
      handler: function (newLink) {
        this.$emit("updatedLink", {
          rules: newLink.rules || [],
          uri: newLink.uri
        });
      },
      deep: true,
    },
  },
  methods: {
    finished() {
      this.$emit("enableRedirection", !this.redirectionEnabled);
      this.dialog = false;
    },
    addRule() {
      if (!this.draftLink.rules) {
        this.$set(this.draftLink, 'rules', [])
      }
      this.draftLink.rules.push({
        uri: "https://example.com",
        conditions: []
      });
    },
    removeRule(idx){
      this.draftLink.rules.splice(idx, 1);
    },
    addCondition(rule) {
      rule.conditions.push({
      })
    },
    removeCondition(idx, rule) {
      rule.conditions.splice(idx, 1)
    },
    clearCondition(condition) {
      delete condition.locale;
      delete condition.country;
    },
    conditionOptions(conditions) {
      const selectedOptions = conditions.map(({ condition }) => condition);
      return [{
        "label": "Geo Target (Country)",
        "value": "geo",
        "disabled": selectedOptions.includes("geo")
      }, {
        "label": "Language",
        "value": "locale",
        "disabled": selectedOptions.includes("locale")
      }, {
        "label": "Operating System",
        "value": "os",
        "disabled": selectedOptions.includes("os")
      }];
    },
    checkCanAddCondition(conditions) {
      var uniqueArray = [...new Set(conditions.map(({ condition }) => condition))];
      if (uniqueArray.length < 3) return true;
      return false;
    }
  },
};
</script>

<style lang="scss" scoped>
.handle {
  cursor:move;
}
</style>