<template>
  <v-dialog v-model="templateDialog" max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="primary"
        dark
        small
        class="ml-4"
        :disabled="disabled"
      >
        Create New
      </v-btn>
    </template>

    <v-card class="d-flex flex-column" :loading="loading">
      <v-toolbar dark>
        <v-btn icon dark @click="templateDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Create a new Bounce Page</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-4">
        <v-form
          ref="form"
          lazy-validation
        >
          <v-text-field
            :rules="nameRules"
            v-model="name"
            label="Name"
            hint="Use the name to identify your Bounce Page"
            required
            autocomplete="off"
            autocapitalize="off"
          ></v-text-field>
          <v-text-field
            label="Custom link path (optional)"
            v-model="path"
            :rules="pathRules"
            prefix="bounce.page/d/"
            hint="Customise your Bounce Page path"
            :loading="checkingPath"
            @input="pathCheck"
            :append-icon="pathIcon"
            autocomplete="off"
            autocapitalize="off"
          ></v-text-field>
        </v-form>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="newTemplate" :disabled="!valid">
            Next
          </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import bugsnagClient from "../../utils/bugsnag";
import debounce from "lodash/debounce";

const pathRegex = /^([a-z0-9])+$/;

export default {
  name: "NewTemplateModal",
  props: ["finishedCallback", "disabled", "showTemplate"],
  data() {
    return {
      name: "New BouncePage",
      path: "",
      templateDialog: false,
      checkingPath: false,
      isValidPath: false,
      nameRules: [
        (v) =>
          (v && v.length > 2 && v.length < 32) ||
          "Name must be between 3 and 24 characters",
      ],
      pathRules: [
        (v) =>
          (v && (v.length == 0 || (v.length > 2 && v.length < 32))) ||
          "Custom link path must be between 3 and 32 characters",
        (v) =>
          (v && pathRegex.test(v)) ||
          "Custom link path can only contain letters and numbers.",
        (v) => (v && this.isValidPath) || "Custom link path is already taken.",
      ],
      sku: "001",
      loading: false,
      properties: {
        translations: [
          {
            language: "en",
            name: "New Page Title",
            details: [],
          },
        ],
      },
    };
  },
  watch: { 
    showTemplate: function(newVal, oldVal) {
      this.templateDialog = newVal;
    }
  },
  computed: {
    valid: function () {
      const pathValid = (this.path.length > 0 && pathRegex.test(this.path))
        ? this.isValidPath
        : true;
      return (
        this.name.length > 2 &&
        this.name.length < 32 &&
        pathValid &&
        !this.checkingPath
      );
    },
    pathIcon: function () {
      if (this.path.length == 0 || this.checkingPath) {
        return null;
      }
      return this.isValidPath && pathRegex.test(this.path)
        ? "mdi-check"
        : "mdi-cancel";
    },
  },
  components: {},
  methods: {
    pathCheck: debounce(function (path) {
      console.log(path);
      if (path.length < 3) {
        this.$refs.form.resetValidation();
        return;
      }
      this.checkingPath = true;
      this.isValidPath = false;
      this.$http
        .get(`/checklinkpath/${path}`)
        .then(({ data }) => {
          if (data === true) {
            this.isValidPath = true;
          } else {
            this.isValidPath = false;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isValidPath = false;
        })
        .finally(() => {
          this.checkingPath = false;
          this.$refs.form.validate();
        });
    }, 500),
    async newTemplate() {
      this.loading = true;
      try {
        let nucleiData = await this.$http.get(`/nuclei`);
        let nucleusId = nucleiData.data[0].id;
        let link = await this.$http.post(`/links`, {
          name: "",
          uri: "https://www.bounce.page",
          path: this.path != "" ? this.path : null,
        });
        let template = await this.$http.post(`/templates`, {
          name: this.name,
          nucleusId: nucleusId,
          properties: this.properties,
          attributes: this.attributes,
          sku: this.sku,
          meta: {
            linkId: link.data.id,
            styling: {
              title_font: "Poppins",
              body_font: "Poppins",
              title_font_source: "google",
              body_font_source: "google",
              container_background_color: "#161616",
              main_background_color: "#161616",
              main_color: "#FCD535",
              main_border_radius: "16",
              gallery_background_color: "#6867AC",
              gallery_border_radius: "0",
              text_align: "center",
              show_verified: false,
              secondary_color: "#272727",
              button_color: "#FCD535",
              button_text_color: "#161616",
              button_radius: "8",
              title_color: "#FFFFFF",
              sensitive: false,
              age_gate: false,
              age_gate_age: "18"
            },
          },
        });
        this.loading = false;
        this.$emit("createdTemplate", template.data.id);
      } catch (err) {
        this.loading = false;
        console.log(err);
        bugsnagClient.notify(err);
        this.$notify({
          type: "error",
          title: "Failed to create Bounce Page.",
          text: "Please try again later.",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
