import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                text: colors.grey.darken3,
                primary: "#fcd535", // #E53935
                secondary: colors.white, // #FFCDD2
                accent: colors.indigo.base, // #3F51B5
            },
        }, 
    },
});
