<template>
  <v-dialog v-model="dialog" max-width="450">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        depressed
        @click="manageSubscription"
        :loading="isLoading"
      >
        Change Plan
      </v-btn>
    </template>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { ArrowRightCircleIcon } from "vue-feather-icons";

import bugsnagClient from "../../utils/bugsnag";

import PaymentMethod from "../../components/PaymentMethod";

export default {
  name: "SubscriptionModal",
  props: {
    reason: String,
    copy: String,
  },
  data() {
    return {
      dialog: false,
      isLoading: false,
      enterPayment: false,
      selectedOption: { plan: "free", title: "Community", price: 0.0 },
      options: [
        { plan: "free", title: "Community", price: 0.0 },
        { plan: "starter_m", title: "Team (Billed Monthly)", price: 90.0 },
        {
          plan: "pro_m",
          title: "Enterprise (Billed Monthly)",
          price: "Contact Us",
        },
      ],
      planAttributes: {
        starter_m: {
          name: "Starter (Monthly)",
          price: "$90 USD per month",
          items: [
            "100 Unique Codes per/month",
            "Dynamic Link Engine",
            "Unlimited Scan Events",
            "Umlimited Tracking Events",
            "Full Analytics",
            "Email and Chat Support",
            "Forms Components",
            "Localization",
          ],
        },
        starter_a: {
          name: "Starter (Annual)",
          price: "$900 USD per year",
          items: [
            "100 Unique Codes per/month",
            "Dynamic Link Engine",
            "Unlimited Scan Events",
            "Umlimited Tracking Events",
            "Full Analytics",
            "Email and Chat Support",
            "Forms Components",
            "Localization",
          ],
        },
        pro_m: {
          name: "Pro (Monthly)",
          price: "Contact Us",
          items: [
            "Dynamic Link Engine",
            "Unlimited Scan Events",
            "Umlimited Tracking Events",
            "Full Analytics",
            "Analytics Export",
            "Integration Support",
            "Phone, Email and Chat Support",
            "Forms Components",
            "Localization",
            "API Access",
            "Augmented Reality Components",
            "CS Verified - Verifiable Ledger",
          ],
        },
        pro_a: {
          name: "Pro (Annual)",
          price: "Contact Us",
          items: [
            "Dynamic Link Engine",
            "Unlimited Scan Events",
            "Umlimited Tracking Events",
            "Full Analytics",
            "Analytics Export",
            "Integration Support",
            "Phone, Email and Chat Support",
            "Forms Components",
            "Localization",
            "API Access",
            "Augmented Reality Components",
            "CS Verified - Verifiable Ledger",
          ],
        },
        ent_m: {
          price: "$2000.00 USD monthly",
        },
        free: {
          name: "Community",
          items: [
            "5 Unique Codes",
            "Dynamic Link Engine",
            "Limited Analytics",
            "Email Support",
          ],
        },
      },
      stripeKey: Vue.config.stripeKey,
      methodSelected: false,
    };
  },
  components: {
    ArrowRightCircleIcon,
    PaymentMethod,
  },
  computed: {
    ...mapGetters("account", {
      paymentMethod: "paymentMethod",
    }),
    ...mapGetters("account", {
      plan: "plan",
    }),
  },
  mounted() {
    this.loadPlan().catch((err) => {
      bugsnagClient.notify(err);
      this.$notify({
        type: "error",
        title: "Failed to load plan information.",
        text: "Please try again later.",
      });
    });
  },
  methods: {
    ...mapActions("account", ["loadPlan", "loadInvoices"]),
    proceed: function () {
      this.enterPayment = true;
    },
    returnToPlan: function () {
      this.enterPayment = false;
    },
    manageSubscription() {
      this.isLoading = true;
      this.$http
        .get(`/subscription?upgrade=true`)
        .then((subRes) => {
          console.log(subRes);
          window.location.replace(subRes.data);
        })
        .catch((err) => {
          bugsnagClient.notify(err);
          this.$notify({
            type: "error",
            title: "Failed to open manage subscription.",
            text: "Please try again later.",
          });
        });
    }
  },
};
</script>
<style lang="scss" scoped>
.credit-modal {
  padding: 16px;
}
.button-tool {
  width: 100%;
}
.total-wrapper {
  padding-top: 16px;
}
.total {
  font-size: 16px;
  margin-right: 8px;
  margin-bottom: 8px;
}
.pay {
  margin-top: 16px;
  margin-bottom: 16px;
}
.v-select .dropdown-toggle .clear {
  display: none !important;
}
.reason {
  height: auto;
  background-color: #0085f6;
  box-shadow: none;
  color: white;
}
.card .content {
  padding: 0rem;
  text-align: center;
  padding-top: 16px;
  padding-bottom: -1000px;
}
</style>
