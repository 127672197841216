<template>
  <v-dialog v-model="dialog" max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        v-bind="attrs" v-on="on"
        :disabled="disabled"
        :color="color"
        class="ml-2"
        >{{title}}
      </v-btn>
    </template>
    <v-card :loading="!link">
      <v-card-title>View/Download QR Code <v-spacer></v-spacer> <v-btn icon dark @click="dialog = false">
        <v-icon>mdi-close</v-icon>
      </v-btn></v-card-title>
      <v-card-text> 
        <div v-if="link">

          <v-container>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <div id="qrcontainer" @click="downloadQRPNG">
                  <vue-qrcode :value="slugString(showGS1)" :options="{
                    width: this.width,
                    color: {
                      dark: this.style.dark,
                      light: this.style.light,
                    },
                    errorCorrectionLevel: 'Q',
                  }"></vue-qrcode>
                </div>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <color-picker
                  :color="style.dark"
                  @colorChanged="
                    (val) => updateStyle('dark', val)
                  "
                  label="QR Code Foreground Color"
                  hint="Select the foreground color of the QR Code"
                />
                <color-picker
                  :color="style.light"
                  @colorChanged="
                    (val) => updateStyle('light', val)
                  "
                  label="QR Code Background Color"
                  hint="Select the background color of the QR Code"
                />
                <file-pond
                  v-if="!$vuetify.breakpoint.smAndDown"
                  name="file"
                  ref="qr"
                  accepted-file-types="image/*"
                  v-on:addfile="handleFileAdded"
                  v-on:removefile="handleFileRemoved"
                  allowImageTransform
                  :labelIdle="'Upload Center Logo'"
                  credits="false"
                />
              </v-col>
            </v-row>
          </v-container>
          <v-alert
            v-if="copiedURL"
            dense
            text
            type="success"
          >
            URL copied to clipboard!
          </v-alert>
          <v-text-field
            v-if="!copiedURL"
            :value="slugString(showGS1)"
            label="Bounce Page URL"
            readonly
            append-icon="mdi-content-copy"
            @click:append="copyURL"
          ></v-text-field>
        </div>

      </v-card-text>
      <v-card-actions class="justify-end">
        <span class="mr-2">
          Download QR
        </span>
        <v-btn
          @click="downloadQRPNG"
          color="primary"
          >PNG
        </v-btn>
        <v-btn
          @click="downloadQRJPG"
          color="primary"
          >JPG
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from "vue";
import VueQrcode from "@chenfengyuan/vue-qrcode";
import Download from "downloadjs";
import moment from "moment";
import ColorPicker from "./../../components/editor/ColorPicker.vue";
import vueFilePond from "vue-filepond";
import bugsnagClient from "../../utils/bugsnag";

import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImageTransform
);

export default {
  name: "DownloadQRModal",
  props: ["disabled", "linkId", "atom", "color", "title", "small"],
  components: {
    Download,
    "vue-qrcode": VueQrcode,
    ColorPicker,
    FilePond,
  },
  mounted() {
    this.$http
      .get(`/links/${this.linkId}`)
      .then(({ data }) => {
        this.link = data;
      });
  },
  data() {
    return {
      width: 512,
      link: null,
      dialog: false,
      showGS1: false,
      copiedURL: false,
      style: {
        dark: '#000000FF',
        light: '#FFFFFFFF',
      }
    };
  },
  methods: {
    updateStyle(prop, val) {
      this.$set(this.style, prop, val);
    },
    handleFileAdded: function (error, file) {
      if (error) {
        console.log(error);
      }

      const qrCodeCanvas = document.querySelector("#qrcontainer > canvas");
      const ctx = qrCodeCanvas.getContext('2d');
      ctx.imageSmoothingEnabled = false;

      let url = URL.createObjectURL(file.file);
      let img = new Image();

      ctx.fillStyle = this.style.light;

      img.onload = function() {
        URL.revokeObjectURL(this.src);
        ctx.fillRect(192, 192, 128, 128);
        ctx.drawImage(this, 192, 192, 128, 128);
      };

      img.src = url;
    },
    handleFileRemoved: function () {
      this.style = Object.assign({}, this.style);
    },
    slugString(gs1) {
      if (!this.link) return;
      if (gs1) {
        if (this.link.gtin && this.link.batchRef) {
          return `${Vue.config.sulde}/01/${this.link.gtin}/10/${this.link.batchRef}`;
        } else if (this.link.gtin) {
          return `${Vue.config.sulde}/01/${this.link.gtin}`;
        }
      } else {
        if (this.link && this.link.path && this.link.path.length > 0) {
          return `${Vue.config.sulde}/d/${this.link.path}`;
        } else if (this.link){
          return `${Vue.config.sulde}/l/${this.link.hashId}`;
        } else {
          return `${Vue.config.sulde}/p/${this.atom.slugId}`;
        }
      }
    },
    copyURL() {
      const url = this.slugString(this.showGS1);
      navigator.clipboard.writeText(url).then(() => {
        console.log('Async: Copying to clipboard was successful!');
        this.copiedURL = true;
        setTimeout(() => {
          this.copiedURL = false;
        }, 5000)
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    },
    downloadQRPNG() {
      const qrCodeCanvas = document.querySelector("#qrcontainer > canvas");
      const qrdata = qrCodeCanvas.toDataURL();
      Download(qrdata, `qr-${moment().format()}.png`, "image/png");
    },
    downloadQRJPG() {
      // let datauri = this.slugString(this.showGS1);
      // QRCode.toString(datauri, (err, qrdata) => {
      //   if (err) {
      //     bugsnagClient.notify(err);
      //     this.$notify({
      //       type: "error",
      //       title: "Failed to generate QR Code.",
      //       text: "Please try again later.",
      //     });
      //     return;
      //   }
      //   Download(qrdata, `qr-${moment().format()}.svg`, "image/svg");
      // });
      const qrCodeCanvas = document.querySelector("#qrcontainer > canvas");
      const qrdata = qrCodeCanvas.toDataURL();
      Download(qrdata, `qr-${moment().format()}.jpg`, "image/jpg");
    }
  },
};
</script>
<style scoped>
#qrcontainer {
  height: 200px;
  transform: scale(0.4, 0.4) translate(-170px, -120px);
}
</style>