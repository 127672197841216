<template>
  <v-dialog v-model="templateDialog" max-width="900" scrollable>
    <template v-slot:activator="{ on, attrs }">
      <div class="d-flex justify-center mt-4">
        <v-btn v-bind="attrs" v-on="on" color="primary" dark small>
          Choose Template
        </v-btn>
      </div>
    </template>

    <v-card class="d-flex flex-column">
      <v-toolbar dark>
        <v-btn icon dark @click="templateDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Choose template</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pa-0 ma-0">
        <v-container fluid class="pa-0 ma-0">
          <v-row no-gutters>
            <!-- <v-col cols="3">
              <v-navigation-drawer permanent>
                <v-list dense>
                  <v-list-item v-for="item in items" :key="item.title" link>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-navigation-drawer>
            </v-col> -->
            <v-col cols="12">
              <v-container fluid class="pa-4">
                <v-row dense class="overflow-auto" style="height: 390px">
                  <v-col
                    v-for="(card, i) in cards"
                    :key="card.title"
                    cols="12"
                    sm="12"
                    :md="card.flex"
                  >
                    <v-hover>
                      <template v-slot:default="{ hover }">
                        <v-card class="text-center">
                          <svg
                            width="138px"
                            height="205px"
                            viewBox="0 0 415 615"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink"
                          >
                            <title>Group 3</title>
                            <defs>
                              <linearGradient
                                :id="`gradient-${i}`"
                                v-if="card.gradient"
                                x1="0%"
                                y1="0%"
                                x2="0%"
                                y2="100%"
                              >
                                <stop
                                  offset="0%"
                                  :stop-color="card.gradient[0]"
                                />
                                <stop
                                  offset="100%"
                                  :stop-color="card.gradient[1]"
                                />
                              </linearGradient>
                            </defs>
                            <g
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <g
                                id="Artboard"
                                transform="translate(-295.000000, -978.000000)"
                              >
                                <g
                                  id="Group-3"
                                  transform="translate(295.000000, 978.000000)"
                                >
                                  <rect
                                    v-if="card.gradient"
                                    stroke="#979797"
                                    :fill="`url(#gradient-${i})`"
                                    x="0.5"
                                    y="0.5"
                                    width="414"
                                    height="614"
                                    rx="26"
                                  ></rect>
                                  <rect
                                    v-else
                                    id="Rectangle-Copy-12"
                                    stroke="#979797"
                                    :fill="card.backgroundColor"
                                    x="0.5"
                                    y="0.5"
                                    width="414"
                                    height="614"
                                    rx="26"
                                  ></rect>
                                  <rect
                                    id="Rectangle-Copy-11"
                                    :fill="card.buttonColor"
                                    x="30"
                                    y="36"
                                    width="357"
                                    height="128"
                                    rx="26"
                                  ></rect>
                                  <rect
                                    id="Rectangle-Copy-10"
                                    :fill="card.buttonColor"
                                    x="30"
                                    y="331"
                                    width="357"
                                    height="66"
                                    rx="26"
                                  ></rect>
                                  <rect
                                    id="Rectangle-Copy-9"
                                    :fill="card.buttonColor"
                                    x="30"
                                    y="422"
                                    width="357"
                                    height="66"
                                    rx="26"
                                  ></rect>
                                  <rect
                                    id="Rectangle-Copy-7"
                                    :fill="card.buttonColor"
                                    x="30"
                                    y="513"
                                    width="357"
                                    height="66"
                                    rx="26"
                                  ></rect>
                                  <circle
                                    id="Oval-Copy"
                                    :stroke="card.backgroundColor"
                                    stroke-width="8"
                                    :fill="card.buttonColor"
                                    cx="206.5"
                                    cy="164.5"
                                    r="74"
                                  ></circle>
                                </g>
                              </g>
                            </g>
                          </svg>
                          <v-card-text
                            class="h6"
                            v-text="card.title"
                          ></v-card-text>
                          <v-fade-transition>
                            <v-overlay v-if="hover" absolute color="#000000">
                              <v-btn
                                color="primary"
                                small
                                @click="() => pickTemplate(card)"
                                >Use template</v-btn
                              >
                            </v-overlay>
                          </v-fade-transition>
                        </v-card>
                      </template>
                    </v-hover>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "TemplateStylePicker",
  props: ["templateChosen"],
  data() {
    return {
      path: "",
      templateDialog: false,
      template: null,
      items: [
        { title: "All templates" },
        { title: "Light" },
        { title: "Dark" },
      ],
      mini: true,
      cards: [
        {
          title: "Sky White",
          flex: 3,
          backgroundColor: "#FFFFFF",
          buttonColor: "#D8D8D8",
          styling: {
            title_font: "Noto Sans",
            body_font: "Noto Sans JP",
            title_font_source: "google",
            body_font_source: "google",
            container_background_color: "#FFFFFF",
            main_background_color: "#FFFFFF",
            main_color: "#000000",
            main_border_radius: "16",
            gallery_background_color: "#D8D8D8",
            gallery_border_radius: "8",
            text_align: "center",
            show_verified: false,
            secondary_color: "#F7F8F9",
            button_color: "#D8D8D8",
            button_text_color: "#000000",
            button_radius: "16",
            title_color: "#000000"
          },
        },
        {
          title: "Sky Black",
          flex: 3,
          backgroundColor: "#242424",
          buttonColor: "#4F4F4F",
          styling: {
            title_font: "Noto Sans",
            body_font: "Noto Sans JP",
            title_font_source: "google",
            body_font_source: "google",
            container_background_color: "#242424",
            main_background_color: "#242424",
            main_color: "#4F4F4F",
            main_border_radius: "16",
            gallery_background_color: "#4F4F4F",
            gallery_border_radius: "8",
            text_align: "center",
            show_verified: false,
            secondary_color: "#F7F8F9",
            button_color: "#4F4F4F",
            button_text_color: "#FFFFFF",
            button_radius: "16",
            title_color: "#FFFFFF"
          },
        },
        {
          title: "Sky Grey",
          flex: 3,
          backgroundColor: "#CBCEDB",
          buttonColor: "#FFFFFF",
          styling: {
            title_font: "Noto Sans",
            body_font: "Noto Sans JP",
            title_font_source: "google",
            body_font_source: "google",
            container_background_color: "#CBCEDB",
            main_background_color: "#CBCEDB",
            main_color: "#474747",
            main_border_radius: "16",
            gallery_background_color: "#FFFFFF",
            gallery_border_radius: "32",
            text_align: "center",
            show_verified: false,
            secondary_color: "#F7F8F9",
            button_color: "#FFFFFF",
            button_text_color: "#474747",
            button_radius: "32",
            title_color: "#474747"
          },
        },
        {
          title: "Obsidian",
          flex: 3,
          backgroundColor: "#323232",
          buttonColor: "#FFFFFF",
          styling: {
            title_font: "Roboto Mono",
            body_font: "Roboto Mono",
            title_font_source: "google",
            body_font_source: "google",
            container_background_color: "#323232",
            main_background_color: "#323232",
            main_color: "#FFFFFF",
            main_border_radius: "16",
            gallery_background_color: "#323232",
            gallery_border_radius: "32",
            text_align: "center",
            show_verified: false,
            secondary_color: "#F7F8F9",
            button_color: "#FFFFFF",
            button_text_color: "#323232",
            button_radius: "32",
            title_color: "#FFFFFF"
          },
        },
        {
          title: "Cyberpunk",
          flex: 3,
          backgroundColor: "#222831",
          buttonColor: "#FFD369",
          styling: {
            title_font: "Inconsolata",
            body_font: "Inconsolata",
            title_font_source: "google",
            body_font_source: "google",
            container_background_color: "#222831",
            main_background_color: "#222831",
            main_color: "#FFD369",
            main_border_radius: "0",
            gallery_background_color: "#222831",
            gallery_border_radius: "0",
            text_align: "center",
            show_verified: false,
            secondary_color: "#F7F8F9",
            button_color: "#FFD369",
            button_text_color: "#222831",
            button_radius: "8",
            title_color: "#FFD369"
          },
        },
        // {
        //   title: "Atmosphere",
        //   flex: 3,
        //   backgroundColor: "#2980B9",
        //   buttonColor: "#FFFFFF",
        //   gradient: ["#005AA7", "#FFFDE4"],
        // },
        {
          title: "Greenery",
           flex: 3,
          backgroundColor: "#064635",
          buttonColor: "#F4EEA9",
          styling: {
            title_font: "Inconsolata",
            body_font: "Inconsolata",
            title_font_source: "google",
            body_font_source: "google",
            container_background_color: "#064635",
            main_background_color: "#064635",
            main_color: "#F4EEA9",
            main_border_radius: "0",
            gallery_background_color: "#222831",
            gallery_border_radius: "0",
            text_align: "center",
            show_verified: false,
            secondary_color: "#042A20",
            button_color: "#F4EEA9",
            button_text_color: "#064635",
            button_radius: "8",
            title_color: "#F4EEA9"
          },
        },
        // {
        //   title: "Space Walk",
        //   flex: 3,
        //   backgroundColor: "#1D2671",
        //   buttonColor: "#FFFFFF",
        //   gradient: ["#C33764", "#1D2671"],
        // },
        {
          title: "Mono 1",
          flex: 3,
          backgroundColor: "#FFFFFF",
          buttonColor: "#4F4F4F",
          styling: {
            title_font: "Noto Sans",
            body_font: "Noto Sans JP",
            title_font_source: "google",
            body_font_source: "google",
            container_background_color: "#4F4F4F",
            main_background_color: "#FFFFFF",
            main_color: "#4F4F4F",
            main_border_radius: "16",
            gallery_background_color: "#FFFFFF",
            gallery_border_radius: "0",
            text_align: "center",
            show_verified: false,
            secondary_color: "#FFFFFF",
            button_color: "#4F4F4F",
            button_text_color: "#FFFFFF",
            button_radius: "8",
            title_color: "#4F4F4F"
          },
        },
        {
          title: "Mono 2",
          flex: 3,
          backgroundColor: "#4F4F4F",
          buttonColor: "#FFFFFF",
          styling: {
            title_font: "Noto Sans",
            body_font: "Noto Sans JP",
            title_font_source: "google",
            body_font_source: "google",
            container_background_color: "#FFFFFF",
            main_background_color: "#4F4F4F",
            main_color: "#FFFFFF",
            main_border_radius: "16",
            gallery_background_color: "#4F4F4F",
            gallery_border_radius: "0",
            text_align: "center",
            show_verified: false,
            secondary_color: "#313131",
            button_color: "#FFFFFF",
            button_text_color: "#4F4F4F",
            button_radius: "8",
            title_color: "#FFFFFF"
          },
        },
        {
          title: "Pastel Blue",
          flex: 3,
          backgroundColor: "#9ADCFF",
          buttonColor: "#FFFFFF",
          styling: {
            title_font: "Poppins",
            body_font: "Poppins",
            title_font_source: "google",
            body_font_source: "google",
            container_background_color: "#9ADCFF",
            main_background_color: "#9ADCFF",
            main_color: "#FFFFFF",
            main_border_radius: "16",
            gallery_background_color: "#9ADCFF",
            gallery_border_radius: "0",
            text_align: "center",
            show_verified: false,
            secondary_color: "#7DD2FF",
            button_color: "#FFFFFF",
            button_text_color: "#9ADCFF",
            button_radius: "8",
            title_color: "#FFFFFF"
          },
        },
        {
          title: "Pastel Salmon",
          flex: 3,
          backgroundColor: "#FFB2A6",
          buttonColor: "#FFFFFF",
          styling: {
            title_font: "Poppins",
            body_font: "Poppins",
            title_font_source: "google",
            body_font_source: "google",
            container_background_color: "#FFB2A6",
            main_background_color: "#FFB2A6",
            main_color: "#FFFFFF",
            main_border_radius: "16",
            gallery_background_color: "#FFB2A6",
            gallery_border_radius: "0",
            text_align: "center",
            show_verified: false,
            secondary_color: "#FF9888",
            button_color: "#FFFFFF",
            button_text_color: "#FFB2A6",
            button_radius: "8",
            title_color: "#FFFFFF"
          },
        },
        {
          title: "Pastel Purple",
          flex: 3,
          backgroundColor: "#6867AC",
          buttonColor: "#FFFFFF",
          styling: {
            title_font: "Poppins",
            body_font: "Poppins",
            title_font_source: "google",
            body_font_source: "google",
            container_background_color: "#6867AC",
            main_background_color: "#6867AC",
            main_color: "#FFFFFF",
            main_border_radius: "16",
            gallery_background_color: "#6867AC",
            gallery_border_radius: "0",
            text_align: "center",
            show_verified: false,
            secondary_color: "#5756AA",
            button_color: "#FFFFFF",
            button_text_color: "#6867AC",
            button_radius: "8",
            title_color: "#FFFFFF"
          },
        },
        {
          title: "Bounce Default",
          flex: 3,
          backgroundColor: "#161616",
          buttonColor: "#FCD535",
          styling: {
            title_font: "Poppins",
            body_font: "Poppins",
            title_font_source: "google",
            body_font_source: "google",
            container_background_color: "#161616",
            main_background_color: "#161616",
            main_color: "#FCD535",
            main_border_radius: "16",
            gallery_background_color: "#6867AC",
            gallery_border_radius: "0",
            text_align: "center",
            show_verified: false,
            secondary_color: "#272727",
            button_color: "#FCD535",
            button_text_color: "#161616",
            button_radius: "8",
            title_color: "#FFFFFF"
          },
        },
      ],
    };
  },
  computed: {},
  components: {},
  methods: {
    async pickTemplate(template) {
      this.$emit("templateChosen", template);
      this.templateDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
