<template>
  <v-dialog v-model="dialog" max-width="600">
    <template v-slot:activator="{ on, attrs }">
      <v-list-item link v-bind="attrs" v-on="on">
        <v-list-item-title>Settings / SEO</v-list-item-title>
      </v-list-item>
    </template>
    <v-card class="d-flex flex-column" :loading="loading">
      <v-toolbar dark>
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Settings / SEO</v-toolbar-title>
      </v-toolbar>
      <v-card-text class="pt-4">

        <v-text-field :rules="nameRules" v-model="internalName" label="Bounce Page Name"
          hint="Use the name to identify your Bounce Page internally"></v-text-field>

        <v-banner v-if="!isPaid" sticky color="primary" dense>
          <span class="black--text font-weight-medium">
            To enable SEO and Analytics settings, you'll need to upgrade to the
            Plus or Team plan.
          </span>
          <template v-slot:actions>
            <subscription-modal></subscription-modal>
          </template>
        </v-banner>

        <v-text-field :disabled="!isPaid" v-model="internalSEO.title" label="SEO Meta Title"
          hint="Override your Bounce Page meta title"></v-text-field>
        <v-text-field :disabled="!isPaid" v-model="internalSEO.description" label="SEO Meta Description"
          hint="Override your Bounce Page meta description"></v-text-field>
        <v-text-field :disabled="!isPaid" v-model="internalSEO.ganalyticsid" label="SEO Google Analytics ID"
          hint="Add a Google Analytics ID"></v-text-field>
        <v-text-field :disabled="!isPaid" v-model="internalSEO.tiktokpixel" label="SEO TikTok Pixel ID"
          hint="Add a TikTok Pixel ID"></v-text-field>
        <v-text-field :disabled="!isPaid" v-model="internalSEO.metapixel" label="SEO Meta/Facebook Pixel ID"
          hint="Add a Facebook/Meta Pixel ID"></v-text-field>
        <v-checkbox
          v-model="internalSEO.utmenabled"
        >
          <template v-slot:label>
            <div>Turn on UTM Parameters</div>
          </template>
        </v-checkbox>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="update" :disabled="!valid"> Save </v-btn>
        </v-card-actions>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import SubscriptionModal from "../../views/modals/SubscriptionModal";

export default {
  name: "SettingsModal",
  components: {
    SubscriptionModal
  },
  props: {
    isPaid: {
      type: Boolean,
      default: false
    },
    name: {
      default: "",
      type: String
    },
    seo: {
      default: () => {
        return {
          title: "",
          description: "",
          ganalyticsid: "",
          tiktokpixel: "",
          metapixel: "",
          utmenabled: false
        };
      },
      type: Object
    },
  },
  data() {
    return {
      internalName: this.name,
      internalSEO: this.seo,
      nameRules: [
        v => (v && v.length > 3) || "Name must be longer than 3 characters",
      ],
      dialog: false,
      loading: false,
    };
  },
  computed: {
    valid: function () {
      return this.internalName.length > 3;
    }
  },
  methods: {
    update() {
      this.$emit("finished", {
        name: this.internalName,
        seo: this.internalSEO
      });
      this.dialog = false;
    },
  },
}
</script>
