<template>
<div class="container-fluid">
    <modals-container>
    </modals-container>
    <div class="columns">
      <div class="column col-12">
        <div class="card">
          <div class="content">
            <div class="header">
              <button @click="back" class="button-tool"><arrow-left-circle-icon class="icon"></arrow-left-circle-icon> Templates</button>
              <div v-if="savingTemplate" class="loading loading-lg">
                Saving Template
              </div>
              <div v-else class="export">
                <button class="button-tool" @click="openPrev"><eye-icon class="icon"></eye-icon> Preview</button>
                <button class="button-tool" @click="saveTemplate"><save-icon class="icon"></save-icon>Save {{unsavedTemplate ? "(unsaved changes)" : ""}}</button>
                <!-- <button class="button-tool quickstart" @click="newBatch">Create Smart Code(s)</button> -->
                <NewCodesModal></NewCodesModal>
                  <!-- <v-dialog
          v-model="templateDialog"
          persistent
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item link v-bind="attrs" v-on="on">
              <v-list-item-icon>
                  <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-title>New BouncePage</v-list-item-title>
              </v-list-item>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              New BouncePage
            </v-card-title>
            <v-card-text>Create a new BouncePage</v-card-text>
            <v-card-text>
              <v-text-field
                v-model="newTemplateName"
                label="BouncePage Name"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="secondary"
                text
                @click="templateDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="createTemplate"
              >
                New Template
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="details column col-5 col-md-12">
        <div v-if="template">
          <div class="card">
            <div class="accordion">
                <input type="checkbox" id="accordion-1" name="accordion-checkbox" hidden>
                <label class="accordion-header" for="accordion-1">
                    <chevron-right-icon class="mr-1 mt-2 icon"></chevron-right-icon>
                    <div class="accordion-header-text">Template Details</div>
                </label>
                <div class="accordion-body">
                    <div class="form-horizontal content">
                      <ValidationProvider name="template-name" rules="required|min:3" v-slot="{ errors }">
                        <div class="form-group" v-bind:class="{ 'has-error': errors[0] }">
                            <div class="col-3 col-sm-12">
                                <label class="form-label" for="template-name">Template Name</label>
                            </div>
                            <div class="col-9 col-sm-12">
                                <input class="form-input" type="text" id="template-name" v-model="template.name" name="name">
                                <p class="form-input-hint">{{ errors[0] }}</p>
                            </div>
                        </div>
                      </ValidationProvider>

                      <ValidationProvider name="template-sku" rules="required|min:3" v-slot="{ errors }">
                        <div class="form-group" v-bind:class="{ 'has-error': errors[0] }">
                            <div class="col-3 col-sm-12">
                                <label class="form-label" for="template-sku">Template SKU</label>
                            </div>
                            <div class="col-9 col-sm-12">
                                <input class="form-input" type="text" id="template-sku" v-model="template.sku" name="sku">
                                <p class="form-input-hint">{{ errors[0] }}</p>
                            </div>
                        </div>
                      </ValidationProvider>

                      <ValidationProvider name="template-gtin" :rules="{ regex: /^[0-9]{14}$|^[0-9]{13}$|^[0-9]{12}$|^[0-9]{8}$/ }" v-slot="{ errors }">
                        <div class="form-group" v-bind:class="{ 'has-error': errors[0] }">
                            <div class="col-3 col-sm-12">
                                <label class="form-label" for="template-gtin" >Template GTIN (UPC/EAN)</label>
                            </div>
                            <div class="col-9 col-sm-12">
                                <input class="form-input" type="text" id="template-gtin" v-model="template.gtin" name="gtin">
                                <p class="form-input-hint">{{ errors[0] }}</p>
                            </div>
                        </div>
                      </ValidationProvider>
                    </div>
                </div>
            </div>
          </div>

          <div style="height:60px" :class="{ 'd-hide': !sticky }"></div>
          <div class="tab-container" :class="{ sticky: sticky }" >
                <ul class="tab tab-block">
                    <li class="tab-item" v-for="(translation, translationIdx) in template.properties.translations" v-bind:key="'translation-'+translationIdx">
                        <a @click.prevent="setActiveTranslation(translationIdx)" :class="{ active: isActiveTranslation(translationIdx) }" href="#">
                            <div class="language-title">
                                {{ locales.find(l => l.code === translation.language).language }}
                                <div v-if="isActiveTranslation(translationIdx) && template.properties.translations.length > 1">
                                    <trash-2-icon @click="deleteTranslation(translationIdx)"></trash-2-icon>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li class="tab-item">
                        <a @click="addTranslation()"><plus-icon></plus-icon></a>
                    </li>
                </ul>
          </div>

          <div class="card" style="overflow:visible">
          <div class="tab-content content">
            <div class="tab-pane fade" v-for="(translation, translationIdx) in template.properties.translations" v-bind:key="'translation-'+translationIdx" :class="{ 'active d-none': !isActiveTranslation(translationIdx) }">
                <div class="form-horizontal content">

                    <div class="form-group">
                        <div class="col-3 col-sm-12">
                            <label class="form-label" for="translation-language">Language</label>
                        </div>
                        <div class="col-9 col-sm-12">
                            <v-select class="selector" :clearable="false" :options="locales" v-model="translation.language" :reduce="locale => locale.code" label="language"></v-select>
                        </div>
                    </div>

                  <ValidationProvider :name="'translation-'+translationIdx+'-name'" rules="required" v-slot="{ errors }">
                    <div class="form-group" v-bind:class="{ 'has-error': errors[0] }">
                        <div class="col-3 col-sm-12">
                            <label class="form-label" for="template-title">Title</label>
                        </div>
                        <div class="col-9 col-sm-12">
                            <input class="form-input" type="text" id="template-title" v-model="translation.name" name="name">
                            <p class="form-input-hint">{{ errors[0] }}</p>
                        </div>
                    </div>
                  </ValidationProvider>

                    <div class="form-group">
                        <div class="col-3 col-sm-12">
                            <label class="form-label" for="template-media">Media</label>
                        </div>
                        <div class="col-9 col-sm-12">
                            <v-select class="selector" :clearable="false" :options="mediaTypes" :value="activeMediaType(translation).value" @input="(selected) => setActiveMediaType(translation, selected)"></v-select>
                        </div>
                    </div>

                    <div class="form-group" v-if="activeMediaType(translation) === 'images'">
                        <div class="col-3">
                            <label class="form-label" for="template-media">Image Gallery</label>
                        </div>
                        <div class="col-9">
                            <file-selector v-bind:key="'translationimages-'+translationIdx" v-bind:files="translation.images" v-on:update="updateFiles(translation, 'images', $event)"></file-selector>
                        </div>
                    </div>

                    <div v-if="activeMediaType(translation) === 'video'">
                        <div class="form-group">
                            <div class="col-3">
                                <label class="form-label" for="template-media">Video</label>
                            </div>
                            <div class="col-9">
                                <div v-if="translation.video && translation.video.match(/{{[0-z._]+}?}}/)">
                                  <v-select class="selector" :reduce="attribute => attribute.key" label="key" :clearable="false" :options="normalizedAttributes" :value="translation.video" @input="(selected) => selectReplaceableVideo(translation, selected)"></v-select>
                                  <o-button buttonStyle="alt" @click.native="clearReplaceableVideo(translation)">Clear Replaceable</o-button>
                                </div>
                                <div v-else>
                                  <file-selector v-bind:key="'translationvideo-'+translationIdx" :maxFiles=1 v-bind:files="translation.video" v-on:update="updateFiles(translation, 'video', $event)"></file-selector>
                                  <div v-if="normalizedAttributes.length > 0">
                                    <o-button buttonStyle="alt" @click.native="makeReplaceableVideo(translation)">Make Replaceable</o-button>
                                  </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="form-switch">
                                    <input type="checkbox" :checked="settings(translation).autoplay" @input="(e) => { setSettings(translation, 'autoplay', e) }">
                                    <i class="form-icon"></i> Enable video autoplay (this will mute your video)
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="form-group" v-if="activeMediaType(translation) === 'image'">
                        <div class="col-3">
                            <label class="form-label" for="template-media">Single Image</label>
                        </div>
                        <div class="col-9">
                          <div v-if="translation.image && translation.image.match(/{{[0-z._]+}?}}/)">
                            <v-select class="selector" :reduce="attribute => attribute.key" label="key" :clearable="false" :options="normalizedAttributes" :value="translation.image" @input="(selected) => selectReplaceableImage(translation, selected)"></v-select>
                            <o-button buttonStyle="alt" @click.native="clearReplaceableImage(translation)">Clear Replaceable</o-button>
                          </div>
                          <div v-else>
                            <file-selector v-bind:key="'translationimage-'+translationIdx" :maxFiles=1 v-bind:files="translation.image" v-on:update="updateFiles(translation, 'image', $event)"></file-selector>
                            <div v-if="normalizedAttributes.length > 0">
                              <o-button buttonStyle="alt" @click.native="makeReplaceableImage(translation)">Make Replaceable</o-button>
                            </div>
                          </div>
                        </div>
                    </div>

                    <div class="form-group" v-if="activeMediaType(translation) === 'ar'">
                        <div class="col-3 col-sm-12">
                            <label class="form-label" for="template-media">Augmented Reality</label>
                        </div>
                        <div class="col-9 col-sm-12">
                            <file-selector v-bind:key="'translationaug-'+translationIdx" :maxFiles=1 v-bind:files="translation.ar" v-on:update="updateFiles(translation, 'ar', $event)"></file-selector>
                        </div>
                    </div>

                </div>

                <div class="empty">
                    <draggable
                        class="draggable-details"
                        :list="translation.details"
                        group="details"
                        handle=".move">
                        <div
                            class="card component-container"
                            v-for="(detail, detailIdx) in translation.details"
                            v-bind:key="'translation'+translationIdx+'-detail-'+detailIdx">
                            <div class="component content">
                                <div class="editor">
                                    <pie-chart-icon v-if="detail.style == 'chart'"></pie-chart-icon>
                                    <image-icon v-else-if="detail.style == 'image'"></image-icon>
                                    <link-icon v-else-if="detail.style == 'link'"></link-icon>
                                    <type-icon v-else-if="detail.style == 'text'"></type-icon>
                                    <paperclip-icon v-else-if="detail.style == 'attachment'"></paperclip-icon>
                                    <list-icon v-else-if="detail.style == 'results'"></list-icon>
                                    <check-square-icon v-else-if="detail.style == 'form'"></check-square-icon>
                                    <div class="info">
                                        <div class="title">
                                            <div class="form-group">
                                                <label class="form-label" for="detail-title">Title</label>
                                                <input style="max-width: 300px;" class="form-input" type="text" name="detail-title" v-model="detail.title" key="detail-title"/>
                                            </div>
                                        </div>

                                        <div class="form-group" v-if="detail.style == 'text'" >
                                            <label class="form-label" for="detail-textarea">Content</label>
                                            <textarea style="max-width: 300px; max-height:100%;" class="form-input" type="text" name="detail-textarea" v-model="detail.content" key="detail-textarea"/>
                                            <!-- <tagify mode="textarea" class="textarea" type="text" name="detail-textarea" v-model="detail.content" :tags="normalizedAttributes" key="detail-textarea"/> -->
                                        </div>

                                        <div v-if="detail.style == 'chart'">
                                            <div class="form-group">
                                                <label class="form-label" for="detail-chart-type">Chart Type</label>
                                                <v-select class="selector" :clearable="false" :options="chartTypes" v-model="detail.chartType" :reduce="chartType => chartType.code"></v-select>
                                            </div>
                                            <div class="form-group">
                                                <label class="form-label" for="detail-chart-labels">Chart Properties</label>
                                                <draggable tag="ul" :list="detail.labels" class="list-group empty-list" handle=".propertyhandle" @change="changedChartPropertyPosition($event, detail)">
                                                    <li
                                                        class="list-group-item card"
                                                        v-for="(chartLabel, chartIdx) in detail.labels"
                                                        :key="chartIdx">
                                                        <div class="input-group">
                                                            <o-button buttonStyle="icon" class="propertyhandle">
                                                                <move-icon></move-icon>
                                                            </o-button>
                                                            <input class="form-input" type="text" id="detail-label" placeholder="Label" v-model="detail.labels[chartIdx]">
                                                            <input class="form-input" type="text" id="detail-data" placeholder="Value (number)" pattern="[0-9]+(\.[0-9]+)?%?" v-model="detail.data[chartIdx]">
                                                            <o-button buttonStyle="icon" @click.native="removeChartProperty(chartIdx, detail)">
                                                              <minus-circle-icon></minus-circle-icon>
                                                            </o-button>
                                                        </div>
                                                    </li>
                                                </draggable>
                                                <plus-circle-icon @click="addChartProperty(detail)"></plus-circle-icon>
                                            </div>
                                        </div>

                                        <div class="form-group" v-if="detail.style == 'results'">
                                            <label class="form-label">Table Header</label>
                                            <input class="form-input" type="text" id="detail-subheader" placeholder="Table Header" v-model="detail.subheader">

                                            <label class="form-label">Table Columns</label>
                                            <div v-for="(header, idx) in detail.header" v-bind:key="idx">
                                                <input class="form-input" type="text" id="detail-header" placeholder="Table Column" v-model="detail.header[idx]">
                                            </div>

                                            <plus-circle-icon v-if="!detail.header" @click="addTableHeader(detail)"></plus-circle-icon>

                                            <label class="form-label">Table Data rows</label>
                                            <draggable tag="ul" :list="detail.rows" class="list-group empty-list" handle=".tablehandle">
                                                <li
                                                    class="list-group-item card"
                                                    v-for="(row, idx) in detail.rows"
                                                    v-bind:key="'table'+idx">
                                                    <div class="input-group">
                                                        <o-button buttonStyle="icon" class="tablehandle">
                                                            <move-icon></move-icon>
                                                        </o-button>
                                                        <input class="form-input" type="text" id="table-data-title" placeholder="Table Row Title" v-model="detail.rows[idx].title">
                                                        <input class="form-input" type="text" id="table-data-detail" placeholder="Table Row Content" v-model="detail.rows[idx].detail">
                                                        <o-button buttonStyle="icon" @click.native="removeTableRow(idx, detail)">
                                                            <minus-circle-icon></minus-circle-icon>
                                                        </o-button>
                                                    </div>
                                                </li>
                                            </draggable>
                                            <o-button buttonStyle="alt" @click.native="addTableRow(detail)"><plus-circle-icon></plus-circle-icon> Add Table Row</o-button>
                                        </div>

                                        <div class="form-group" v-if="detail.style == 'image'">
                                            <label class="form-label" for="detail-file">Image</label>
                                            <div v-if="detail.file && detail.file.match(/{{[0-z._]+}?}}/)">
                                                <v-select class="selector" :reduce="attribute => attribute.key" label="key" :clearable="false" :options="normalizedAttributes" :value="detail.file" @input="(selected) => selectReplaceable(detail, selected)"></v-select>
                                                <o-button buttonStyle="alt" @click.native="clearReplaceable(detail)">Clear Replaceable</o-button>
                                            </div>
                                            <div v-else>
                                                <file-selector :maxFiles=1 v-bind:files="detail.file" v-on:update="updateFiles(detail, 'file', $event)"></file-selector>
                                                <div v-if="normalizedAttributes.length > 0">
                                                  <o-button  buttonStyle="alt" @click.native="makeReplaceable(detail)">Make Replaceable</o-button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group" v-if="detail.style == 'attachment'">
                                            <label class="form-label" for="detail-file">Attachment</label>
                                            <div v-if="detail.file && detail.file.match(/{{[0-z._]+}?}}/)">
                                                <v-select class="selector" :reduce="attribute => attribute.key" label="key" :clearable="false" :options="normalizedAttributes" :value="detail.file" @input="(selected) => selectReplaceable(detail, selected)"></v-select>
                                                <o-button buttonStyle="alt" @click.native="clearReplaceable(detail)">Clear Replaceable</o-button>
                                            </div>
                                            <div v-else>
                                                <file-selector :maxFiles=1 v-bind:files="detail.file" v-on:update="updateFiles(detail, 'file', $event)"></file-selector>
                                                <div v-if="normalizedAttributes.length > 0">
                                                  <o-button buttonStyle="alt" @click.native="makeReplaceable(detail)">Mark as Replaceable</o-button>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group" v-if="detail.style == 'link' || detail.style == 'image'">
                                            <label class="form-label" for="detail-uri">Link URI</label>
                                            <input class="form-input" type="url" name="detail-uri" placeholder="https://example.com" v-model="detail.uri" key="detail-uri"/>
                                        </div>

                                        <div class="form-group" v-if="detail.style == 'link' || detail.style == 'attachment'">
                                          <label class="form-label" for="detail-cta">Button Text</label>
                                          <input class="form-input" type="text" name="detail-cta" v-model="detail.cta" key="detail-cta"/>
                                        </div>

                                        <div class="form-group" v-if="detail.style == 'form'">
                                          <label class="form-label">Form Field</label>
                                          <draggable tag="ul" :list="detail.fields" class="list-group empty-list" handle=".tablehandle">
                                            <li
                                              class="list-group-item card"
                                              v-for="(field, idx) in detail.fields"
                                              v-bind:key="'form'+idx">
                                              <div class="input-group">

                                                <div class="form-fields">

                                                  <div class="form-group">
                                                    <label class="form-label" for="form-field-type">Field Type</label>
                                                    <v-select class="selector" :clearable="false" :options="formFieldTypes" v-model="detail.fields[idx].fieldType" :reduce="fieldType => fieldType.code"></v-select>
                                                  </div>

                                                  <div class="form-group">
                                                    <label class="form-label" for="form-field-title">Field Title</label>
                                                    <input class="form-input" type="text" name="form-field-title" v-model="detail.fields[idx].title"/>
                                                  </div>

                                                  <div class="form-group" v-if="detail.fields[idx].fieldType !== 'select' && detail.fields[idx].fieldType !== 'checkbox'">
                                                    <label class="form-label" for="form-field-placeholder">Placeholder</label>
                                                    <input class="form-input" type="text" name="form-field-placeholder" v-model="detail.fields[idx].placeholder"/>
                                                  </div>

                                                  <div class="form-group">
                                                    <label class="form-label" for="form-field-required">Required</label>
                                                    <input type="checkbox" v-model="detail.fields[idx].required">
                                                  </div>
                                                </div>

                                                <div>
                                                  <trash-2-icon @click="removeFormField(idx, detail)"></trash-2-icon>
                                                  <move-icon class="tablehandle"></move-icon>
                                                </div>

                                              </div>
                                            </li>
                                          </draggable>
                                          <plus-circle-icon @click="addFormField(detail)"></plus-circle-icon>                                          
                                        </div>
                                    </div>
                                </div>
                                <div class="actions">
                                  <trash-2-icon @click="deleteDetail(translation.details, detailIdx)"></trash-2-icon>
                                  <move-icon class="move"></move-icon>
                                </div>

                            </div>
                        </div>
                    </draggable>
                    <div style="margin-top: -100px" v-show="translation.details.length == 0">
                        <p class="empty-title h5">This template is empty</p>
                        <p class="empty-subtitle">Drag and drop components you want into this area from the right sidebar.</p>
                    </div>
                </div>
            </div>
          </div>
          </div>
        </div>
      </div>
      <div class="column col-4 col-md-12">
            <p>Add text, images and other content, and style your template. </p>
            <div :class="{ stickycomponents: sticky }" style="" ref="components">
                <div class="col-12">
                    <div class="tab-container">
                        <ul class="tab tab-block">
                            <li class="tab-item">
                                <a @click.prevent="setActive('components')" :class="{ active: isActive('components') }" href="#components">Components</a>
                            </li>
                            <li class="tab-item">
                                <a class="tab-link" @click.prevent="setActive('styles')" :class="{ active: isActive('styles') }" href="#styles">Styling</a>
                            </li>
                            <li class="tab-item">
                                <a @click.prevent="setActive('advanced')" :class="{ active: isActive('advanced') }" href="#advanced">Advanced</a>
                            </li>
                        </ul>
                    </div>
                    <div class="tab-content">
                        <div class="tab-pane fade" :class="{ 'active d-none': !isActive('components') }" id="components">
                            <draggable
                                class="components"
                                :list="components"
                                :group="{ name: 'details', pull: 'clone', put: false }"
                                :clone="cloneDetail"
                                >
                                <div
                                    class="card component-container"
                                    v-for="element in components"
                                    :key="element.style">
                                    <div class="component content">
                                        <div class="icon">
                                            <pie-chart-icon v-if="element.style == 'chart'"></pie-chart-icon>
                                            <image-icon v-else-if="element.style == 'image'"></image-icon>
                                            <link-icon v-else-if="element.style == 'link'"></link-icon>
                                            <type-icon v-else-if="element.style == 'text'"></type-icon>
                                            <paperclip-icon v-else-if="element.style == 'attachment'"></paperclip-icon>
                                            <list-icon v-else-if="element.style == 'results'"></list-icon>
                                            <check-square-icon v-else-if="element.style == 'form'"></check-square-icon>
                                            <div class="info">
                                                <div class="name">{{ componentDescriptions[element.style].name }}</div>
                                                <div class="description">{{ componentDescriptions[element.style].description }}</div>
                                            </div>
                                        </div>
                                        <move-icon class="move"></move-icon>
                                    </div>
                                </div>
                            </draggable>
                        </div>
                        <div class="tab-pane fade" :class="{ 'active d-none': !isActive('styles') }" id="styles">
                            <div class="card" style="overflow: visible">
                              <div class="scrollbox style">
                                <div class="form-horizontal content">
                                    <div class="form-group">
                                        <div class="col-3 col-sm-12">
                                            <label class="form-label" for="titles-font">Title Font</label>
                                        </div>
                                        <div class="col-9 col-sm-12">
                                            <v-select class="selector" :clearable="false" :options="fonts" :reduce="font => font.family" :value="normalizedStyling.title_font" @input="val => updateStyling('title_font', val)" label="family"></v-select>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-3 col-sm-12">
                                            <label class="form-label" for="body-font">Body Font</label>
                                        </div>
                                        <div class="col-9 col-sm-12">
                                            <v-select class="selector" :clearable="false" :options="fonts" :reduce="font => font.family" :value="normalizedStyling.body_font" @input="val => updateStyling('body_font', val)" label="family"></v-select>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-3 col-sm-12">
                                            <label class="form-label" for="text-align">Text Align</label>
                                        </div>
                                        <div class="col-9 col-sm-12">
                                            <v-select class="selector" :clearable="false" :options="alignments" :value="normalizedStyling.text_align" @input="val => updateStyling('text_align', val.val)" label="label"></v-select>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-6 col-sm-12">
                                            <label class="form-label" for="text-color">Text Color</label>
                                        </div>
                                        <div style="white-space: nowrap: overflow: hidden;" class="col-5 col-sm-12">
                                            <v-popover style="display: inline-block;">
                                              <button style="display: inline-block;" class="button-tool tooltip-target" >Select Color</button>
                                              <template slot="popover">
                                                <div class="popover-color">
                                                  <color-picker :disableAlpha="true" :value="normalizedStyling.main_color" @input="val => updateStyling('main_color', val.hex)" />
                                                </div>
                                              </template>
                                            </v-popover>
                                          </div>
                                          <tip-modal style="display: inline-block; padding-top: 1.5%;" title="Text Color" text='Set the color of the displayed text'></tip-modal><br />
                                    </div>
                                    <div class="form-group">
                                        <div class="col-6 col-sm-12">
                                            <label class="form-label" for="text-color">Title Color</label>
                                        </div>
                                        <div class="col-5 col-sm-12">
                                            <v-popover>
                                              <button class="button-tool tooltip-target" >Select Color</button>
                                              <template slot="popover">
                                                <div class="popover-color">
                                                  <color-picker :disableAlpha="true" :value="normalizedStyling.title_color" @input="val => updateStyling('title_color', val.hex)" />
                                                </div>
                                              </template>
                                            </v-popover>
                                        </div>
                                        <tip-modal style="display: inline-block; padding-top: 1.5%;" title="Title Color" text='Set the color of the headings and titles'></tip-modal><br />
                                    </div>

                                    <div class="form-group">
                                        <div class="col-6 col-sm-12">
                                            <label class="form-label" for="container-background-color">Container Background Color</label>
                                        </div>
                                        <div class="col-5 col-sm-12">
                                            <v-popover>
                                              <button class="button-tool tooltip-target" >Select Color</button>
                                              <template slot="popover">
                                                <div class="popover-color">
                                                  <color-picker :disableAlpha="true" :value="normalizedStyling.container_background_color" @input="val => updateStyling('container_background_color', val.hex)" />
                                                </div>
                                              </template>
                                            </v-popover>
                                        </div>
                                        <tip-modal style="display: inline-block; padding-top: 1.5%;" title="Container Background Color" text='Set the color of the content card (area that the information is displayed)'></tip-modal><br />
                                    </div>

                                    <div class="form-group">
                                        <div class="col-6 col-sm-12">
                                            <label class="form-label" for="background-color">Background Color</label>
                                        </div>
                                        <div class="col-5 col-sm-12">
                                            <v-popover>
                                              <button class="button-tool tooltip-target" >Select Color</button>
                                              <template slot="popover">
                                                <div class="popover-color">
                                                  <color-picker :disableAlpha="true" :value="normalizedStyling.main_background_color" @input="val => updateStyling('main_background_color', val.hex)" />
                                                </div>
                                              </template>
                                            </v-popover>
                                        </div>
                                        <tip-modal style="display: inline-block; padding-top: 1.5%;" title="Background Color" text='Set the background color of the page'></tip-modal><br />
                                    </div>

                                    <div class="form-group">
                                        <div class="col-6 col-sm-12">
                                            <label class="form-label" for="gallery-background-color">Gallery Background Color</label>
                                        </div>
                                        <div class="col-5 col-sm-12">
                                            <v-popover>
                                              <button class="button-tool tooltip-target" >Select Color</button>
                                              <template slot="popover">
                                                <div class="popover-color">
                                                  <color-picker :disableAlpha="true" :value="normalizedStyling.gallery_background_color" @input="val => updateStyling('gallery_background_color', val.hex)" />
                                                </div>
                                              </template>
                                            </v-popover>
                                        </div>
                                        <tip-modal style="display: inline-block; padding-top: 1.5%;" title="Gallery Background Color" text='Set the background color of the gallery'></tip-modal><br />
                                    </div>

                                    <div class="form-group">
                                        <div class="col-6 col-sm-12">
                                            <label class="form-label" for="secondary-color">Secondary Color</label>
                                        </div>
                                        <div class="col-5 col-sm-12">
                                            <v-popover>
                                              <button class="button-tool tooltip-target" >Select Color</button>
                                              <template slot="popover">
                                                <div class="popover-color">
                                                  <color-picker :disableAlpha="true" :value="normalizedStyling.secondary_color" @input="val => updateStyling('secondary_color', val.hex)" />
                                                </div>
                                              </template>
                                            </v-popover>
                                        </div>
                                        <tip-modal style="display: inline-block; padding-top: 1.5%;" title="Secondary Color" text='Set the color of the table stripe'></tip-modal><br />
                                    </div>

                                    <div class="form-group">
                                        <div class="col-6 col-sm-12">
                                            <label class="form-label" for="button-color">Button Color</label>
                                        </div>
                                        <div class="col-5 col-sm-12">
                                            <v-popover>
                                              <button class="button-tool tooltip-target" >Select Color</button>
                                              <template slot="popover">
                                                <div class="popover-color">
                                                  <color-picker :disableAlpha="true" :value="normalizedStyling.button_color" @input="val => updateStyling('button_color', val.hex)" />
                                                </div>
                                              </template>
                                            </v-popover>
                                        </div>
                                        <tip-modal style="display: inline-block; padding-top: 1.5%;" title="Button Color" text='Set the color of the buttons'></tip-modal><br />
                                    </div>

                                    <div class="form-group">
                                        <div class="col-6 col-sm-12">
                                            <label class="form-label" for="button-text-color">Button Text Color</label>
                                        </div>
                                        <div class="col-5 col-sm-12">
                                            <v-popover>
                                              <button class="button-tool tooltip-target" >Select Color</button>
                                              <template slot="popover">
                                                <div class="popover-color">
                                                  <color-picker :disableAlpha="true" :value="normalizedStyling.button_text_color" @input="val => updateStyling('button_text_color', val.hex)" />
                                                </div>
                                              </template>
                                            </v-popover>
                                        </div>
                                        <tip-modal style="display: inline-block; padding-top: 1.5%;" title="Button Text Color" text='Set the color of the button text'></tip-modal><br />
                                    </div>

                                    <div class="form-group">
                                        <div class="col-6 col-sm-12">
                                            <label class="form-label" for="border-radius">Border Radius</label>
                                        </div>
                                        <div class="col-6 col-sm-12">
                                            <input class="form-input" type="number" min="0" max="32" id="border-radius" :value="normalizedStyling.main_border_radius" @input="event => updateStyling('main_border_radius', event.target.value)"/>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-6 col-sm-12">
                                            <label class="form-label" for="gallery-border-radius">Gallery Border Radius</label>
                                        </div>
                                        <div class="col-6 col-sm-12">
                                            <input class="form-input" type="number" min="0" max="32" id="gallery-border-radius" :value="normalizedStyling.gallery_border_radius" @input="event => updateStyling('gallery_border_radius', event.target.value)"/>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-6 col-sm-12">
                                            <label class="form-label" for="button-border-radius">Button Border Radius</label>
                                        </div>
                                        <div class="col-6 col-sm-12">
                                            <input class="form-input" type="number" min="0" max="32" id="button-border-radius" :value="normalizedStyling.button_radius" @input="event => updateStyling('button_radius', event.target.value)"/>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <div class="col-12 col-sm-12">
                                            <button @click="() => { this.template.meta.styling = {} }"  class="button-tool reset-btn"><refresh-ccw-icon class="icon"></refresh-ccw-icon>Reset Styling</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" :class="{ 'active d-none': !isActive('advanced') }" id="advanced">

                            <div class="card">
                                <div class="content">
                                    <h5>Other</h5><br />
                                    
                      <ValidationProvider name="template-name" rules="required|min:3" v-slot="{ errors }">
                        <div class="form-group" v-bind:class="{ 'has-error': errors[0] }">
                            <div class="col-6 col-sm-12">
                                <label class="form-label" for="template-name">Template Name</label>
                            </div>
                            <div class="col-9 col-sm-12">
                                <input class="form-input" type="text" id="template-name" v-model="template.name" name="name">
                                <p class="form-input-hint">{{ errors[0] }}</p>
                            </div>
                        </div>
                      </ValidationProvider>

                      <ValidationProvider name="template-sku" rules="required|min:3" v-slot="{ errors }">
                        <div class="form-group" v-bind:class="{ 'has-error': errors[0] }">
                            <div class="col-6 col-sm-12">
                                <label class="form-label" for="template-sku">Template SKU</label>
                            </div>
                            <div class="col-9 col-sm-12">
                                <input class="form-input" type="text" id="template-sku" v-model="template.sku" name="sku">
                                <p class="form-input-hint">{{ errors[0] }}</p>
                            </div>
                        </div>
                      </ValidationProvider>
                                    <button class="button-tool" @click="archiveTemplate">Archive BouncePage</button><tip-modal title="Archive BouncePage" text='Archiving a BouncePage removes access to the BouncePage from your account. The BouncePage will remain active on our system, so as to not interfere with any codes printed on packaging, etc. If you wish to have a BouncePage permenantly removed, please contact us.'></tip-modal>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
      </div>
      <div class="column col-3 col-md-12"> 
        <!-- preview box -->
        <p>Preview</p>
        <div :class="{ sticky: sticky }" style="padding-top: 26px">
      <Preview></Preview>
        </div>
      </div>
    </div>
</div>

      
</template>


<script>
import draggable from "vuedraggable";
import {
  Chrome
} from "vue-color";
import axios from 'axios';
import { ValidationProvider, extend } from 'vee-validate';
import { required, min, regex } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

extend('min', {
  ...min,
  message: 'This field must be longer than 3 characters.'
});

extend('regex', {
  ...regex,
  message: 'This GTIN is invalid.'
});

import bugsnagClient from '../utils/bugsnag';

import FileSelector from '../components/FileSelector';
import ConfirmDialog from '../components/ConfirmDialog';
import FieldModal from './modals/FieldModal';
import NewCodesModal from './modals/NewCodesModal';
import TipModal from '../components/Tip';
import SubscriptionModal from './modals/SubscriptionModal';

import { mapActions, mapGetters } from 'vuex';
import {
  PieChartIcon,
  ImageIcon,
  LinkIcon,
  PaperclipIcon,
  TypeIcon,
  ListIcon,
  MoveIcon,
  PlusIcon,
  ArrowLeftCircleIcon,
  EyeIcon,
  ChevronRightIcon,
  PlusCircleIcon,
  MinusCircleIcon,
  EditIcon,
  SaveIcon,
  Trash2Icon,
  RefreshCcwIcon,
  CheckSquareIcon
} from 'vue-feather-icons';

import locales from '../locales.json';
import { duration } from 'moment';
import Preview from '../views/Preview.vue';

export default {
  name: "TemplateEditor",
  order: 2,
  components: {
    ValidationProvider,
    draggable,
    FileSelector,
    'color-picker': Chrome,
    PieChartIcon,
    PlusCircleIcon,
    MinusCircleIcon,
    ImageIcon,
    SaveIcon,
    LinkIcon,
    PaperclipIcon,
    TypeIcon,
    ListIcon,
    MoveIcon,
    PlusIcon,
    ArrowLeftCircleIcon,
    ChevronRightIcon,
    EditIcon,
    Trash2Icon,
    RefreshCcwIcon,
    CheckSquareIcon,
    TipModal,
    EyeIcon,
    Preview,
    NewCodesModal,
  },
  mounted() {
    window.addEventListener('scroll', this.onScroll)
    this.retrieveFonts();
    this.getTemplate(this.$route.params.id);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.onScroll)
  },
  beforeRouteLeave(to, from, next) {
    if (this.unsavedTemplate) {
      this.$modal.show(ConfirmDialog, {
        finishedCallback: (confirmed) => {
          if (confirmed) {
            next();
          } else {
            next(false);
          }
        },
        message: 'You have unsaved changes! Are you sure you want to leave?',
        primaryButtonTitle: 'Leave'
      }, {
        height: 150,
        width: 300,
        scrollable: false
      });
    } else {
      next();
    }
  },
  computed: {
    ...mapGetters('account', {
      plan: 'plan'
    }),
    normalizedStyling() {
      let styling = {};
      if (this.template && this.template.meta && this.template.meta.styling != null) {
        styling = this.template.meta.styling;
      }
      return Object.assign({
        title_font: 'PT Sans',
        body_font: 'Lato',
        title_font_source: 'google',
        body_font_source: 'google',
        container_background_color: '#001629',
        main_background_color: '#ffffff',
        main_color: '#121036',
        main_border_radius: '16',
        gallery_background_color: '#000000',
        gallery_border_radius: '8',
        text_align: "left",
        secondary_color: "#f7f8f9",
        button_color: "#efefef",
        button_text_color:"#000000",
        button_radius: "8",
        title_color: '#121036'
      }, styling);
    },
    normalizedAttributes() {
      if (this.template && this.template.attributes) {
        return Object.entries(this.template.attributes).map(([k, v]) => {
          return {
            "key": k,
            "field": v
          }
        }).sort((a, b) => {
          return a.key.toLowerCase().localeCompare(b.key.toLowerCase());
        })
      } else {
        return []
      }
    }
  },
  data() {
    return {
      templateDialog: false,
      sticky: false,
      stickyHeight: 0,
      activeTranslationIndex: 0,
      locales: locales,
      fonts: [],
      show_verified: false,
      mediaTypes: [{
          label: 'Video',
          code: 'video'
        },
        {
          label: 'Image Gallery',
          code: 'images'
        },
        {
          label: 'Single Image',
          code: 'image'
        },
        //{ label: 'Augmented Reality', code: 'ar' },
        {
          label: 'No media selected',
          code: 'none'
        }
      ],
      activeContentItem: 'components',
      components: [{
          style: 'text',
          title: '',
          content: ''
        },
        {
          style: 'image',
          link: ''
        },
        {
          style: 'chart',
          title: ''
        },
        {
          style: 'results',
          title: '',
          subheader: '',
          rows: []
        },
        {
          style: 'attachment',
          title: '',
          file: '',
          cta: 'Download File'
        },
        {
          style: 'link',
          title: '',
          uri: '',
          cta: 'Visit Link'
        },
        {
          style: 'form',
          title: '',
          fields: [{
            title: '',
            fieldType: 'text',
            placeholder: '',
            required: false
          }]
        }
      ],
      chartTypes: [{
          label: 'Doughnut',
          code: 'doughnut'
        },
        {
          label: 'Bar',
          code: 'bar'
        }
      ],
      formFieldTypes: [{
          label: 'Text',
          code: 'text'
        },
        {
          label: 'Phone',
          code: 'phone'
        },
        {
          label: 'Email',
          code: 'email'
        },
        {
          label: 'Textarea',
          code: 'textarea'
        }, 
        {
          label: 'Checkbox',
          code: 'checkbox'
        },
        // {
        //   label: 'Selector',
        //   code: 'select'
        // }
      ],
      componentDescriptions: {
        text: {
          name: 'Text',
          description: 'A freeform text box'
        },
        image: {
          name: 'Image',
          description: 'A responsive image'
        },
        chart: {
          name: 'Chart',
          description: 'A bar or pie chart'
        },
        results: {
          name: 'Table',
          description: 'A responsive multipurpose table'
        },
        attachment: {
          name: 'Attachment',
          description: 'A downloadable attachment'
        },
        link: {
          name: 'Link',
          description: 'A link to external content'
        },
        form: {
          name: 'Form',
          description: 'A user-completable form'
        }
      },
      alignments: [{
        label: 'Left',
        val: 'left'
      },{
        label: 'Center',
        val: 'center'
      },{
        label: 'Right',
        val: 'right'
      }],
      savingTemplate: false,
      unsavedTemplate: false,
      template: null
    };
  },
  watch: {
    template: {
      handler: function(newTemplate, oldTemplate) {
        if (oldTemplate !== null) {
        this.unsavedTemplate = true;
        //send updates to preview
        localStorage.setItem("template", JSON.stringify({ //must stringify, otherwise it bricks
        name: this.template.name,
        gtin: this.template.gtin,
        sku: this.template.sku,
        nucleusId: this.template.nucleusId,
        properties: this.template.properties,
        attributes: this.template.attributes,
        meta: this.template.meta}))
        }
      },
      deep: true
    }
  },
  methods: {
    // navigation and core
    back() {
      this.$router.push({
        name: 'templates'
      });
    },
    openPrev() {
      //wipe any previous template, replace it with a new one
      localStorage.setItem("template", JSON.stringify({
        name: this.template.name,
        gtin: this.template.gtin,
        sku: this.template.sku,
        nucleusId: this.template.nucleusId,
        properties: this.template.properties,
        attributes: this.template.attributes,
        meta: this.template.meta}))
      let routeData = this.$router.resolve({name: 'preview'});
      window.open(routeData.href, "Preview", "width=375,height=812");
    },
    onScroll: function() {
      // handle sticky header
      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop
      if (currentScrollPosition < 0) {
        return
      }
      if (208 < currentScrollPosition) {
        this.stickyHeight = currentScrollPosition
        this.sticky = true;
      } else {
        this.sticky = false;
      }
    },
    isActive(menuItem) {
      return this.activeContentItem === menuItem
    },
    setActive(menuItem) {
      this.activeContentItem = menuItem
    },
    isActiveTranslation(menuItem) {
      return this.activeTranslationIndex === menuItem
    },
    setActiveTranslation(menuItem) {
      this.activeTranslationIndex = menuItem
    },
    // api functions
    retrieveFonts() {
      axios.get('https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyB0NPbv4YbzL4ZQepOO6UqoXPaO94aCz-k')
        .then((res) => {
          // handle success
          if (res.data && res.data.items) {
            this.fonts = res.data.items;
          }
        })
        .catch((err) => {
          bugsnagClient.notify(err);
          this.$notify({
            type: 'error',
            title: 'Failed to retrieve available fonts.',
            text: 'Please try again later.',
            duration: 10000
          })
        })
    },
    getTemplate(id) {
      this.$http.get(`/templates/${id}`).then((res) => {
        this.template = res.data;
        this.unsavedTemplate = false;
      }).catch((err) => {
        bugsnagClient.notify(err);
        this.$notify({
          type: 'error',
          title: 'Failed to retrieve template.',
          text: 'Please try again later.',
          duration: 10000
        })
      });
    },
    saveTemplate() {
      this.savingTemplate = true;
      this.$http.put(`/templates/${this.template.id}`, {
        name: this.template.name,
        gtin: this.template.gtin,
        sku: this.template.sku,
        nucleusId: this.template.nucleusId,
        properties: this.template.properties,
        attributes: this.template.attributes,
        meta: this.template.meta
      }).then((res) => {
        this.unsavedTemplate = false;
      }).catch((err) => {
        bugsnagClient.notify(err);
        let res = err.response.data
        console.log(res.split(/:(.+)/)[1])
        this.$notify({
          type: 'error',
          title: 'Failed to save template.',
          text: 'Please check the template for the following issue(s):' + res.split(/:(.+)/)[1].replaceAll(" Must validate one and only one schema (oneOf),",""),
          duration: 10000,
        })
      }).finally(() => {
        this.savingTemplate = false;
      })
    },
    archiveTemplate() {
      this.$modal.show(ConfirmDialog, {
        finishedCallback: (confirmed) => {
          if (confirmed) {
            return this.$http.delete(`/templates/${this.template.id}`).then((res) => {
              this.$router.go(-1);
            }).catch((err) => {
              bugsnagClient.notify(err);
              this.$notify({
                type: 'error',
                title: 'Failed to archive template.',
                text: 'Please check and try again later.',
                duration: 10000
              })
            })
          }
        },
        message: 'Are you sure you want to archive this template?',
        primaryButtonTitle: 'Archive Template'
      }, {
        height: 150,
        width: 300,
        scrollable: false
      });
    },
    // template editing
    addTranslation() {
      if(this.plan.tier == 'free') {
        this.$modal.show(SubscriptionModal, {
                finishedCallback: () => {
                // refresh credits
                },
                copy: "You need to be a Pro or Teams member to add translations"
            }, {
                adaptive: true,
                minHeight: 400,
                height: 'auto',
                scrollable: false
            })
      } else {
      this.template.properties.translations.push({
        "details": [],
        "language": "en",
        "name": "New Product Title"
      });
    }},
    deleteTranslation(index) {
      this.$modal.show(ConfirmDialog, {
        finishedCallback: (confirmed) => {
          if (confirmed) {
            this.$delete(this.template.properties.translations, index);
            if (this.template.properties.translations.length > 0) {
              this.setActiveTranslation(0)
            }
          }
        },
        message: 'Are you sure you want to delete this translation?',
        primaryButtonTitle: 'Delete'
      }, {
        height: 150,
        width: 300,
        scrollable: false
      });
    },
    activeMediaType(translation) {
      if (translation.video || translation.video === '') {
        return 'video';
      }
      if (translation.ar || translation.ar === '') {
        return 'ar';
      }
      if (translation.image || translation.image === '') {
        return 'image';
      }
      if (translation.images) {
        return 'images';
      }
      return 'none';
    },
    setActiveMediaType(translation, selected) {
      switch (selected.code) {
        case 'video':
          this.$delete(translation, 'images')
          this.$delete(translation, 'ar')
          this.$delete(translation, 'image')
          this.$set(translation, 'video', '')
          break;
        case 'ar':
          this.$delete(translation, 'video')
          this.$delete(translation, 'images')
          this.$delete(translation, 'image')
          this.$set(translation, 'ar', '')
          break;
        case 'images':
          this.$delete(translation, 'video')
          this.$delete(translation, 'ar')
          this.$delete(translation, 'image')
          this.$set(translation, 'images', [])
          break;
        case 'image':
          this.$delete(translation, 'video')
          this.$delete(translation, 'ar')
          this.$delete(translation, 'images')
          this.$set(translation, 'image', '')
          break;
        case 'none':
          this.$delete(translation, 'video')
          this.$delete(translation, 'images')
          this.$delete(translation, 'image')
          this.$delete(translation, 'ar')
          break;
      }
    },
    settings(translation) {
      if (!translation.settings) {
        return {
          autoplay: true
        };
      } else {
        return translation.settings;
      }
    },
    setSettings(translation, key, event) {
      if (!translation.settings) {
        this.$set(translation, 'settings', {})
      }
      this.$set(translation.settings, key, event.target.checked)
    },
    updateFiles(root, key, value) {
      this.$set(root, key, value)
      this.$forceUpdate();
    },
    updateStyling(el, value) {
      if (!this.template.meta) {
        this.template.meta = {
          styling: {}
        }
      } else if (this.template.meta && !this.template.meta.styling) {
        this.template.meta.styling = {}
      }

      this.$set(this.template.meta.styling, el, value)
    },
    cloneDetail(detail) {
      // only copy enumerable props
      let newdetail = {}
      Object.assign(newdetail, detail)
      if(this.plan.tier == 'free' && newdetail.style == 'form')
      {
        this.$modal.show(SubscriptionModal, {
                finishedCallback: () => {
                // refresh credits
                },
                copy: 'Forms are not avaliable in the Community Tier'
            }, {
                adaptive: true,
                minHeight: 400,
                height: 'auto',
                scrollable: false
            })
      } else return newdetail
    },
    deleteDetail(details, detailIdx) {
      this.$modal.show(ConfirmDialog, {
        finishedCallback: (confirmed) => {
          if (confirmed) {
            details.splice(detailIdx, 1)
          }
        },
        message: 'Are you sure you want to delete this element?',
        primaryButtonTitle: 'Delete'
      }, {
        height: 150,
        width: 300,
        scrollable: false
      });
    },
    // replaceable fields (attributes)
    newTemplateAttribute() {
      this.$modal.show(FieldModal, {
        finishedCallback: (field) => {
          if (!this.template.attributes) {
            this.$set(this.template, 'attributes', {});
          }
          this.$set(this.template.attributes, field.name, field)
        },
      }, {
        height: 550,
        minHeight: 600,
        scrollable: false
      });
    },
    editTemplateAttribute(key) {
      this.$modal.show(FieldModal, {
        initialField: this.template.attributes[key],
        finishedCallback: (field) => {
          this.$delete(this.template.attributes, key)
          this.$set(this.template.attributes, field.name, field)
          this.unsavedTemplate = true
          
        },
      }, {
        height: 550,
        minHeight: 600,
        scrollable: false
      });
    },
    deleteTemplateAttribute(key) {
      this.$delete(this.template.attributes, key)
      this.unsavedTemplate = true;
    },
    makeReplaceable(detail) {
      this.$set(detail, 'file', `{{${this.normalizedAttributes[0].key}}}`);
    },
    clearReplaceable(detail) {
      this.$set(detail, 'file', '');
    },
    selectReplaceable(detail, selected) {
      this.$set(detail, 'file', `{{${selected}}}`);
    },
    makeReplaceableVideo(translation) {
      this.$set(translation, 'video', `{{${this.normalizedAttributes[0].key}}}`);
    },
    clearReplaceableVideo(translation) {
      this.$set(translation, 'video', '');
    },
    selectReplaceableVideo(translation, selected) {
      this.$set(translation, 'video', `{{${selected}}}`);
    },
    makeReplaceableImage(translation) {
      this.$set(translation, 'image', `{{${this.normalizedAttributes[0].key}}}`);
    },
    clearReplaceableImage(translation) {
      this.$set(translation, 'image', '');
    },
    selectReplaceableImage(translation, selected) {
      this.$set(translation, 'image', `{{${selected}}}`);
    },
    // chart properties
    changedChartPropertyPosition(e, detail) {
      if (e.moved) {
        var element = detail.data[e.moved.oldIndex];
        var data = detail.data;
        data.splice(e.moved.oldIndex, 1);
        data.splice(e.moved.newIndex, 0, element);
        this.$set(detail, 'data', data);
      }
    },
    removeChartProperty(idx, detail) {
      detail.labels.splice(idx, 1);
      detail.data.splice(idx, 1);
      this.$forceUpdate();
    },
    addChartProperty(detail) {
      if (!detail.labels) {
        this.$set(detail, 'labels', []);
        this.$set(detail, 'data', []);
      }
      detail.labels.push("");
      detail.data.push("");
    },
    removeTableRow(idx, detail) {
      detail.rows.splice(idx, 1);
      this.$forceUpdate();
    },
    addTableRow(detail) {
      if (!detail.rows) this.$set(detail, 'rows', []);
      detail.rows.push({
        title: "",
        detail: "",
        validated: true
      });
    },
    addTableHeader(detail) {
      this.$set(detail, 'header', ["", ""]);
    },
    removeFormField(idx, detail) {
      detail.fields.splice(idx, 1);
      this.$forceUpdate();
    },
    addFormField(detail) {
      if (!detail.fields) this.$set(detail, 'fields', []);
      detail.fields.push({
        title: "",
        fieldType: "text",
        required: false
      });
    },
    newBatch() {
      this.$modal.show(NewCodesModal, {
        finishedCallback: (id) => {
          this.$router.push({
            name: 'batch',
            params: {
              id: id
            },
            query: {
              show: true
            }
          })
        },
        template: this.template
      }, {
        adaptive: true,
        minHeight: 600,
        scrollable: true,
        height: "auto"
      })
    }
  }
};
</script>
<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4px;
}

.empty {
  padding: 0px 0px 20px 0px;
}

.empty-subtitle {
  max-width: 90%;
}

.quickstart {
  background-color: #0085F6;
  box-shadow: none;
  color: white;
  transition: 0.2s;
  opacity: 0.8;
  cursor: pointer;
}

.quickstart:hover {
  opacity: 1;
  box-shadow: 0 7px 14px 0 rgba(59, 65, 94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07);
}

.draggable-details {
  min-height: 120px;
  min-width: 100%;
  padding: 20px;
}

.textarea {
  height: 80px;
}

.stickycomponents {
  @media (min-width: 840px) {
    position: fixed;
    top: 0px;
    width: 28%
  }
}

.sticky {
  @media (min-width: 840px) {
    position: fixed;
    top: 0px;
    max-width: 46%;
    background-color: black;
    z-index: 99;
    // margin-left: -5px;
    max-width: 545px;
    border-radius: 4px;
    border: white;
  }
}

.accordion-header-text {
  font-size: 18px;
  display: inline;
  vertical-align: super;
}

.language-title {
  display: flex;
  justify-content: space-between;
  color: white;
}

.details {
  padding-right: 24px;
}

.empty-list {
  padding: 8px 8px 1px 8px;
  border-radius: 0.1rem;
}

.list-group {
  list-style: none;
}

.tab-container *{
  
  color: white !important;
}

.tab-group {
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;

  .tab-link {
    padding: 12px;
  }
}

.component-container {
  overflow: visible;
}

.component {
  position: relative;
  display: flex;
  padding: 8px;
  padding-left: 15px;
  align-items: center;
  flex-direction: row;
  text-align: left;
  justify-content: space-between;
  vertical-align: middle;
  cursor: grab;

  .icon {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .info {
      margin-left: 15px;

      .name {
        font-size: 18px;
        font-weight: 500;
      }

      .description {
        font-size: 14px;
      }
    }
  }

  .editor {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-grow: 1;

    .info {
      margin-left: 15px;

      .name {
        font-size: 18px;
        font-weight: 500;
      }

      .description {
        font-size: 14px;
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 12px;
    right: 6px;
    cursor: pointer;
    z-index: 1;
    padding: 0 5px;
  }

  .move {
    opacity: 0.5;
  }
}

.rfield {
  display: flex;
  justify-content: space-between;
  border-bottom: #efefef 1px solid;
  padding-top: 6px;
  padding-bottom: 6px;

  .buttons {
    display: flex;
    flex-direction: row;
    margin-top: -8px;
    padding-left: 8px;
  }
}

.scrollbox {
  max-height: 40vh;
  overflow-y: auto;
}

.scrollbox.style {
  max-height: 80vh;
  overflow-y: auto;
}

.scrollbox.style .button-tool {
  height: 33px;
}
.scrollbox::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scrollbox::-webkit-scrollbar:vertical {
  width: 11px;
}

.scrollbox::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  /* should match background, can't be transparent */
  background-color: rgba(0, 0, 0, .5);
}

.vs__dropdown-menu {
  z-index: 99;
}

.reset-btn {
  width: 100%;
  display: block;
}

.form-fields {
  flex-direction: row;
  padding: 16px;
  min-height: 300px;

  .form-input {
    width: 100%;
  }
}

.selector {
  background-color: white;
  border-radius: 4px;
}

.selectorHolder {
  color: black;
}


</style>