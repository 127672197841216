<template>
    <div class="container grid-lg">
      <modals-container>
      </modals-container>
      <div class="columns">
        <div class="column col-12 col-md-12" style="text-align: center;">
          <div>
            <div class="content">
              <h4>Welcome to BouncePage</h4>
              <p>Follow one of the steps below to begin building scannable experiences for your products.</p><br />
              <div class="columns">
              <div class="column col-12 col-md-12">
              <div class="card quickstart" @click="newBatch">
                <div class="content">
                <h5>Create Bounce Page</h5>
                <div>
              <p>
                Jump right in and create a new BouncePage with this QuickStart link
              </p>
                
                <o-button >Create a BouncePage</o-button>
              </div>
              </div>
              </div>
              </div>
              <!-- <div class="column col-6 col-md-12">
              <div class="card quickstart" @click="newLink">
                <div class="content">
                <h5>Create a dynamic link</h5>
                <p>
                  A dynamic link is a scannable QR Code that redirects the visitor based on inferred geolocation, and web browser language.
                </p>
                <br />
                  <o-button>Create a dynamic link</o-button>
                </div>
                </div>
                </div> -->
                </div>
              </div>
              </div>
            </div>
          </div>
          <hr class="divider" />
        <div class="columns">
        <div class="column col-6 col-md-12">
          <div class="card">
            <div class="content" v-if="plan">
              <h4>Usage</h4>
              <p>Currently subscribed to the {{plan.tier | planTier}} plan.</p>
              <h5>{{plan.balance}}</h5>
              <span>Codes Remaining</span>
              <p style="padding-top:16px"><button class="button-tool" @click="manageSubscription">Manage Subscription</button></p>
            </div>
            </div>
        </div>
          <div class="column col-6 col-md-12">
          <div class="card">
            <div class="content">
              <h4>Top 10 SKUs</h4>
              Last 30 days
            </div>
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>SKU</th>
                  <th>Scans</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="skuCount in lastMonthSKUs" v-bind:key="skuCount.sku">
                  <td style="max-width:200px; overflow-x:auto;" v-if="skuCount.sku != 'null'">{{skuCount.sku}}</td>
                  <td v-else>Unassigned SKU</td>
                  <td>{{skuCount.count}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
          </div>
        </div>
      
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { PlusCircleIcon, BarChart2Icon, BoxIcon, InfoIcon } from 'vue-feather-icons';
import SubscriptionModal from './modals/SubscriptionModal';
import NewDigitalLink from './modals/NewDigitalLink';
import NewTemplateModal from './modals/NewTemplateModal';
import bugsnagClient from '../utils/bugsnag';

export default {
  name: 'Home',
  components: {
    PlusCircleIcon,
    BarChart2Icon,
    BoxIcon,
    InfoIcon
  },
  computed: {
    ...mapGetters('scans', {
      lastMonthSKUs: 'lastMonthSKUs'
    }),
    ...mapGetters('account', {
      plan: 'plan'
    })
  },
  filters: {
    planTier: function (tier) {
      if (!tier) return ''
      switch (tier) {
                case 'free':
                    return 'Community';
                case 'starter_m':
                    return 'Team (Monthly)'
                case 'plus_m':
                    return 'Plus (Monthly)'
                case 'starter_a':
                    return 'Team (Annual)'
                case 'plus_a':
                    return 'Plus (Annual)'
                case 'bouncepage_tier1':
                    return 'AppSumo (Tier 1)'
                case 'bouncepage_tier2':
                    return 'AppSumo (Tier 2)'
                default:
                    return '';
            }
    }
  },
  methods: {
    ...mapActions('scans', [
      'setLastMonth'
    ]),
    ...mapActions('account', [
      'loadPlan'
    ]),
    manageSubscription(){
            this.$http.get(`/subscription`).then((subRes) => {
                console.log(subRes)
                window.location.replace(subRes.data);
            }).catch((err) => {
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to open manage subscription.',
                    text: 'Please try again later.'
                })
            })
        },
    changeSubscription(){
      this.$modal.show(SubscriptionModal, {
        finishedCallback: () => {
          // refresh credits
        }
      }, {
        adaptive: true,
        minHeight: 400,
        height: 'auto',
        scrollable: false
      })
    },
    newBatch(){
      if (this.plan.balance == 0) {
        
        this.$modal.show(SubscriptionModal, {
                finishedCallback: () => {
                // refresh credits
                },
                copy: "You have exceeded your quota of codes"
            }, {
                adaptive: true,
                minHeight: 400,
                height: 'auto',
                scrollable: false
            })
      } else {
      this.$modal.show(NewTemplateModal, {
        finishedCallback: (id) => {
          this.$router.push({name: 'template', params: {id: id}})
        }
      }, {
        adaptive: true,
        minHeight: 600,
        scrollable: true
      })
    }},
    newLink(){
      if (this.plan.balance == 0) {
        
        this.$modal.show(SubscriptionModal, {
                finishedCallback: () => {
                // refresh credits
                },
                copy: "You have exceeded your quota of codes"
            }, {
                adaptive: true,
                minHeight: 400,
                height: 'auto',
                scrollable: false
            })
      } else {
      this.$modal.show(NewDigitalLink, {
        finishedCallback: (id) => {
          this.$router.push({name: 'link', params: { id: id }, query: { show: true } })
        }
      }, {
        adaptive: true,
        minHeight: 600,
        scrollable: true
      })
    }}
  },
  created() {
    this.setLastMonth();
    this.loadPlan();
  },
}
</script>
<style lang="scss" scoped>
#map {
  width: 100%;
  height: 500px;
}
.large-icon {
  width: 48px;
  height: 48px;
  display: block;
  margin-bottom: 12px;
}
.tut-tile {
  height: 180px;
}
.icon-sm {
  width: 16px;
  height: 16px;
}

.quickstart {
  height: auto;
  background: #e96443; /* fallback for old browsers */
  background: -webkit-linear-gradient(to left, #e96443, #904e95); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to left, #e96443, #904e95); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  box-shadow: none;
  color: white;
  transition: 0.2s;
  opacity: 0.8;
  cursor: pointer;
}
.quickstart:hover {
  opacity: 1;
  box-shadow: 0 7px 14px 0 rgba(59,65,94, 0.1), 0 3px 6px 0 rgba(0, 0, 0, .07);
}
.btn {
  background-color: #001628;
}
.divider {
  margin-bottom: 25px;
  background-color: #c0c0c0;
  border: none;
  height: 1px;
}
</style>

