<template>
<v-container>
    <v-app-bar
        app
        color="black"
        flat
    >
        <v-container class="py-0 fill-height">
            <a href="https://bounce.page">
            <v-img
                max-height="80"
                max-width="200"
                src="../assets/img/mark-circle.svg"
            ></v-img>
            </a>
            <v-spacer></v-spacer>
            <v-btn
                text
                color="primary"
                :to="{ name: 'login'}"
            >
                Sign in
            </v-btn>
        </v-container>
    </v-app-bar>
    <v-container class="mt-15">
        <v-row class="justify-center d-flex">
            <v-col class="login">
                <v-card
                    shaped
                >
                    <v-card-title>
                        Reset password
                    </v-card-title>
                    <v-card-text>
                        <v-alert
                            border="left"
                            dense
                            type="error"
                            v-if="error"
                        >Failed to reset password.</v-alert>
                        <v-alert
                            border="left"
                            dense
                            type="warning"
                            v-if="(password.length > 0 || confirmPassword.length > 0) && (password != confirmPassword)"
                        >Passwords must match</v-alert>
                        <v-alert
                            border="left"
                            dense
                            type="warning"
                            v-if="(password === confirmPassword) && (password.length > 0 && password.length < 8)"
                        >Passwords must be longer than 7 characters</v-alert>
                        <v-form
                            ref="form"
                            lazy-validation
                        >
                            <v-text-field
                                v-model="password"
                                label="Password"
                                type="password"
                                autocomplete="new-password"
                            ></v-text-field>

                            <v-text-field
                                v-model="confirmPassword"
                                label="Confirm password"
                                type="password"
                                autocomplete="new-password" 
                            ></v-text-field>

                            <v-card-actions class="d-flex justify-end">
                                <v-btn
                                    text
                                    small
                                    color="secondary"
                                    @click="() => this.$router.push({name: 'login'})"
                                >
                                    Return to Sign In
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    @click="reset"
                                    :disabled="((password.length == 0) || (password != confirmPassword)) || ((password === confirmPassword) && (password.length > 0 && password.length < 8))"
                                >
                                    Reset Password
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card-text>
                    <v-overlay
                        :value="isLoading"
                        absolute
                    >
                        <v-progress-circular
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                </v-card>
            </v-col>
        </v-row>
        </v-container>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import bugsnagClient from '../utils/bugsnag';

    export default {
        name: 'PasswordReset',
        data() {
            return {
                token: '',
                password: '',
                confirmPassword: '',
                isLoading: false,
                error: null
            };
        },
        mounted() {
            this.token = this.$route.query.token
        },
        methods: {
            reset() {
                if (this.password != this.confirmPassword) {
                    return;
                }
                this.isLoading = true;
                axios.post(`${process.env.VUE_APP_PAIZA_ENDPOINT}/auth/reset`, {
                    newPassword: this.password
                }, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                })
                .then((res) => {
                    this.isLoading = false;
                    this.$auth.token(null, res.data.accessToken);
                    this.$router.push({name: 'editor_list'})
                    this.$notify({
                        type: 'success',
                        title: 'Password Reset Successfully!',
                        text: 'Successfully reset password for account.'
                    })
                }).catch((err) => {
                    this.isLoading = false;
                    this.error = err
                    console.log(err);
                    bugsnagClient.notify(err);
                })
            }
        }
    }
</script>
<style lang="scss" scoped>
    #reset-modal {
        width: 360px;
        margin: 100px auto;
    }
    .btn {
        margin-top:1rem;
    }
</style>
