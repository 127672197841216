<template>
  <v-container>
    <v-alert dismissible prominent type="success" v-if="showPublishedSuccess">
      <v-row align="center">
        <v-col class="grow font-weight-bold">
          Your Bounce Page is now live!
        </v-col>
        <v-col class="shrink">
          <download-qr-modal
            title="Download QR/View URL"
            v-if="template.meta.linkId"
            :linkId="template.meta.linkId"
            :disabled="!published"
            color="primary"
          />
          <!-- <v-btn color="primary">Download QR/View URL</v-btn> -->
        </v-col>
      </v-row>
    </v-alert>
    <v-row v-if="template">
      <v-col v-if="$vuetify.breakpoint.mdAndUp">
        <v-toolbar elevation="1" dense>
          <v-app-bar-nav-icon @click="backToList">
            <v-icon>mdi-arrow-left</v-icon>
          </v-app-bar-nav-icon>
          <v-toolbar-title>
            Editor - {{ template.name }}
            <v-chip v-if="!published" color="orange">Draft</v-chip>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!unsavedTemplate"
            color="yellow darken-4"
            class="mr-2"
            small
            @click="saveTemplate"
            :loading="savingTemplate"
            >{{ unsavedTemplate ? "Save draft" : "Saved" }}</v-btn
          >

          <v-btn color="green darken-1" @click="publish" :loading="publishing" small
            >{{ !published ? "Publish" : "Re-publish" }}
          </v-btn>

          <download-qr-modal
            title="Download QR"
            v-if="template.meta.linkId"
            :linkId="template.meta.linkId"
            :disabled="!published"
            color="green darken-1"
          />

          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <settings-modal
                @finished="updateSettings"
                :name="template.name"
                :seo="template.meta.seo"
                :isPaid="plan && plan.tier != 'free'"
              ></settings-modal>

              <domain-modal
                @finished="updateDomainSettings"
                :domainId="template.meta.domainId"
                :domains="domains"
                :isPaid="plan && plan.tier != 'free'"
              ></domain-modal>

              <v-list-item link :disabled="!published" @click="viewAnalytics">
                <v-list-item-title>View analytics</v-list-item-title>
              </v-list-item>
              <lt-import :listItem="true" @imported="importFromLT"></lt-import>
              <!-- <DomainSelectorModal :listItem="true"></DomainSelectorModal> -->
              <!-- <v-list-item link @click="importFromLT">
                <v-list-item-title>
                  Import from LinkTree (beta)
                </v-list-item-title>
              </v-list-item> -->
              <v-list-item link :disabled="!published" @click="unpublish">
                <v-list-item-title>Unpublish page</v-list-item-title>
              </v-list-item>
              <delete-dialog
                :listItem="true"
                title="Delete page?"
                text="Are you sure you want to delete this Bounce page?"
                @finished="deletePage"
              />
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-col>
      <v-col v-else>
        <v-toolbar>
          <v-toolbar-title>
            <v-app-bar-nav-icon @click="backToList">
              <v-icon>mdi-arrow-left</v-icon>
            </v-app-bar-nav-icon>
            {{ template.name }}
            <v-chip v-if="!published" color="orange">Draft</v-chip>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list>
              <settings-modal
                @finished="updateSettings"
                :name="template.name"
                :seo="template.meta.seo"
                :isPaid="plan && plan.tier != 'free'"
              ></settings-modal>
              <v-list-item link :disabled="!published" @click="viewAnalytics">
                <v-list-item-title>View analytics</v-list-item-title>
              </v-list-item>
              <lt-import :listItem="true" @imported="importFromLT"></lt-import>
              <!-- <v-list-item link @click="importFromLT">
                <v-list-item-title>
                  Import from LinkTree (beta)
                </v-list-item-title>
              </v-list-item> -->
              <v-list-item link :disabled="!published" @click="unpublish">
                <v-list-item-title>Unpublish page</v-list-item-title>
              </v-list-item>
              <delete-dialog
                :listItem="true"
                title="Delete page?"
                text="Are you sure you want to delete this Bounce page?"
                @finished="deletePage"
              />
            </v-list>
          </v-menu>
        </v-toolbar>
        <div class="d-flex justify-end m-2">
          <v-btn
            :disabled="!unsavedTemplate"
            color="yellow darken-4"
            class="mr-2"
            small
            @click="saveTemplate"
            :loading="savingTemplate"
            >{{ unsavedTemplate ? "Save draft" : "Saved" }}</v-btn
          >
          <v-btn color="green darken-1" @click="publish" small :loading="publishing"
            >{{ !published ? "Publish" : "Re-publish" }}
          </v-btn>

          <download-qr-modal
            title="QR"
            v-if="template.meta.linkId"
            :linkId="template.meta.linkId"
            :disabled="!published"
            color="green darken-1"
          />
        </div>
      </v-col>
    </v-row>
    <BounceEditor
      v-if="template"
      :template="template"
      @updatedTemplate="updatedTemplate"
      @saveTemplate="saveTemplate"
      @publish="publish"
      :savingTemplate="savingTemplate"
      :unsavedTemplate="unsavedTemplate"
      :published="published"
      :publishing="publishing"
    />
    <v-row v-else>
      <v-col>
        <v-card shaped width="100%">
          <v-card-title>
            Bounce Pages

            <v-tooltip
              top
              v-if="plan && (plan.tier == 'free' && templates.length > 0) || (plan.tier.startsWith('plus') && templates.length > 4) || (plan.tier.startsWith('bouncepage_tier1') && templates.length > 4)"
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  <new-template-modal
                    disabled
                    @createdTemplate="templateCreated"
                    :showTemplate="showTemplate"
                  ></new-template-modal>
                </div>
              </template>
              <span>
                {{ plan.tier == 'free' ? 'To create more than one Bounce Page, you\'ll need to upgrade to the Plus or Premium plan.' : 'To create more than 5 Bounce Pages, you\'ll need to upgrade to the Premium plan.' }}
              </span>
            </v-tooltip>
            <new-template-modal
              v-else
              @createdTemplate="templateCreated"
              :showTemplate="showTemplate"
            ></new-template-modal>

            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-banner
            v-if="plan && plan.tier == 'free'"
            single-line
            sticky
            color="primary"
            dense
          >
            <span class="black--text font-weight-medium">
              To create more than one bounce page, you'll need to upgrade to the
              Plus or Premium plan.
            </span>
            <template v-slot:actions>
              <subscription-modal></subscription-modal>
            </template>
          </v-banner>
          <v-banner
            v-if="plan && (plan.tier.startsWith('plus') || plan.tier.startsWith('bouncepage_tier1')) && templates.length > 4"
            single-line
            sticky
            color="primary"
            dense
          >
            <span class="black--text font-weight-medium">
              To create more than five bounce pages, you'll need to upgrade to the
              Premium plan.
            </span>
            <template v-slot:actions>
              <subscription-modal></subscription-modal>
            </template>
          </v-banner>
          <v-data-table
            :headers="templateHeaders"
            :items="templates"
            :options.sync="options"
            :server-items-length="totalTemplates"
            :loading="loading"
            :search="search"
            @click:row="editBouncePage"
            class="elevation-1"
            style="cursor: pointer"
          >
            <template v-slot:item.createdAt="{ item }">
              {{ formatFn(item.createdAt) }}
            </template>
            <template v-slot:item.updatedAt="{ item }">
              {{ formatFn(item.updatedAt) }}
            </template>
            <template v-slot:item.meta="{ item }">
              <v-chip
                v-if="formatPublished(item)"
                color="green"
                text-color="white"
              >
                Published
              </v-chip>
              <v-chip v-if="!formatPublished(item)" color="orange">
                Draft
              </v-chip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const _ = require("lodash");
import axios from "axios";
import Vue from "vue";
import moment from "moment-timezone";
import { mapActions, mapGetters } from "vuex";
import SubscriptionModal from "./modals/SubscriptionModal";
import bugsnagClient from "../utils/bugsnag";

import LinkRules from "../components/editor/LinkRules";
import BounceEditor from "../components/editor/BounceEditor";
import DeleteDialog from "../components/editor/DeleteDialog";

import NewTemplateModal from "./modals/NewTemplateModal";
import DownloadQRModal from "./modals/DownloadQRModal";
import SettingsModal from "../components/editor/SettingsModal.vue";
import DomainModal from "../components/editor/DomainModal.vue";
import LtImport from "../components/editor/ltImport.vue";
import DomainSelectorModal from "./modals/DomainSelectorModal.vue";

export default {
  name: "Editor",
  components: {
    LinkRules,
    BounceEditor,
    NewTemplateModal,
    "download-qr-modal": DownloadQRModal,
    DeleteDialog,
    SubscriptionModal,
    SettingsModal,
    DomainModal,
    LtImport,
    DomainSelectorModal
},
  data() {
    return {
      showTemplate: false,
      loading: false,
      loadingSubscription: false,
      error: null,
      templates: [],
      totalTemplates: 0,
      options: {
        sortBy: [],
        sortDesc: [true],
      },
      search: "",
      templateHeaders: [
        {
          text: "Name",
          align: "start",
          value: "name",
        },
        { text: "Created On", value: "createdAt" },
        { text: "Updated At", value: "updatedAt" },
        {
          text: "Published",
          value: "meta",
        },
      ],
      templateParams: {
        page: 1,
        limit: 10,
        search: "",
        sort: {
          field: "",
          type: "",
        },
      },
      // template handling
      template: null,
      unsavedTemplate: false,
      savingTemplate: false,
      publishing: false,
      showPublishedSuccess: false,
    };
  },
  watch: {
    $route(to, from) {
      console.log(to);
      if (to.params.id) {
        this.loadSingleTemplate(to.params.id);
      } else {
        this.template = null;
        this.loadTemplates();
      }
    },
    options: {
      handler() {
        this.loadTemplates();
      },
      deep: true,
    },
    search: {
      handler() {
        if (this.search.length > 2 || this.search.length === 0) {
          this.loadTemplates();
        }
      },
    },
  },
  computed: {
    ...mapGetters("account", {
      plan: "plan",
      domains: "domains"
    }),
    published: function () {
      console.log(this.template);
      return _.get(this.template, "meta.published", false);
    },
  },
  mounted() {
    if (this.$route.params.id) {
      this.loadSingleTemplate(this.$route.params.id);
      this.loadDomains();
    } else {
      this.template = null;
      this.loadTemplates();
    }
  },
  methods: {
    ...mapActions('account', [
      'loadDomains'
    ]),
    async importFromLT(user) {
      axios
        .get("https://corsproxy.io/?https://linktr.ee/" + user, {
          transformRequest: (data, headers) => {
            delete headers.common;
            return data;
          },
        })
        .then((response) => {
          var fakeDom = new DOMParser().parseFromString(
            response.data,
            "text/html"
          );
          var out = fakeDom.getElementById("__NEXT_DATA__").innerText;
          if (out) {
            let rawLinks = JSON.parse(out);
            const links = [];
            const socialLinks = [];
            const socialActual = {
              style: "social",
              buttons: socialLinks,
            }
            rawLinks.props.pageProps.links.forEach((rawLink) => {
              links.push({
                style: "link",
                title: "",
                cta: rawLink.title || "",
                uri: rawLink.url || "",
              });
            });

            rawLinks.props.pageProps.socialLinks.forEach((rawLink) => {
              if (rawLink.type.toLowerCase() == "email_address") {
                rawLink.type = "mail";
              }
              socialLinks.push({
                provider: rawLink.type.toLowerCase(),
                link: rawLink.url || "",

              })
            });
            this.template.properties.translations[0].details.push(...links);
            this.template.properties.translations[0].name=rawLinks.props.pageProps.account.pageTitle;
            this.template.properties.translations[0].details.push(socialActual);
          }
        });
    },
    async loadTemplates() {
      this.loading = true;
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      try {
        let res = await Vue.axios.get("/templates", {
          params: {
            page,
            limit: itemsPerPage,
            search: this.search,
            sort: {
              field: sortBy[0] ? sortBy[0] : "updatedAt",
              type: sortDesc[0] ? "desc" : "asc",
            },
          },
        });
        this.templates = res.data.templates;
        this.totalTemplates = res.data.count;
        if (res.data.count === 0) {
          this.showTemplate = true;
        } else {
          this.showTemplate = false;
        }
      } catch (err) {
        this.error = "Error loading templates";
        bugsnagClient.notify(err);
        this.$notify({
          type: "error",
          title: "Failed to load Bounce Pages",
          text: "Please try again later.",
        });
      }
      this.loading = false;
    },
    async templateCreated(id) {
      this.loadTemplates();
      this.loadSingleTemplate(id);
    },
    async loadSingleTemplate(id) {
      try {
        let res = await Vue.axios.get(`/templates/${id}`);
        this.template = res.data;
      } catch (err) {
        this.error = "Error loading template";
        bugsnagClient.notify(err);
        this.$notify({
          type: "error",
          title: "Failed to load selected Bounce Page",
          text: "Please try again later.",
        });
      }
    },
    // async newBouncePage() {
    //   this.$modal.show(newBouncePage, {
    //     props: {
    //       onSuccess: () => {
    //         this.loadTemplates();
    //       },
    //     },
    //   });
    // },
    async editBouncePage(row) {
      this.$router.push({
        name: "editor",
        params: {
          id: row.id,
        },
      });
    },
    async updatedTemplate(template) {
      console.log("template updated");
      this.template = template;
      this.unsavedTemplate = true;
    },
    async saveTemplate() {
      this.savingTemplate = true;
      try {
        await this.$http.put(`/templates/${this.template.id}`, {
          name: this.template.name,
          gtin: this.template.gtin,
          sku: this.template.sku,
          nucleusId: this.template.nucleusId,
          properties: this.template.properties,
          attributes: this.template.attributes,
          meta: this.template.meta,
        });
        this.unsavedTemplate = false;
      } catch (err) {
        console.warn(err);
        bugsnagClient.notify(err);
        this.$notify({
          type: "error",
          title: "Failed to update Bounce Page",
          text: "Please try again later.",
        });
      }
      this.savingTemplate = false;
    },
    async updateDomainSettings({domainId}) {
      this.template.meta.domainId = domainId;
      this.unsavedTemplate = true;
    },
    async publish() {
      this.publishing = true;
      try {
        await this.saveTemplate();
        let batchId = this.template.meta.publishedBatchId;
        const redirectionEnabled =
          this.template.meta.redirectionEnabled || false;
        if (!batchId) {
          // no published batchId, so publish batch with saved template
          const batchRes = await this.$http.post(`/batches`, {
            batchRef: "",
            templateId: this.template.id,
            atomCount: 1,
            linksEnabled: false,
            attributes: {},
          });
          batchId = batchRes.data.id;

          const savedTemplate = await this.$http.put(
            `/templates/${this.template.id}`,
            {
              name: this.template.name,
              gtin: this.template.gtin,
              sku: this.template.sku,
              nucleusId: this.template.nucleusId,
              properties: this.template.properties,
              attributes: this.template.attributes,
              meta: {
                ...this.template.meta,
                publishedBatchId: batchId,
                published: true,
              },
            }
          );
          this.template = savedTemplate.data;
          this.template.meta.published = true;
          this.showPublishedSuccess = true;
        } else {
          await this.$http.put(`/batches/${batchId}`, {
            templateId: this.template.id,
            attributes: {},
          });
          await this.$http.put(`/templates/${this.template.id}`, {
            name: this.template.name,
            gtin: this.template.gtin,
            sku: this.template.sku,
            nucleusId: this.template.nucleusId,
            properties: this.template.properties,
            attributes: this.template.attributes,
            meta: {
              ...this.template.meta,
              published: true,
            },
          });
        }
        await this.$http.put(`/links/${this.template.meta.linkId}`, {
          rules: this.template.meta.link?.rules || [],
          uri: this.template.meta.link?.uri || "https://bounce.page",
          batchId: redirectionEnabled ? null : batchId,
          domainId: this.template.meta.domainId,
          domainPath: "index"
        });
        this.template.meta.published = true;
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Failed to update Bounce Page",
          text: "Please try again later.",
        });
      }
      this.publishing = false;
      this.$forceUpdate();
    },
    async updateSettings(settings) {
      this.template.name = settings.name;
      this.template.meta.seo = settings.seo;
      this.unsavedTemplate = true;
    },
    async unpublish() {
      try {
        await this.$http.put(`/templates/${this.template.id}`, {
          name: this.template.name,
          gtin: this.template.gtin,
          sku: this.template.sku,
          nucleusId: this.template.nucleusId,
          properties: this.template.properties,
          attributes: this.template.attributes,
          meta: {
            ...this.template.meta,
            published: false,
          },
        });
        await this.$http.put(`/links/${this.template.meta.linkId}`, {
          uri: "https://bounce.page",
          batchId: null,
        });
        this.template.meta.published = false;
      } catch (err) {
        this.$notify({
          type: "error",
          title: "Failed to unpublish Bounce Page",
          text: "Please try again later.",
        });
      }
      this.$forceUpdate();
    },
    async deletePage() {
      await this.$http.delete(`/templates/${this.template.id}`);
      await this.$http.delete(`/links/${this.template.meta.linkId}`);
      this.backToList();
    },
    backToList() {
      this.template = null;
      this.$router.push({
        name: "editor_list",
      }).catch(err => {
        window.location.reload();
      });
    },
    formatFn: function (value) {
      var d = moment.tz(value, "UTC");
      return d.tz(moment.tz.guess()).format("MMM Do, YYYY HH:mm");
    },
    formatPublished: function (value) {
      return _.get(value, "meta.published", false);
    },
    manageSubscription() {
      this.loadingSubscription = true;
      this.$http
        .get(`/subscription?upgrade=true`)
        .then((subRes) => {
          window.location.replace(subRes.data);
        })
        .catch((err) => {
          bugsnagClient.notify(err);
          this.$notify({
            type: "error",
            title: "Failed to open manage subscription.",
            text: "Please try again later.",
          });
        });
    },
    viewAnalytics() {
      this.$router.push({
        name: "analytics",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>
