<template>
<!-- <div>
  <div v-if="loading" class="loading-cover">
    <div>
    <div class="loading loading-lg"></div>
    <span>Loading data for export..</span>
    </div>
  </div>
  <div class="export-modal">
      <div class="header">
        <h3>Export Data</h3>
      </div>
      <div class="export-modal-content">
          <p>
              {{description}}
          </p>
      </div>
      <div class="export-footer">
        <button class="button-tool" @click="downloadData"><download-icon class="icon"></download-icon> Download Data</button>
      </div>
  </div>
  <div class="export-helper">
      <div class="container">
          <div class="columns">
              <div class="column col-2">
                   <info-icon></info-icon>
              </div>
              <div class="column col-10">
                   <p>Data is exported in CSV format.
                    </p>
              </div>
          </div>
      </div>
  </div>
  </div> -->
  <div> 
    <v-dialog
          v-model="templateDialog"
          max-width="450"
        >
          <template v-slot:activator="{ on, attrs }">
              <v-btn
                            v-bind="attrs" v-on="on"
                            depressed
                            color="primary"
                            @click="loadExportData"
                        >
                            Export Analytics
                        </v-btn>
          </template>
          
          <v-card>
            <v-card-title class="text-h5">
              Export Analytics
            </v-card-title>
            <v-card-text>
The following analytics data will be exported: Atom ID, Link ID, Batch ID, Batch Reference, SKU, Location, Browser Name, Browser Version, Locale, Sanitized IP Address, and OS            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="secondary"
                text
                @click="templateDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="downloadData"
              >
                Export Analytics
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue';
import Download from 'downloadjs';
import PapaParse from 'papaparse';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import { VueAutosuggest } from 'vue-autosuggest';
import { ArrowRightCircleIcon, InfoIcon, DownloadIcon } from 'vue-feather-icons';

import bugsnagClient from '../../utils/bugsnag';

import CreditsModal from './CreditsModal';

export default {
  name: 'ExportModal',
  props: ['id', 'exportType', 'data', 'templateDialog'],
  data() {
    return {
      loading: true,
      description: '',
      items: [],
      csv: null
    }
  },
  components: {
    ArrowRightCircleIcon, InfoIcon, DownloadIcon,
    VueAutosuggest
  },
  mounted() {
    this.loadExportData().catch((err) => {
      bugsnagClient.notify(err);
      this.$notify({
        type: 'error',
        title: 'Failed to load data for export.',
        text: 'Please try again later.'
      })
      return;
    })
  },
  methods: {
    loadExportData(){
      this.loading = true;
      switch (this.exportType) {
        case 'batch': {
          this.description = `The following columns will be exported for this batch: ID, Unique Code URI, GS1 Digital Link URI (if applicable)`;
          let endpoint = `/batches/${this.id}/export`;
          this.$http.post(endpoint).then((res) => {
              this.items = res.data.map((item) => {
                  let exportItem = {
                      ID: item.id,
                      'Unique Code URI': `${Vue.config.sulde}/p/${item.slugId}`
                  };
                  if (item.link && item.link.gtin) {
                      exportItem['GS1 Digital Link'] = `${Vue.config.sulde}/01/${item.link.gtin}/21/${item.link.hashId}`;
                  }
                  return exportItem;
              });
              
              this.loading = false;
          });
          break;
        }
        case 'analytics': {
          this.description = `The following analytics data will be exported: Atom ID, Link ID, Batch ID, Batch Reference, SKU, Location, Browser Name, Browser Version, Locale, Sanitized IP Address, and OS`;
          this.items = this.data.map((item) => {
            return {
              atomId: item.atomId,
              linkId: item.linkId,
              linkName: item.linkName,
              linkUri: item.uri,
              batchId: item.batchId,
              batchRef: item.batchRef,
              sku: item.sku,
              location: item.geog,
              time: item.createdAt,
              ip: item.ip,
              ...item.event,
            }
          });
          this.loading = false;
          break;
        }
        default: {
          break;
        }
      }
    },
    downloadData(){
        // generate exportable data
        this.csv = PapaParse.unparse(this.items, {
            delimiter: ',',
            encoding: 'utf8'
        });
        this.csv = 'SEP=' + ',' + '\r\n' + this.csv
        Download(this.csv, `${this.exportType}-DataExport-${moment().format()}.csv`, 'application/csv')
    }
  }
}
</script>
<style lang="scss" scoped>
  .export-modal {
      padding: 16px;
      color: white;
  }
  .export-footer {
    margin-right: 0px;
    padding-top: 24px;

    .button-tool {
      margin: 0px;
      z-index: 9999999;
    }
    .footer-text {
      display: inline-block;
      margin: 16px;
    }
    margin-bottom: 4px;
  }
  .export-helper {
      background-color: rgb(48, 55, 66);
      padding: 16px 16px 0px 8px;
      bottom: 0;
      position: absolute;
      width: 100%;
      color: white;
  }
</style>
