<template>
  <v-container>
  <modals-container>
  </modals-container>
  <v-navigation-drawer
    app
    color="black lighten-3"
    permanent
    expand-on-hover
    mini-variant
  >
  <div class="justify-space-between d-inline-flex flex-column fill-height">
    <v-list
        nav
        dense
      >
        <v-list-item link :to="{ name: 'editor_list'}">
          <v-list-item-icon>
            <v-icon>mdi-qrcode-edit</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Editor</v-list-item-title>
        </v-list-item>

        <v-list-item link :to="{ name: 'analytics'}">
          <v-list-item-icon>
            <v-icon>mdi-chart-line</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Analytics</v-list-item-title>
        </v-list-item>

        <v-list-item link :to="{ name: 'settings'}">
          <v-list-item-icon>
            <v-icon>mdi-account-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item>
      </v-list>

    <v-list
        nav
        dense
      >
        <v-list-item v-if="identity && identity.claims.roles.includes('master')" link :to="{ name: 'admin'}">
          <v-list-item-icon>
            <v-icon>mdi-shield-crown</v-icon>
          </v-list-item-icon>
          <v-list-item-title>Admin</v-list-item-title>
        </v-list-item>

        <v-dialog
          v-model="logoutDialog"
          persistent
          max-width="290"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item link v-bind="attrs" v-on="on">
              <v-list-item-icon>
                  <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Log Out</v-list-item-title>
              </v-list-item>
          </template>
          <v-card>
            <v-card-title class="text-h5">
              Log out
            </v-card-title>
            <v-card-text>Are you sure you want to log out?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="secondary"
                text
                @click="logoutDialog = false"
              >
                Cancel
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="logout"
              >
                Log Out
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-list>
  </div>
  </v-navigation-drawer>
  <v-main>
    <router-view></router-view>
  </v-main>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { HomeIcon, BarChart2Icon, SettingsIcon, LogOutIcon, InboxIcon } from 'vue-feather-icons';

export default {
  name: 'app',
  components: {
    HomeIcon,
    BarChart2Icon,
    SettingsIcon,
    LogOutIcon,
    InboxIcon,
  },
  data () {
    return {
      logoutDialog: false,
    }
  },
  computed: {
    ...mapGetters('account', {
        identity: 'identity',
        organization: 'organization',
        plan: 'plan',
    })
  },
  methods: {
      ...mapActions('account', [
          'loadIdentity',
          'loadOrganization',
          'loadPlan'
      ]),
      logout: function(){
          this.$auth.logout({redirect: '/login'});
      }
  },
  async mounted() {
    await this.loadIdentity();
    await this.loadOrganization();
    await this.loadPlan();
    
  },
}
</script>
<style lang="scss" scoped>
#content {
    overflow-y: auto;
}
.menu {
    position: fixed;
    left: 0;
    background:#242424;
    height: 100%;
    max-width: 60px;
    
}
.lower {
   position: fixed;
   bottom: 0;
}
.menu-item {
    padding-bottom: 8px;
    margin-left: -3px;
}

.custom-class{
    margin-left: -2px;
}

</style>
