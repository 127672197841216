<template>
  <div>
    <v-row>
      <v-col cols="12" sm="12" md="6" lg="5">
        <v-card
          shaped
          class="editor-container"
          v-if="selectedTab === 'editor' || !$vuetify.breakpoint.smAndDown"
        >
          <v-tabs v-model="activeTranslation" dark dense>
            <v-tab
              v-for="(translation, translationIdx) in draftTemplate.properties
                .translations"
              :key="'translation-' + translationIdx"
            >
              {{
                locales.find((l) => l.code === translation.language).language
              }}
              <delete-dialog
                v-if="translationIdx !== 0 ||
                  draftTemplate.properties.translations.length > 1" 
                small
                color="secondary"
                @finished="deleteTranslation(translationIdx)"
              />
            </v-tab>
            <v-spacer></v-spacer>
            <!-- free tier -->
            <div v-if="plan && plan.tier == 'free'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-btn
                      small
                      color="primary"
                      @click="manageSubscription"
                      :loading="isLoading"
                      style="margin-top: 11px; margin-right: 10px"
                    >
                      <v-icon>mdi-lock-open-variant</v-icon>
                      Unlock Translations
                    </v-btn>
                  </div>
                </template>
                <span>
                  Upgrade to a Team plan to unlock the ability to add locale
                  based translations
                  <br />
                  as well as many other exciting features!
                </span>
              </v-tooltip>
            </div>
            <!-- on paid tier -->
            <div v-else>
              <v-btn height="100%" text small @click="addTranslation">
                <v-icon>mdi-plus</v-icon>
                Add Translation
              </v-btn>
            </div>
          </v-tabs>
          <v-tabs-items v-model="activeTranslation">
            <v-tab-item
              v-for="(translation, translationIdx) in draftTemplate.properties
                .translations"
              :key="'translation-' + translationIdx"
              class="pa-4"
            >
              <!-- component modal -->
              <v-dialog v-model="showComponents">
                <v-card>
                  <v-card-title class="text-h5">
                    Add Component
                    <v-spacer></v-spacer>
                    <v-btn
                      color="secondary"
                      text
                      @click="showComponents = false"
                    >
                      Close
                    </v-btn>
                  </v-card-title>
                  <v-card-text>
                    <v-list flat>
                      <v-list-item
                        v-for="component in components"
                        :key="component.style"
                        class="draggable-item component"
                      >
                        <v-list-item-icon>
                          <v-icon
                            v-text="componentDescriptions[component.style].icon"
                          ></v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ componentDescriptions[component.style].name }}
                            <v-icon
                              size="20"
                              v-if="
                                plan &&
                                plan.tier == 'free' &&
                                componentDescriptions[component.style].locked
                              "
                              >mdi-lock
                            </v-icon>
                          </v-list-item-title>
                          <v-list-item-subtitle
                            v-text="
                              componentDescriptions[component.style].description
                            "
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-btn
                            small
                            color="primary"
                            @click="
                              addComponent(translation.details, component)
                            "
                          >
                            Add
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                    </v-list>
                  </v-card-text>
                </v-card>
              </v-dialog>
              
              <v-select
                :items="locales"
                item-text="language"
                item-value="code"
                label="Language"
                v-model="translation.language"
                hint="Select the language of your page"
                v-if="
                  translationIdx !== 0 ||
                  draftTemplate.properties.translations.length > 1
                "
              ></v-select>
              <v-text-field
                v-model="translation.name"
                counter="60"
                hint="This appears as the title of your page"
                label="Title"
              ></v-text-field>

              <v-expansion-panels accordion>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    Add header content</v-expansion-panel-header
                  >
                  <v-expansion-panel-content>
                    <v-select
                      :items="mediaTypes"
                      item-text="label"
                      item-value="code"
                      label="Media Type"
                      hint="Select the main media type of your page"
                      :value="activeMediaType(translation)"
                      @change="
                        (selected) => setActiveMediaType(translation, selected)
                      "
                    ></v-select>

                    <div v-if="activeMediaType(translation) == 'video'">
                      <file-picker
                        v-bind:key="'translationvideo-' + translationIdx"
                        :files="parseFiles(translation.video)"
                        :allowMultiple="false"
                        v-on:update="updateFiles(translation, 'video', $event)"
                      ></file-picker>
                      <v-checkbox
                        :input-value="settings(translation).autoplay"
                        @change="
                          (val) => {
                            setSettings(translation, 'autoplay', val == true);
                          }
                        "
                      >
                        <template v-slot:label>
                          <div>
                            Enable video autoplay (this will mute your video)
                          </div>
                        </template>
                      </v-checkbox>
                    </div>

                    <div v-if="activeMediaType(translation) == 'images'">
                      <file-picker
                        imageOnly
                        v-bind:key="'translationimages-' + translationIdx"
                        :files="parseFiles(translation.images)"
                        :allowMultiple="true"
                        v-on:update="updateFiles(translation, 'images', $event)"
                      ></file-picker>
                    </div>

                    <!-- <div v-if="activeMediaType(translation) == 'ar'">
                      <file-picker
                        v-bind:key="'translationar-' + translationIdx"
                        :files="parseFiles(translation.ar.usdz)"
                        :allowMultiple="false"
                        v-on:update="
                          updateFiles(translation.ar, 'usdz', $event)
                        "
                        label="Drag and drop a USDZ file"
                        acceptedType="model/vnd.usdz+zip"
                      ></file-picker>
                    </div> -->

                    <div v-if="activeMediaType(translation) === 'youtube'">
                      <v-text-field
                        v-model="translation.youtubeId"
                        label="YouTube Video ID"
                        hint="Enter the ID of the YouTube video you want to embed"
                      ></v-text-field>
                    </div>

                    <div v-if="activeMediaType(translation) === 'profile'">
                      <div class="text-body-2">
                        Upload your avatar and profile banner images
                      </div>
                      <file-picker
                        imageOnly
                        v-bind:key="'translationavatar-' + translationIdx"
                        :files="parseFiles(translation.profile.avatar)"
                        :allowMultiple="false"
                        label="Drag and drop your avatar image"
                        v-on:update="
                          updateFiles(translation.profile, 'avatar', $event)
                        "
                      ></file-picker>
                      <file-picker
                        imageOnly
                        v-bind:key="'translationbanner-' + translationIdx"
                        :files="parseFiles(translation.profile.banner)"
                        :allowMultiple="false"
                        label="Drag and drop your banner image"
                        v-on:update="
                          updateFiles(translation.profile, 'banner', $event)
                        "
                      ></file-picker>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

              <draggable
                class="draggable-items pa-2 mt-2 pb-10"
                :list="translation.details"
                group="details"
                handle=".move"
              >
                <v-card
                  class="mx-auto mb-2"
                  outlined
                  v-for="(detail, detailIdx) in translation.details"
                  :key="generateKey(detail, translationIdx, detailIdx)"
                >
                  <v-card-title class="overline">
                    {{ componentDescriptions[detail.style].name }}
                    <v-spacer />

                    <delete-dialog
                      @finished="deleteDetail(translation.details, detailIdx)"
                    />

                    <div v-if="$vuetify.breakpoint.mdAndUp">
                      <v-icon small class="move">mdi-drag-variant</v-icon>
                    </div>
                    <div v-else>
                      <v-btn
                        icon
                        @click="moveDetailUp(translation.details, detailIdx)"
                        v-if="translation.details.length > 0 && detailIdx > 0"
                      >
                        <v-icon small>mdi-arrow-up-bold</v-icon>
                      </v-btn>
                      <v-btn
                        icon
                        @click="moveDetailDown(translation.details, detailIdx)"
                        v-if="
                          translation.details.length > 0 &&
                          detailIdx != translation.details.length - 1
                        "
                      >
                        <v-icon small>mdi-arrow-down-bold</v-icon>
                      </v-btn>
                    </div>
                  </v-card-title>
                  <v-card-text>
                    <v-text-field
                      v-if="
                        detail.style != 'link' &&
                        detail.style != 'image' &&
                        detail.style != 'social' &&
                        detail.style != 'embed' &&
                        detail.style != 'bounceApp'
                      "
                      v-model="detail.title"
                      label="Text Title"
                      hint="Enter the title for this element"
                    ></v-text-field>
                    <v-textarea
                      v-if="detail.style == 'text'"
                      v-model="detail.content"
                      label="Text Content"
                      hint="Enter the text content for this element"
                      filled
                      auto-grow
                    ></v-textarea>
                    <file-picker
                      v-if="
                        detail.style == 'image' || detail.style == 'attachment'
                      "
                      imageOnly
                      :files="parseFiles(detail.file)"
                      :allowMultiple="false"
                      v-on:update="updateFiles(detail, 'file', $event)"
                    ></file-picker>
                    <v-text-field
                      v-if="detail.style == 'link' || detail.style == 'image'"
                      v-model="detail.uri"
                      label="Link URL"
                      placeholder="https://example.com"
                      hint="Enter the link for this element"
                      :rules="[rules.required, rules.protocol]"
                      autocomplete="off"
                      autocapitalize="off"
                    />
                    <v-text-field
                      v-if="
                        detail.style == 'link' || detail.style == 'attachment'
                      "
                      v-model="detail.cta"
                      label="Button Text"
                      hint="Enter the text that appears on the link button"
                    />
                    <v-autocomplete
                      v-model="detail.icon"
                      v-if="detail.style == 'link'"
                      :items="icons"
                      :filter="iconFilter"
                      item-text="name"
                      item-value="id"
                      label="Button icon"
                      @change="
                        (selected) => {
                          if (!selected) {
                            detail.icon = '';
                          } else {
                            detail.icon = selected;
                          }
                        }
                      "
                    >
                      <template v-slot:selection="data">
                        <v-list-item-avatar>
                          <i :class="data.item.class"></i>
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title>{{
                            data.item.name
                          }}</v-list-item-title>
                        </v-list-item-content>
                      </template>
                      <template v-slot:item="data">
                        <template>
                          <v-list-item-avatar>
                            <i :class="data.item.class"></i>
                          </v-list-item-avatar>
                          <v-list-item-content>
                            <v-list-item-title>{{
                              data.item.name
                            }}</v-list-item-title>
                          </v-list-item-content>
                        </template>
                      </template>
                    </v-autocomplete>
                    <file-picker
                      v-if="detail.style == 'link'"
                      imageOnly
                      :files="parseFiles(detail.file)"
                      :allowMultiple="false"
                      v-on:update="updateFiles(detail, 'file', $event)"
                      label="Drag and drop a button image"
                    ></file-picker>
                    <div v-if="detail.style == 'results'">
                      <div
                        v-for="(header, idx) in detail.header"
                        v-bind:key="idx"
                      >
                        <v-text-field
                          v-model="detail.header[idx]"
                          label="Table Column"
                          dense
                        />
                      </div>
                      <v-list dense>
                        <draggable
                          tag="ul"
                          :list="detail.rows"
                          class="list-group empty-list"
                          handle=".tablehandle"
                        >
                          <v-list-item
                            v-for="(row, idx) in detail.rows"
                            v-bind:key="'table' + idx"
                          >
                            <v-list-item-content>
                              <v-text-field
                                v-model="detail.rows[idx].title"
                                label="Table Row Title"
                                dense
                              />

                              <v-text-field
                                v-model="detail.rows[idx].detail"
                                label="Table Row Content"
                                dense
                              />
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn icon @click="removeTableRow(idx, detail)">
                                <v-icon color="grey lighten-1"
                                  >mdi-delete</v-icon
                                >
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </draggable>
                      </v-list>
                      <v-btn small color="primary" @click="addTableRow(detail)"
                        >Add row</v-btn
                      >
                    </div>
                    <div v-if="detail.style == 'social'">
                      <v-list dense>
                        <draggable
                          tag="ul"
                          :list="detail.buttons"
                          class="list-group empty-list social-button-list"
                          handle=".handle"
                        >
                          <v-list-item
                            v-for="(button, idx) in detail.buttons"
                            v-bind:key="'socialbutton' + idx"
                          >
                            <v-list-item-content>
                              <v-autocomplete
                                v-model="button.provider"
                                :items="social"
                                item-text="provider"
                                item-value="id"
                                label="Choose provider"
                                @change="
                                  (selected) => {
                                    if (!selected) {
                                      button.provider = '';
                                    } else {
                                      button.provider = selected;
                                    }
                                  }
                                "
                              >
                                <template v-slot:selection="data">
                                  <v-list-item-avatar>
                                    <i
                                      :class="
                                        classForProvider({
                                          provider: data.item.id,
                                        })
                                      "
                                    ></i>
                                  </v-list-item-avatar>
                                  <v-list-item-content>
                                    <v-list-item-title>{{
                                      data.item.provider
                                    }}</v-list-item-title>
                                  </v-list-item-content>
                                </template>
                                <template v-slot:item="data">
                                  <template>
                                    <v-list-item-avatar>
                                      <i
                                        :class="
                                          classForProvider({
                                            provider: data.item.id,
                                          })
                                        "
                                      ></i>
                                    </v-list-item-avatar>
                                    <v-list-item-content>
                                      <v-list-item-title>{{
                                        data.item.provider
                                      }}</v-list-item-title>
                                    </v-list-item-content>
                                  </template>
                                </template>
                              </v-autocomplete>
                              <v-text-field
                                v-if="button.provider"
                                v-model="button.link"
                                label="Link URL"
                                placeholder="https://"
                                hint="Enter the link for this provider"
                                :rules="[rules.required, rules.protocol]"
                                autocomplete="off"
                                autocapitalize="off"
                              />
                              <v-btn
                                v-if="$vuetify.breakpoint.smAndDown"
                                @click="removeSocialButton(idx, detail)"
                              >
                                <v-icon small color="grey lighten-1"
                                  >mdi-delete</v-icon
                                >
                                Delete button
                              </v-btn>
                            </v-list-item-content>
                            <v-list-item-action
                              v-if="$vuetify.breakpoint.mdAndUp"
                            >
                              <div>
                                <v-icon class="handle">mdi-drag-variant</v-icon>
                                <v-btn
                                  icon
                                  @click="removeSocialButton(idx, detail)"
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                        </draggable>
                      </v-list>
                      <v-btn
                        v-if="detail.buttons.length < 5"
                        small
                        color="primary"
                        @click="addSocialButton(detail)"
                        >Add Social Button</v-btn
                      >
                    </div>
                    <div v-if="detail.style == 'embed'">
                      <v-text-field
                        v-model="detail.height"
                        label="Embed Height (px)"
                        dense
                        type="number"
                      />
                      <prism-editor
                        class="my-editor height-300"
                        v-model="detail.content"
                        :highlight="highlighter"
                        line-numbers
                      ></prism-editor>
                    </div>

                    <!-- here's the stuff for the bounce apps -->
                    <div v-if="detail.style == 'bounceApp'">
                      <v-select
                        v-model="detail.app"
                        :items="bounceApps"
                        item-text="label"
                        item-value="embed"
                        label="Choose App"
                        @change="
                          (selected) => {
                            if (!selected) {
                              detail.app = '';
                            } else {
                              detail.app = selected;
                              this.console.log(selected.label);
                            }
                          }
                        "
                      ></v-select>
                      <!-- add in various inputs based on what app has been selected -->
                      <div v-if="detail.app.label == 'YouTube'">
                        <v-text-field
                          v-model="detail.app1.title"
                          label="App Title"
                          dense
                        />
                        <v-text-field
                          v-model="detail.app1.subtitle"
                          label="App Subtitle"
                          dense
                        />
                        <v-text-field
                          v-model="detail.app1.button"
                          label="App Button"
                          dense
                        />
                        <v-text-field
                          v-model="detail.app1.link"
                          label="App Link"
                          dense
                        />
                      </div>
                    </div>

                    <div v-if="detail.style == 'form'">
                      <v-list dense>
                        <draggable
                          tag="ul"
                          :list="detail.fields"
                          class="list-group empty-list"
                          handle=".handle"
                        >
                          <v-list-item
                            v-for="(field, idx) in detail.fields"
                            v-bind:key="'form' + idx"
                          >
                            <v-list-item-content>
                              <v-select
                                :items="formFieldTypes"
                                item-text="label"
                                item-value="code"
                                label="Field Type"
                                hint="Select the form field type"
                                v-model="detail.fields[idx].fieldType"
                              ></v-select>
                              <v-text-field
                                v-model="detail.fields[idx].title"
                                label="Field Title"
                                dense
                              />
                              <v-text-field
                                v-if="
                                  detail.fields[idx].fieldType !== 'checkbox'
                                "
                                v-model="detail.fields[idx].placeholder"
                                label="Placeholder"
                                dense
                              />
                              <v-btn
                                v-if="$vuetify.breakpoint.smAndDown"
                                @click="removeFormField(idx, detail)"
                              >
                                <v-icon small color="grey lighten-1"
                                  >mdi-delete</v-icon
                                >
                                Delete form field
                              </v-btn>
                              <!-- <v-checkbox
                              v-model="detail.fields[idx].required"
                            >
                              <template v-slot:label>
                                <div>
                                  Form input required
                                </div>
                              </template>
                            </v-checkbox> -->
                            </v-list-item-content>
                            <v-list-item-action
                              v-if="$vuetify.breakpoint.mdAndUp"
                            >
                              <div>
                                <v-icon class="handle">mdi-drag-variant</v-icon>
                                <v-btn
                                  icon
                                  @click="removeFormField(idx, detail)"
                                >
                                  <v-icon color="grey lighten-1"
                                    >mdi-delete</v-icon
                                  >
                                </v-btn>
                              </div>
                            </v-list-item-action>
                          </v-list-item>
                        </draggable>
                      </v-list>
                      <v-btn small color="primary" @click="addFormField(detail)"
                        >Add field</v-btn
                      >
                    </div>
                    <div v-if="detail.style == 'contact'">
                      <v-container fluid class="m-0 p-0">
                        <v-row>
                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="detail.contactData.firstname"
                              label="First Name"
                              dense
                              hint="Enter your first name"
                              prepend-icon="mdi-account"
                            />
                          </v-col>

                          <v-col cols="12" sm="6">
                            <v-text-field
                              v-model="detail.contactData.lastname"
                              label="Last Name"
                              dense
                              hint="Enter your last name"
                            />
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-text-field
                        v-model="detail.contactData.phone"
                        label="Phone"
                        dense
                        hint="Enter your phone number"
                        prepend-icon="mdi-phone"
                      />
                      <v-text-field
                        v-model="detail.contactData.email"
                        label="Email"
                        dense
                        hint="Enter your email address"
                        prepend-icon="mdi-email"
                      />
                      <v-text-field
                        v-model="detail.contactData.address"
                        label="Address"
                        dense
                        hint="Enter your address"
                        prepend-icon="mdi-map-marker"
                      />
                      <v-text-field
                        v-model="detail.contactData.company"
                        label="Company Name"
                        dense
                        hint="Enter your comapny name"
                        prepend-icon="mdi-account-card-details"
                      />
                      <v-text-field
                        v-model="detail.contactData.jobtitle"
                        label="Job Title"
                        dense
                        hint="Enter your job title"
                        prepend-icon="mdi-account-card-details"
                      />
                      <v-text-field
                        v-model="detail.contactData.website"
                        label="Website"
                        dense
                        hint="Enter your website URL"
                        prepend-icon="mdi-web"
                        :rules="[rules.required, rules.protocol]"
                        autocomplete="off"
                        autocapitalize="off"
                      />
                    </div>
                  </v-card-text>
                </v-card>
                <v-btn
                  slot="header"
                  style="width: 100%; margin-bottom: 0.8rem"
                  v-if="$vuetify.breakpoint.smAndDown"
                  @click="showComponents = true"
                  color="primary"
                >
                  <v-icon small>mdi-plus</v-icon>
                  Add component
                </v-btn>
              </draggable>
              <div
                class="pa-4"
                style="margin-top: -160px"
                v-show="translation.details.length == 0"
              >
                <v-icon large class="grey--text mb-2"
                  >mdi-information-outline</v-icon
                >
                <p class="h4">Start building your page!</p>
                <p class="body">
                  Drag and drop components you want into this area from the
                  components sidebar.
                </p>
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
        <v-card
          shaped
          v-if="selectedTab === 'styling' && $vuetify.breakpoint.smAndDown"
        >
          <template-style-picker @templateChosen="styleTemplateChosen" />
          <v-expansion-panels accordion :key="'styling ' + refreshKey">
            <v-expansion-panel>
              <v-expansion-panel-header
                >Text and fonts</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <label class="v-label theme--dark caption" style=""
                  >Title font</label
                >
                <font-picker
                  :api-key="'AIzaSyAXPibrZFfVfB7B0tYr3S3kAP8C3amaa2g'"
                  :options="fontOptions"
                  :active-font="normalizedStyling.title_font"
                  @change="(val) => updateStyling('title_font', val.family)"
                />

                <color-picker
                  :color="normalizedStyling.title_color"
                  @colorChanged="(val) => updateStyling('title_color', val)"
                  label="Title color"
                  hint="Select the title text color"
                />

                <label class="v-label theme--dark caption" style=""
                  >Body font</label
                >
                <font-picker
                  :api-key="'AIzaSyAXPibrZFfVfB7B0tYr3S3kAP8C3amaa2g'"
                  :options="fontOptions"
                  :active-font="normalizedStyling.body_font"
                  @change="(val) => updateStyling('body_font', val.family)"
                />

                <color-picker
                  :color="normalizedStyling.main_color"
                  @colorChanged="(val) => updateStyling('main_color', val)"
                  label="Body color"
                  hint="Select the body text color"
                />
                <v-select
                  :items="alignments"
                  item-text="label"
                  item-value="val"
                  label="Text alignment"
                  hint="Select the text alignment"
                  :value="normalizedStyling.text_align"
                  @input="(val) => updateStyling('text_align', val)"
                ></v-select>
                <v-select
                  :items="titleFontSizes"
                  item-text="label"
                  item-value="val"
                  label="Title font size"
                  hint="Select the title font size"
                  :value="normalizedStyling.title_font_size"
                  @input="(val) => updateStyling('title_font_size', val)"
                ></v-select>
                <v-select
                  :items="bodyFontSizes"
                  item-text="label"
                  item-value="val"
                  label="Body font size"
                  hint="Select the body font size"
                  :value="normalizedStyling.body_font_size"
                  @input="(val) => updateStyling('body_font_size', val)"
                ></v-select>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header
                >Buttons and tables</v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <!-- <v-checkbox
                    label="Enable share button"
                    default-checked="true"
                    :value="(normalizedStyling.share == true)"
                    @change="(val) => updateStyling('share', val)"
                  ></v-checkbox> -->

                <color-picker
                  :color="normalizedStyling.button_color"
                  @colorChanged="(val) => updateStyling('button_color', val)"
                  label="Button background color"
                  hint="Set the background color of buttons"
                />
                <color-picker
                  :color="normalizedStyling.button_text_color"
                  @colorChanged="
                    (val) => updateStyling('button_text_color', val)
                  "
                  label="Button text color"
                  hint="Set the text color of buttons"
                />
                <v-slider
                  :value="normalizedStyling.button_radius"
                  @input="(val) => updateStyling('button_radius', `${val}`)"
                  :thumb-size="18"
                  :max="32"
                  :min="0"
                  label="Button border radius"
                  thumb-label="always"
                >
                </v-slider>
                <v-select
                  :items="buttonFontSizes"
                  item-text="label"
                  item-value="val"
                  label="Button font size"
                  hint="Select the button font size"
                  :value="normalizedStyling.button_font_size"
                  @input="(val) => updateStyling('button_font_size', val)"
                ></v-select>
                <color-picker
                  :color="normalizedStyling.secondary_color"
                  @colorChanged="(val) => updateStyling('secondary_color', val)"
                  label="Table secondary color"
                  hint="Select the color of the alternate table row"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>Background</v-expansion-panel-header>
              <v-expansion-panel-content>
                <color-picker
                  :color="normalizedStyling.container_background_color"
                  @colorChanged="
                    (val) => updateStyling('container_background_color', val)
                  "
                  label="Container background color"
                  hint="Set the color of the content card (where components are displayed)"
                />
                <color-picker
                  :color="normalizedStyling.main_background_color"
                  @colorChanged="
                    (val) => updateStyling('main_background_color', val)
                  "
                  label="Page background color"
                  hint="Set the background color of the page"
                />
                <v-slider
                  :value="normalizedStyling.main_border_radius"
                  @input="
                    (val) => updateStyling('main_border_radius', `${val}`)
                  "
                  :thumb-size="16"
                  :max="32"
                  :min="0"
                  label="Main panel border radius"
                  thumb-label="always"
                ></v-slider>

                <file-picker
                  imageOnly
                  :files="parseFiles(normalizedStyling.background_media)"
                  :allowMultiple="false"
                  label="Drag and drop your background image"
                  v-on:update="
                    (val) => {
                      if (val) {
                        updateStyling('background_media', `${val}`);
                      } else {
                        updateStyling('background_media', '');
                      }
                    }
                  "
                ></file-picker>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>Media</v-expansion-panel-header>
              <v-expansion-panel-content>
                <color-picker
                  :color="normalizedStyling.gallery_background_color"
                  @colorChanged="
                    (val) => updateStyling('gallery_background_color', val)
                  "
                  label="Gallery background color"
                  hint="Set the background color of the image gallery"
                />
                <v-slider
                  :value="normalizedStyling.gallery_border_radius"
                  @input="
                    (val) => updateStyling('gallery_border_radius', `${val}`)
                  "
                  :thumb-size="18"
                  :max="32"
                  :min="0"
                  label="Gallery border radius"
                  thumb-label="always"
                >
                </v-slider>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>Age Check</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox
                  :input-value="normalizedStyling.sensitive"
                  @change="
                    (val) => {
                      updateStyling('sensitive', val == true);
                    }
                  "
                >
                  <template v-slot:label>
                    <div>Turn on Sensitive Material check</div>
                  </template>
                </v-checkbox>

                <v-checkbox
                  :input-value="normalizedStyling.age_gate"
                  @change="
                    (val) => {
                      updateStyling('age_gate', val == true);
                    }
                  "
                >
                  <template v-slot:label>
                    <div>Turn on Age Check</div>
                  </template>
                </v-checkbox>

                <v-slider
                  v-if="normalizedStyling.age_gate"
                  :value="normalizedStyling.age_gate_age"
                  @input="(val) => updateStyling('age_gate_age', `${val}`)"
                  :thumb-size="18"
                  :max="99"
                  :min="0"
                  label="Minimum age"
                  thumb-label="always"
                >
                </v-slider>
                <!-- <input type="checkbox" :checked="normalizedStyling.show_verified" @input="(e) => { updateStyling('show_verified', e.target.checked) }"/> Show verified badge -->
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card>
      </v-col>
      <v-col cols="0" sm="0" md="6" lg="7">
        <v-card
          v-if="
            draftTemplate.meta.link &&
            (selectedTab === 'styling' || !$vuetify.breakpoint.smAndDown)
          "
        >
          <v-card-text>
            <v-row>
              <v-col lg="7" class="redirectText">
                {{
                  redirectionEnabled
                    ? "Page redirection enabled"
                    : "Turn on page redirection"
                }}
              </v-col>
              <v-col lg="5" class="redirectSettings">
                <link-rules
                  :link="draftTemplate.meta.link"
                  :redirectionEnabled="redirectionEnabled"
                  @updatedLink="updatedLink"
                  @enableRedirection="enableRedirection"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-row>
          <v-col sm="12" class="d-none d-md-flex">
            <v-toolbar
              elevation="1"
              dense
              v-show="scrollPosition > 180"
              :style="scrollPositionCSS"
            >
              <v-toolbar-title> Editor - {{ template.name }} </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!unsavedTemplate"
                color="yellow darken-4"
                class="mr-2"
                @click="saveTemplate"
                :loading="savingTemplate"
                >{{ unsavedTemplate ? "Save draft" : "Saved" }}</v-btn
              >

              <v-btn
                color="green darken-1"
                @click="publish"
                :loading="publishing"
                >{{ !published ? "Publish" : "Re-publish" }}
              </v-btn>
            </v-toolbar>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="0" md="12" lg="7">
            <div class="d-none d-md-flex">
              <v-card shaped :style="scrollPositionCSS">
                <v-tabs v-model="tab" dark fixed-tabs>
                  <v-tabs-slider></v-tabs-slider>
                  <v-tab class="components-tab" href="#components">
                    Components
                  </v-tab>

                  <v-tab class="styling-tab" href="#styling"> Styling </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab" class="scrollBox">
                  <v-tab-item value="components">
                    <v-list flat>
                      <draggable
                        class="components"
                        :list="components"
                        :group="{ name: 'details', pull: 'clone', put: false }"
                        :clone="cloneDetail"
                        :sort="false"
                      >
                        <v-list-item
                          v-for="component in components"
                          :key="component.style"
                          class="draggable-item component"
                        >
                          <v-list-item-icon>
                            <v-icon
                              v-text="
                                componentDescriptions[component.style].icon
                              "
                            ></v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ componentDescriptions[component.style].name }}
                              <v-icon
                                size="20"
                                v-if="
                                  plan &&
                                  plan.tier == 'free' &&
                                  componentDescriptions[component.style].locked
                                "
                                >mdi-lock
                              </v-icon>
                            </v-list-item-title>
                            <v-list-item-subtitle
                              v-text="
                                componentDescriptions[component.style]
                                  .description
                              "
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-icon>mdi-drag-variant</v-icon>
                          </v-list-item-action>
                        </v-list-item>
                      </draggable>
                    </v-list>
                  </v-tab-item>
                  <v-tab-item value="styling">
                    <v-card flat>
                      <template-style-picker
                        @templateChosen="styleTemplateChosen"
                      />
                      <v-expansion-panels
                        accordion
                        :key="'styling ' + refreshKey"
                      >
                        <v-expansion-panel>
                          <v-expansion-panel-header
                            >Text and fonts</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <label class="v-label theme--dark caption" style=""
                              >Title font</label
                            >
                            <font-picker
                              :api-key="'AIzaSyAXPibrZFfVfB7B0tYr3S3kAP8C3amaa2g'"
                              :options="fontOptions"
                              :active-font="normalizedStyling.title_font"
                              @change="
                                (val) => updateStyling('title_font', val.family)
                              "
                            />

                            <color-picker
                              :color="normalizedStyling.title_color"
                              @colorChanged="
                                (val) => updateStyling('title_color', val)
                              "
                              label="Title color"
                              hint="Select the title text color"
                            />

                            <label class="v-label theme--dark caption" style=""
                              >Body font</label
                            >
                            <font-picker
                              :api-key="'AIzaSyAXPibrZFfVfB7B0tYr3S3kAP8C3amaa2g'"
                              :options="fontOptions"
                              :active-font="normalizedStyling.body_font"
                              @change="
                                (val) => updateStyling('body_font', val.family)
                              "
                            />

                            <color-picker
                              :color="normalizedStyling.main_color"
                              @colorChanged="
                                (val) => updateStyling('main_color', val)
                              "
                              label="Body color"
                              hint="Select the body text color"
                            />
                            <v-select
                              :items="alignments"
                              item-text="label"
                              item-value="val"
                              label="Text alignment"
                              hint="Select the text alignment"
                              :value="normalizedStyling.text_align"
                              @input="(val) => updateStyling('text_align', val)"
                            ></v-select>
                            <v-select
                              :items="titleFontSizes"
                              item-text="label"
                              item-value="val"
                              label="Title font size"
                              hint="Select the title font size"
                              :value="normalizedStyling.title_font_size"
                              @input="
                                (val) => updateStyling('title_font_size', val)
                              "
                            ></v-select>
                            <v-select
                              :items="bodyFontSizes"
                              item-text="label"
                              item-value="val"
                              label="Body font size"
                              hint="Select the body font size"
                              :value="normalizedStyling.body_font_size"
                              @input="
                                (val) => updateStyling('body_font_size', val)
                              "
                            ></v-select>
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel>
                          <v-expansion-panel-header
                            >Buttons and tables</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <!-- <v-checkbox
                              label="Enable share button"
                              default-checked="true"
                              :value="(normalizedStyling.share == true)"
                              @change="(val) => {updateStyling('share', val == true)}"
                            ></v-checkbox> -->
                            <color-picker
                              :color="normalizedStyling.button_color"
                              @colorChanged="
                                (val) => updateStyling('button_color', val)
                              "
                              label="Button background color"
                              hint="Set the background color of buttons"
                            />
                            <color-picker
                              :color="normalizedStyling.button_text_color"
                              @colorChanged="
                                (val) => updateStyling('button_text_color', val)
                              "
                              label="Button text color"
                              hint="Set the text color of buttons"
                            />
                            <v-slider
                              :value="normalizedStyling.button_radius"
                              @input="
                                (val) =>
                                  updateStyling('button_radius', `${val}`)
                              "
                              :thumb-size="18"
                              :max="32"
                              :min="0"
                              label="Button border radius"
                              thumb-label="always"
                            >
                            </v-slider>
                            <v-select
                              :items="buttonFontSizes"
                              item-text="label"
                              item-value="val"
                              label="Button font size"
                              hint="Select the button font size"
                              :value="normalizedStyling.button_font_size"
                              @input="
                                (val) => updateStyling('button_font_size', val)
                              "
                            ></v-select>
                            <color-picker
                              :color="normalizedStyling.secondary_color"
                              @colorChanged="
                                (val) => updateStyling('secondary_color', val)
                              "
                              label="Table secondary color"
                              hint="Select the color of the alternate table row"
                            />
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel>
                          <v-expansion-panel-header
                            >Background</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <color-picker
                              :color="
                                normalizedStyling.container_background_color
                              "
                              @colorChanged="
                                (val) =>
                                  updateStyling(
                                    'container_background_color',
                                    val
                                  )
                              "
                              label="Container background color"
                              hint="Set the color of the content card (where components are displayed)"
                            />
                            <color-picker
                              :color="normalizedStyling.main_background_color"
                              @colorChanged="
                                (val) =>
                                  updateStyling('main_background_color', val)
                              "
                              label="Page background color"
                              hint="Set the background color of the page"
                            />
                            <v-slider
                              :value="normalizedStyling.main_border_radius"
                              @input="
                                (val) =>
                                  updateStyling('main_border_radius', `${val}`)
                              "
                              :thumb-size="16"
                              :max="32"
                              :min="0"
                              label="Main panel border radius"
                              thumb-label="always"
                            ></v-slider>

                            <file-picker
                              imageOnly
                              :files="
                                parseFiles(normalizedStyling.background_media)
                              "
                              :allowMultiple="false"
                              label="Drag and drop your background image"
                              v-on:update="
                                (val) => {
                                  if (val) {
                                    updateStyling('background_media', `${val}`);
                                  } else {
                                    updateStyling('background_media', '');
                                  }
                                }
                              "
                            ></file-picker>
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel>
                          <v-expansion-panel-header
                            >Media</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <color-picker
                              :color="
                                normalizedStyling.gallery_background_color
                              "
                              @colorChanged="
                                (val) =>
                                  updateStyling('gallery_background_color', val)
                              "
                              label="Gallery background color"
                              hint="Set the background color of the image gallery"
                            />
                            <v-slider
                              :value="normalizedStyling.gallery_border_radius"
                              @input="
                                (val) =>
                                  updateStyling(
                                    'gallery_border_radius',
                                    `${val}`
                                  )
                              "
                              :thumb-size="18"
                              :max="32"
                              :min="0"
                              label="Gallery border radius"
                              thumb-label="always"
                            >
                            </v-slider>
                          </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel>
                          <v-expansion-panel-header
                            >Age Check</v-expansion-panel-header
                          >
                          <v-expansion-panel-content>
                            <v-checkbox
                              :input-value="normalizedStyling.sensitive"
                              @change="
                                (val) => {
                                  updateStyling('sensitive', val == true);
                                }
                              "
                            >
                              <template v-slot:label>
                                <div>Turn on Sensitive Material check</div>
                              </template>
                            </v-checkbox>

                            <v-checkbox
                              :input-value="normalizedStyling.age_gate"
                              @change="
                                (val) => {
                                  updateStyling('age_gate', val == true);
                                }
                              "
                            >
                              <template v-slot:label>
                                <div>Turn on Age Check</div>
                              </template>
                            </v-checkbox>

                            <v-slider
                              v-if="normalizedStyling.age_gate"
                              :value="normalizedStyling.age_gate_age"
                              @input="
                                (val) => updateStyling('age_gate_age', `${val}`)
                              "
                              :thumb-size="18"
                              :max="99"
                              :min="0"
                              label="Minimum age"
                              thumb-label="always"
                            >
                            </v-slider>
                            <!-- <input type="checkbox" :checked="normalizedStyling.show_verified" @input="(e) => { updateStyling('show_verified', e.target.checked) }"/> Show verified badge -->
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </div>
          </v-col>
          <v-col cols="0" lg="5">
            <div :class="previewClasses">
              <v-card
                class="preview"
                width="252px"
                height="500px"
                shaped
                :style="!showPreview ? scrollPositionCSS : ''"
              >
                <v-card-title>
                  Preview
                  <v-spacer></v-spacer>
                  <div class="d-flex d-lg">
                    <v-btn
                      color="primary"
                      elevation="2"
                      small
                      @click="openPreview"
                      >Open</v-btn
                    >
                  </div>
                  <div class="d-flex d-lg-none">
                    <v-btn
                      color="primary"
                      elevation="2"
                      small
                      v-if="showPreview"
                      @click="showPreview = !showPreview"
                      >Close Preview</v-btn
                    >
                  </div></v-card-title
                >
                <v-card-subtitle>(updates every 5 seconds)</v-card-subtitle>
                <Preview
                  :redirectionEnabled="redirectionEnabled"
                  :link="draftTemplate.meta.link"
                />
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- upgrade modal -->
    <v-dialog v-model="showUpgrade" max-width="450">
      <v-card>
        <v-card-title class="text-h5"> Upgrade Plan </v-card-title>
        <v-card-text>
          Upgrade your plan to access Forms, embed HTML and other features!
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="showUpgrade = false">
            Cancel
          </v-btn>
          <v-btn color="primary" text @click="manageSubscription">
            Upgrade Subscription
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end of upgrade modal -->

    <div class="previewbutton" v-if="$vuetify.breakpoint.mdOnly">
      <v-btn
        color="primary"
        elevation="2"
        v-if="!showPreview"
        @click="showPreview = !showPreview"
        ><v-icon>mdi-eye-outline</v-icon> Preview</v-btn
      >
    </div>

    <div v-if="$vuetify.breakpoint.smAndDown">
      <v-bottom-navigation class="bottomNav" v-model="selectedTab">
        <v-btn value="editor">
          <span>Editor</span>
          <v-icon>mdi-qrcode-edit</v-icon>
        </v-btn>
        <v-btn value="styling">
          <span>Styling</span>
          <v-icon>mdi-format-font</v-icon>
        </v-btn>
        <v-btn :value="selectedTab" @click="showPreview = !showPreview">
          <span>Preview</span>
          <v-icon>mdi-eye-outline</v-icon>
        </v-btn>
      </v-bottom-navigation>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import debounce from "lodash/debounce";
import { mapGetters } from "vuex";
import FontPicker from "font-picker-vue";
import { PrismEditor } from "vue-prism-editor";
import { v4 as uuidv4 } from "uuid";
import bugsnagClient from "../../utils/bugsnag";

import locales from "../../locales.json";
import icons from "../../icons.json";
import social from "../../social.json";

import Preview from "../../views/Preview";
import SubscriptionModal from "../../views/modals/SubscriptionModal";
import TemplateStylePicker from "../../views/modals/TemplateStylePicker.vue";

import ConfirmDialog from "../ConfirmDialog";
import FileSelector from "../FileSelector";
import FilePicker from "./FilePicker";

import DeleteDialog from "./DeleteDialog.vue";
import ColorPicker from "./ColorPicker.vue";
import LinkRules from "./LinkRules.vue";

import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";

const iconsList = Object.keys(icons).map((key) => {
  return {
    id: key,
    name: key.replace("-", " "),
    icon: icons[key],
    class: `ti ti-${key}`,
  };
});

export default {
  name: "BounceEditor",
  components: {
    draggable,
    FileSelector,
    Preview,
    ColorPicker,
    DeleteDialog,
    LinkRules,
    FontPicker,
    SubscriptionModal,
    PrismEditor,
    TemplateStylePicker,
    FilePicker,
  },
  props: [
    "template",
    "savingTemplate",
    "unsavedTemplate",
    "published",
    "publishing",
  ],
  data() {
    return {
      rules: {
        required: (value) => !!value || "Required.",
        protocol: (value) => {
          const pattern = new RegExp(
            "^(http://|https://|tel://|mailto://|ftp://)"
          );
          return (
            pattern.test(value) ||
            "URL must start with protocol (eg: http:// or https://)"
          );
        },
      },
      refreshKey: false,
      showUpgrade: false,
      showPreview: false,
      showComponents: false,
      selectedTab: "editor",
      isLoading: false,
      draftTemplate: this.template,
      link: null,
      deleteDialog: false,
      valid: true,
      tab: null,
      fonts: [],
      show_verified: false,
      title_font_size: "20px",
      body_font_size: "16px",
      button_font_size: "16px",
      alignments: [
        {
          label: "Left",
          val: "left",
        },
        {
        
          label: "Center",
          val: "center",
        },
        {
          label: "Right",
          val: "right",
        },
      ],
      titleFontSizes: [
        {
          label: "12px",
          val: "12",
        },
        {
          label: "14px",
          val: "14",
        },
        {
          label: "16px",
          val: "16",
        },
        {
          label: "18px",
          val: "18",
        },
        {
          label: "20px",
          val: "20",
        },
        {
          label: "22px",
          val: "22",
        },
        {
          label: "24px",
          val: "24",
        },
        {
          label: "26px",
          val: "26",
        },
      ],
      bodyFontSizes: [
        {
          label: "8px",
          val: "8",
        },
        {
          label: "10px",
          val: "10",
        },
        {
          label: "12px",
          val: "12",
        },
        {
          label: "14px",
          val: "14",
        },
        {
          label: "16px",
          val: "16",
        },
        {
          label: "18px",
          val: "18",
        },
        {
          label: "20px",
          val: "20",
        },
        {
          label: "22px",
          val: "22",
        },
        {
          label: "24px",
          val: "24",
        },
        {
          label: "26px",
          val: "26",
        },
      ],
      buttonFontSizes: [
        {
          label: "8px",
          val: "8",
        },
        {
          label: "10px",
          val: "10",
        },
        {
          label: "12px",
          val: "12",
        },
        {
          label: "14px",
          val: "14",
        },
        {
          label: "16px",
          val: "16",
        },
        {
          label: "18px",
          val: "18",
        },
        {
          label: "20px",
          val: "20",
        },
        {
          label: "22px",
          val: "22",
        },
        {
          label: "24px",
          val: "24",
        },
        {
          label: "26px",
          val: "26",
        },
      ],
      fontOptions: {
        sort: "popularity",
      },
      bounceApps: [
        //will need to switch out the parents for prod, don't wanna forget that
        {
          label: "Twitch (chat)",
          channel: "",
          embed:
            `<iframe src="https://www.twitch.tv/embed/` +
            this.channel +
            `/chat?parent=embed-dev.bounce.page&parent=d.bounce.page"
        height="400px">
      </iframe>`,
        },
        {
          //for livestreams and VODs,
          label: "Twitch (video)",
          //this is a link to either a channel, a video, or a collection
          link: "",
          embed:
            `<iframe src="https://player.twitch.tv/?` +
            this.link +
            `&parent=embed-dev.bounce.page&parent=d.bounce.page"`,
        },
        {
          label: "YouTube",
          link: "",
          embed:
            `<iframe width="100%" style="aspect-ratio: '4/3';" src="https://www.youtube.com/embed/` +
            this.link +
            `"></iframe>`,
        },
      ],
      locales: locales,
      icons: iconsList,
      social: social,
      activeTranslation: null,
      scrollPosition: 0,
      share: true,
      mediaTypes: [
        {
          label: "Profile Picture",
          code: "profile",
        },
        {
          label: "Video",
          code: "video",
        },
        {
          label: "Images",
          code: "images",
        },
        {
          label: "Youtube",
          code: "youtube",
        },
        // {
        //   label: "Augmented Reality",
        //   code: "ar",
        // },
        {
          label: "No media selected",
          code: "none",
        },
      ],
      formFieldTypes: [
        {
          label: "Text",
          code: "text",
        },
        {
          label: "Phone",
          code: "phone",
        },
        {
          label: "Email",
          code: "email",
        },
        {
          label: "Textarea",
          code: "textarea",
        },
        {
          label: "Checkbox",
          code: "checkbox",
        },
        // {
        //   label: 'Selector',
        //   code: 'select'
        // }
      ],
      components: [
        {
          style: "link",
          title: "",
          uri: "",
          cta: "Visit Link",
          button: "",
        },
        {
          style: "social",
          buttons: [
            {
              provider: "instagram",
              link: "",
            },
          ],
        },
        {
          style: "contact",
          title: "",
          contactData: {
            company: "",
            jobtitle: "",
            firstname: "",
            lastname: "",
            email: "",
            phone: "",
            address: "",
            website: "",
          },
        },
        {
          style: "text",
          title: "",
          content: "",
        },
        {
          style: "image",
          link: "",
        },
        {
          style: "results",
          title: "Table Title",
          subheader: "",
          rows: [
            {
              title: "Row title",
              detail: "Row content",
              validated: false,
            },
            {
              title: "Row title 2",
              detail: "Row content 2",
              validated: false,
            },
          ],
        },
        {
          style: "attachment",
          title: "",
          file: "",
          cta: "Download File",
        },
        {
          style: "embed",
          content: "",
          height: "400",
        },
        {
          style: "form",
          title: "",
          fields: [
            {
              title: "",
              fieldType: "text",
              placeholder: "",
              required: false,
            },
          ],
        },
        // {
        //   style: "bounceApp",
        //   content: "",
        // },
      ],
      componentDescriptions: {
        link: {
          icon: "mdi-link",
          name: "Button/Link",
          description: "A link to external content",
        },
        social: {
          icon: "mdi-comment-account",
          name: "Social Buttons",
          description: "A set of social buttons",
        },
        text: {
          icon: "mdi-format-text",
          name: "Text",
          description: "A freeform text box",
        },
        image: {
          icon: "mdi-image",
          name: "Image",
          description: "A responsive image",
        },
        contact: {
          icon: "mdi-contacts",
          name: "Contact Details",
          description: "Downloadable contact details",
        },
        results: {
          icon: "mdi-table-large",
          name: "Table",
          description: "A responsive multipurpose table",
        },
        attachment: {
          icon: "mdi-file-document",
          name: "Attachment",
          description: "A downloadable attachment",
        },
        embed: {
          icon: "mdi-code-braces-box",
          name: "Embed HTML",
          description: "Embed a HTML snippet",
          locked: true,
        },
        form: {
          icon: "mdi-form-textbox",
          name: "Form",
          description: "A user-completable form",
          locked: true,
        },
        bounceApp: {
          icon: "mdi-cogs",
          name: "Bounce App",
          description: "A pre-built embedded app",
          locked: true,
        },
      },
    };
  },
  watch: {
    draftTemplate: {
      handler: debounce(function (newTemplate, oldTemplate) {
        this.$emit("updatedTemplate", newTemplate);
        if (oldTemplate !== null) {
          //send updates to preview
          localStorage.setItem(
            "template",
            JSON.stringify({
              //must stringify, otherwise it bricks
              name: newTemplate.name,
              gtin: newTemplate.gtin,
              sku: newTemplate.sku,
              nucleusId: newTemplate.nucleusId,
              properties: newTemplate.properties,
              attributes: newTemplate.attributes,
              meta: newTemplate.meta,
            })
          );
        }
      }, 500),
      deep: true,
    },
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 5);
    window.addEventListener("scroll", this.handleDebouncedScroll);

    window.addEventListener("beforeunload", this.leave, false);

    localStorage.setItem(
      "template",
      JSON.stringify({
        name: this.draftTemplate.name,
        gtin: this.draftTemplate.gtin,
        sku: this.draftTemplate.sku,
        nucleusId: this.draftTemplate.nucleusId,
        properties: this.draftTemplate.properties,
        attributes: this.draftTemplate.attributes,
        meta: this.draftTemplate.meta,
      })
    );

    //capture ctrl+s
    document.addEventListener("keydown", (e) => {
      if ((e.ctrlKey || e.metaKey) && e.key === "s") {
        e.preventDefault();
        this.saveTemplate();
      }
    });

    this.getLink();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleDebouncedScroll);
    window.removeEventListener("beforeunload", this.leave);
  },
  computed: {
    ...mapGetters("account", {
      plan: "plan",
    }),
    scrollPositionCSS: function () {
      const scroll = this.scrollPosition <= 130 ? 0 : this.scrollPosition - 130;
      return `top: ${scroll}px`;
    },
    previewClasses: function () {
      if (this.showPreview && !this.$vuetify.breakpoint.lgAndUp) {
        return "preview-active";
      } else {
        return "d-none d-lg-flex";
      }
    },
    normalizedStyling: function () {
      //dirty refresh hack
      console.log("refreshKey: " + this.refreshKey);
      this.refreshKey;
      let styling = { ...this.draftTemplate.meta.styling };
      return Object.assign(
        {
          title_font: "Poppins",
          body_font: "Poppins",
          title_font_source: "google",
          body_font_source: "google",
          container_background_color: "#161616",
          main_background_color: "#161616",
          main_color: "#FCD535",
          main_border_radius: "16",
          gallery_background_color: "#6867AC",
          gallery_border_radius: "0",
          text_align: "center",
          title_font_size: "20",
          body_font_size: "16",
          button_font_size: "16",
          show_verified: false,
          secondary_color: "#272727",
          button_color: "#FCD535",
          button_text_color: "#161616",
          button_radius: "8",
          title_color: "#FFFFFF",
          sensitive: false,
          age_gate: false,
          age_gate_age: "18",
          background_media: "",
          share: true,
        },
        styling
      );
    },
    redirectionEnabled: function () {
      return this.draftTemplate.meta.redirectionEnabled || false;
    },
  },
  methods: {
    leave: function (e) {
      e.returnValue = "";
      window.alert("Unsaved changes will be lost.");
    },
    parseFiles(value) {
      if (!value || value === "") {
        return [];
      } else if (Array.isArray(value)) {
        return value;
      } else {
        return [value];
      }
    },
    manageSubscription() {
      this.isLoading = true;
      this.$http
        .get(`/subscription?upgrade=true`)
        .then((subRes) => {
          console.log(subRes);
          window.location.replace(subRes.data);
        })
        .catch((err) => {
          bugsnagClient.notify(err);
          this.$notify({
            type: "error",
            title: "Failed to open manage subscription.",
            text: "Please try again later.",
          });
        });
    },
    handleScroll(event) {
      this.scrollPosition = window.scrollY;
    },
    getLink() {
      if (this.template.meta && !this.template.meta.linkId) {
        return;
      }
      this.$http
        .get(`/links/${this.template.meta.linkId}`)
        .then(({ data: linkRes }) => {
          this.draftTemplate.meta.link = {
            uri: linkRes.uri,
            rules: linkRes.rules,
            batchId: linkRes.batchId,
          };
          this.$forceUpdate();
        })
        .catch((err) => {
          bugsnagClient.notify(err);
          this.$notify({
            type: "error",
            title: "Failed to retrieve link.",
            text: "Please try again later.",
          });
        });
    },
    addTranslation() {
      if (this.plan.tier == "free") {
        this.$modal.show(
          SubscriptionModal,
          {
            finishedCallback: () => {
              // refresh credits
            },
            copy: "You need to be a Pro or Teams member to add translations",
          },
          {
            adaptive: true,
            minHeight: 400,
            height: "auto",
            scrollable: false,
          }
        );
      } else {
        this.draftTemplate.properties.translations.push({
          details: [],
          language: "en",
          name: "New Page Title",
        });
      }
    },
    deleteTranslation(index) {
      console.log('delete'+index)
      this.$delete(this.draftTemplate.properties.translations, index);
      if (this.draftTemplate.properties.translations.length > 0) {
        this.setActiveTranslation(0);
      }
    },
    generateKey(detail, translationIdx, detailIdx) {
      if (!detail) {
        return;
      }
      if (detail.uid) return detail.uid;
      return "translation" + translationIdx + "-detail-" + detailIdx;
    },
    addComponent(details, style) {
      let newdetail = {
        uid: uuidv4(),
      };
      Object.assign(newdetail, style);
      details.unshift(newdetail);
      this.$forceUpdate();
      this.showComponents = false;
    },
    cloneDetail(detail) {
      // only copy enumerable props
      let newdetail = {
        uid: uuidv4(),
      };
      Object.assign(newdetail, detail);
      if (
        this.plan.tier == "free" &&
        (newdetail.style == "form" || newdetail.style == "embed")
      ) {
        this.showUpgrade = true;
      } else return newdetail;
    },
    deleteDetail(details, detailIdx) {
      details.splice(detailIdx, 1);
      this.deleteDialog = false;
      this.$forceUpdate();
    },
    moveDetailUp(details, detailIdx) {
      var detail = details[detailIdx];
      details.splice(detailIdx, 1);
      details.splice(detailIdx - 1, 0, detail);
      this.$forceUpdate();
    },
    moveDetailDown(details, detailIdx) {
      var detail = details[detailIdx];
      details.splice(detailIdx, 1);
      details.splice(detailIdx + 1, 0, detail);
      this.$forceUpdate();
    },
    removeFormField(idx, detail) {
      detail.fields.splice(idx, 1);
      this.$forceUpdate();
    },
    addFormField(detail) {
      if (!detail.fields) this.$set(detail, "fields", []);
      detail.fields.push({
        title: "",
        fieldType: "text",
        required: false,
      });
    },
    removeTableRow(idx, detail) {
      detail.rows.splice(idx, 1);
      this.$forceUpdate();
    },
    removeSocialButton(idx, detail) {
      detail.buttons.splice(idx, 1);
      this.$forceUpdate();
    },
    addTableRow(detail) {
      if (!detail.rows) this.$set(detail, "rows", []);
      detail.rows.push({
        title: "",
        detail: "",
        validated: false,
      });
    },
    addTableHeader(detail) {
      this.$set(detail, "header", ["", ""]);
    },
    activeMediaType(translation) {
      if (translation.video || translation.video === "") {
        return "video";
      }
      if (translation.ar || translation.ar === "") {
        return "ar";
      }
      if (translation.image || translation.image === "") {
        return "image";
      }
      if (translation.youtubeId || translation.youtubeId === "") {
        return "youtube";
      }
      if (translation.profile) {
        return "profile";
      }
      if (translation.images) {
        return "images";
      }
      return "none";
    },
    setActiveMediaType(translation, selected) {
      switch (selected) {
        case "video":
          this.$delete(translation, "images");
          this.$delete(translation, "ar");
          this.$delete(translation, "image");
          this.$delete(translation, "youtubeId");
          this.$delete(translation, "profile");
          this.$set(translation, "video", "");
          break;
        case "ar":
          this.$delete(translation, "video");
          this.$delete(translation, "images");
          this.$delete(translation, "image");
          this.$delete(translation, "youtubeId");
          this.$delete(translation, "profile");
          this.$set(translation, "ar", "");
          break;
        case "images":
          this.$delete(translation, "video");
          this.$delete(translation, "ar");
          this.$delete(translation, "image");
          this.$delete(translation, "youtubeId");
          this.$delete(translation, "profile");
          this.$set(translation, "images", []);
          break;
        case "image":
          this.$delete(translation, "video");
          this.$delete(translation, "ar");
          this.$delete(translation, "images");
          this.$delete(translation, "youtubeId");
          this.$delete(translation, "profile");
          this.$set(translation, "image", "");
          break;
        case "youtube":
          this.$delete(translation, "video");
          this.$delete(translation, "ar");
          this.$delete(translation, "images");
          this.$delete(translation, "image");
          this.$delete(translation, "profile");
          this.$set(translation, "youtubeId", "");
          break;
        case "profile":
          this.$delete(translation, "video");
          this.$delete(translation, "ar");
          this.$delete(translation, "images");
          this.$delete(translation, "image");
          this.$delete(translation, "youtubeId");
          this.$set(translation, "profile", {});
          break;
        case "none":
          this.$delete(translation, "video");
          this.$delete(translation, "images");
          this.$delete(translation, "image");
          this.$delete(translation, "ar");
          this.$delete(translation, "youtubeId");
          this.$delete(translation, "profile");
          break;
      }
    },
    updateStyling(el, value) {
      this.$set(this.draftTemplate.meta.styling, el, value);
    },
    updatedLink(newLink) {
      this.$set(this.draftTemplate.meta, "link", newLink);
    },
    enableRedirection(enabled) {
      this.$set(this.draftTemplate.meta, "redirectionEnabled", enabled);
    },
    updateFiles(root, key, value) {
      if (value === undefined) {
        return;
      }
      this.$set(root, key, value);
      this.$forceUpdate();
    },
    saveTemplate() {
      this.$emit("saveTemplate");
    },
    publish() {
      this.$emit("publish");
    },
    iconFilter(item, queryText) {
      const nameSearch = item.name.toLowerCase();
      const tagsSearch = item.icon.tags.join(" ").toLowerCase();
      const categorySearch = item.icon.category.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        nameSearch.indexOf(searchText) > -1 ||
        tagsSearch.indexOf(searchText) > -1 ||
        categorySearch.indexOf(searchText) > -1
      );
    },
    addSocialButton(detail) {
      if (!detail.buttons) this.$set(detail, "buttons", []);
      const buttons = [
        ...detail.buttons,
        {
          provider: "facebook",
          link: "",
        },
      ];
      this.$set(detail, "buttons", buttons);
      this.$forceUpdate();
    },
    classForProvider(button) {
      const icon = social.find((social) => social.id === button.provider).icon;
      return `ti ti-${icon}`;
    },
    highlighter(code) {
      return highlight(code, languages.js);
    },
    styleTemplateChosen(template) {
      const temp = Object.assign(
        this.draftTemplate.meta.styling,
        template.styling
      );
      this.$set(this.draftTemplate.meta, "styling", temp);
      this.refreshKey = !this.refreshKey;
      this.$forceUpdate();
    },
    settings(translation) {
      if (!translation.settings) {
        return {
          autoplay: true,
        };
      } else {
        return translation.settings;
      }
    },
    setSettings(translation, key, value) {
      if (!translation.settings) {
        this.$set(translation, "settings", {});
      }
      this.$set(translation.settings, key, value);
    },
    openPreview() {
      let routeData = this.$router.resolve({name: 'preview'});
      window.open(routeData.href, "Preview", "width=375,height=812");
    }
  },
};
</script>

<style lang="scss" scoped>
.draggable-item,
.move {
  cursor: move;
}

.list-group {
  padding-left: 0;
  margin: 0;
}

.draggable-items {
  width: 100%;
  background-color: #555555;
  border-radius: 8px;
  min-height: 300px;
}

.redirectText {
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 1rem;
  color: #ffffff;
  font-weight: 500;
}

.redirectSettings {
  display: flex;
  justify-content: right;
}

.ti {
  font-size: 1.5rem;
}

.social-button-list {
  padding-left: 0;
}

.social-button-list .v-list-item {
  padding: 0;
}

.my-editor {
  background: #2d2d2d;
  color: #ccc;

  font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
  font-size: 14px;
  line-height: 1.5;
  padding: 5px;
}

// optional
.prism-editor__textarea:focus {
  outline: none;
}

// not required:
.height-300 {
  height: 300px;
}

.previewbutton {
  position: fixed;
  bottom: 16px;
  right: 16px;
  text-align: center;
  z-index: 222;
}
.preview-active {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.component {
  border-bottom: 1px solid rgb(75, 75, 75);
}

.bottomNav {
  position: fixed;
  margin-left: 24px;
  z-index: 1;
}
.scrollBox {
  max-height: 500px;
  overflow-y: scroll;
}
.scrollBox::-webkit-scrollbar {
  width: 10px;
}
.scrollBox::-webkit-scrollbar-track {
  background: #2d2d2d;
}
.scrollBox::-webkit-scrollbar-thumb {
  background: #FCD535;
}
</style>
