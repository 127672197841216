<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-plus</v-icon> Add Domain
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        {{ title ? title : "Add Domain" }}
      </v-card-title>
      <v-card-text>
        Add a custom domain that people can use to visit your BouncePage. Add a CNAME to your DNS settings pointing to custom.bounce.page as the target domain. <a href='https://bounce.page/d/customdomains' target="_blank">Need help?</a>
      </v-card-text>
      <v-card-actions>
          <v-card-text class="pt-4">
        <v-text-field 
        v-model="name" 
        label="Domain" 
        hint="Enter the subdomain you want to use" 
        required
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
        v-if="this.plan.tier == 'starter_m' || this.plan.tier == 'starter_a'"
          color="primary"
          text
          @click="finished"
        >
          Add
        </v-btn>
        <v-btn v-else
        color="primary"
        text
        @click="finished">
          Add
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "addDomainModal",
  props: ["listItem", "title", "text", "plan"],
  data() {
    return {
      name: "",
      dialog: false,
    };
  },
  mounted() {
    console.log(this.plan.tier)
  },
  methods: {
    newURL(string) {
      let url;
      try {
        url = new URL(string);

        if (!url.hostname) {
          // cases where the hostname was not identified
          // ex: user:password@www.example.com, example.com:8000
          url = new URL("https://" + string);
        }
      } catch (error) {
        url = new URL("https://" + string);
      }
      return url.hostname;
    },

    finished() {
      this.name = this.newURL(this.name)
      this.$emit("added", this.name);
      this.dialog = false;
    },
    needToSub() {
      this.$emit("cta");
      this.dialog = false;
    }
  },
}
</script>
