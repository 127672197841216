var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container grid-lg"},[_c('modals-container'),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column col-12"},[_c('div',{staticClass:"card"},[(!_vm.loaded)?_c('div',{staticClass:"loading loading-lg"}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loaded),expression:"loaded"}],staticClass:"content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"col-12 button-top"},[_c('button',{staticClass:"button-tool",on:{"click":_vm.newTemplate}},[_c('plus-circle-icon',{staticClass:"icon"}),_vm._v(" New Template ")],1)])])]),_c('vue-good-table',{directives:[{name:"show",rawName:"v-show",value:(_vm.loaded),expression:"loaded"}],attrs:{"mode":"remote","theme":"nocturnal","columns":_vm.columns,"rows":_vm.templates,"totalRows":_vm.count,"search-options":{
            enabled: true,
            placeholder: 'Search Templates'
          },"pagination-options":{
            enabled: true
          },"sort-options":{
            enabled: true,
            initialSortBy: {field: 'updatedAt', type: 'desc'}
          },"isLoading":_vm.isLoading},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"on-row-click":_vm.onRowClick,"on-search":_vm.onSearch,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]}}])})],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }