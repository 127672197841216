var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container grid-lg"},[_c('modals-container'),_c('div',{staticClass:"columns"},[_vm._m(0),_c('div',{staticClass:"column col-12"},[_c('div',{staticClass:"card"},[(_vm.loaded)?_c('div',{staticClass:"content"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"col-12 button-top"},[_c('button',{staticClass:"button-tool",on:{"click":_vm.newLink}},[_c('plus-circle-icon',{staticClass:"icon"}),_vm._v(" New Dynamic Link")],1)])])]):_vm._e(),(!_vm.loaded)?_c('div',{staticClass:"loading loading-lg"}):_c('div',[_c('vue-good-table',{attrs:{"mode":"remote","columns":_vm.columns,"rows":_vm.links,"totalRows":_vm.count,"search-options":{
              enabled: true,
              placeholder: 'Search Links'
            },"sort-options":{
              enabled: true,
              initialSortBy: {field: 'updatedAt', type: 'desc'}
            },"pagination-options":{
              enabled: true
            },"isLoading":_vm.isLoading},on:{"on-page-change":_vm.onPageChange,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event},"on-row-click":_vm.openLink,"on-search":_vm.onSearch,"on-sort-change":_vm.onSortChange},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_vm._v(" No dynamic links found. ")])])],1)])])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"column col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"doc-panel"},[_c('div',{staticClass:"doc-item"},[_vm._v(" A dynamic link lets you redirect users to webpages based on a set of configured rules. ")]),_c('div',{staticClass:"doc-item",staticStyle:{"display":"none"}},[_c('button',{staticClass:"button-tool"},[_vm._v("View Documentation")])])])])])])
}]

export { render, staticRenderFns }