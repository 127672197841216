var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","disabled":_vm.disabled,"color":_vm.color}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.title)+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{attrs:{"loading":!_vm.link}},[_c('v-card-title',[_vm._v("View/Download QR Code "),_c('v-spacer'),_vm._v(" "),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[(_vm.link)?_c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('div',{attrs:{"id":"qrcontainer"},on:{"click":_vm.downloadQRPNG}},[_c('vue-qrcode',{attrs:{"value":_vm.slugString(_vm.showGS1),"options":{
                  width: this.width,
                  color: {
                    dark: this.style.dark,
                    light: this.style.light,
                  },
                  errorCorrectionLevel: 'Q',
                }}})],1)]),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"6"}},[_c('color-picker',{attrs:{"color":_vm.style.dark,"label":"QR Code Foreground Color","hint":"Select the foreground color of the QR Code"},on:{"colorChanged":(val) => _vm.updateStyle('dark', val)}}),_c('color-picker',{attrs:{"color":_vm.style.light,"label":"QR Code Background Color","hint":"Select the background color of the QR Code"},on:{"colorChanged":(val) => _vm.updateStyle('light', val)}}),(!_vm.$vuetify.breakpoint.smAndDown)?_c('file-pond',{ref:"qr",attrs:{"name":"file","accepted-file-types":"image/*","allowImageTransform":"","labelIdle":'Upload Center Logo',"credits":"false"},on:{"addfile":_vm.handleFileAdded,"removefile":_vm.handleFileRemoved}}):_vm._e()],1)],1)],1),(_vm.copiedURL)?_c('v-alert',{attrs:{"dense":"","text":"","type":"success"}},[_vm._v(" URL copied to clipboard! ")]):_vm._e(),(!_vm.copiedURL)?_c('v-text-field',{attrs:{"value":_vm.slugString(_vm.showGS1),"label":"Bounce Page URL","readonly":"","append-icon":"mdi-content-copy"},on:{"click:append":_vm.copyURL}}):_vm._e()],1):_vm._e()]),_c('v-card-actions',{staticClass:"justify-end"},[_c('span',{staticClass:"mr-2"},[_vm._v(" Download QR ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.downloadQRPNG}},[_vm._v("PNG ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.downloadQRJPG}},[_vm._v("JPG ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }