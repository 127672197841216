<template>
    <div>
        <div v-if="scans.length == 0">
            <div class="empty-analytics">
                <alert-octagon-icon class="empty-icon"></alert-octagon-icon>
                <p class="empty-title h5">No scans found.</p>
                <p class="empty-subtitle">Please check your filters and selected date range.</p>
            </div>
        </div>
        <div v-else>
        <mapbox 
            v-if="this.disabled"
            :access-token="accesstoken"
            @map-load="mapLoaded"
            @map-init="mapInitialized"
            :map-options="{
                style: style,
                center: center,
                zoom: 0,
            }"></mapbox>
        <mapbox 
            v-else
            :access-token="accesstoken"
            @map-load="mapLoaded"
            @map-init="mapInitialized"
            :map-options="{
                style: style,
                center: center,
                zoom: zoom
            }"
            :fullscreen-control="{
                show: true,
                position: 'top-right'
            }"></mapbox>
        </div>
    </div>
</template>

<script>
let _ = require('lodash');
import SubscriptionModal from '../../views/modals/SubscriptionModal';
import moment from 'moment';
import Mapbox from 'mapbox-gl-vue';
import { AlertOctagonIcon } from 'vue-feather-icons';
import sanitizeHtml from 'sanitize-html';

export default {
    name: 'AnalyticsMapView',
    components: {
        Mapbox,
        AlertOctagonIcon
    },
    props: {
        scans: {
            default: () => [],
            type: Array
        },
        disabled: Boolean
    },
    data() {
        return {
            accesstoken: 'pk.eyJ1IjoiY291bnRlcnNldCIsImEiOiJjanN6NmFzOTkwaWMwNGFwcHAzY2Yyc3V5In0.icY08mJk2adHleCCRfguDw',
            style: 'mapbox://styles/mapbox/dark-v9',
            center: [-103.59179687498357, 40.66995747013945],
            zoom: 3
        }
    },
    watch: {
        scans: {
            handler: 'reloadMapData'
        }
    },
    created() {
        document.getElementById('subModal').addEventListener('click', evt => 
       {
          this.popModal()
        });
    },
    methods: {
        popModal(){
            this.$modal.show(SubscriptionModal, {
                finishedCallback: () => {
                // refresh credits
                }
            }, {
                adaptive: true,
                minHeight: 400,
                height: 'auto',
                scrollable: false
            })
        },
        renderGeoData(){
            return {
                type: "FeatureCollection",
                features: _.map(this.scans, (scan) => {
                    let description = `<strong>Scan Event</strong><p style="margin-bottom: 0px">${moment(scan.createdAt).format('LLLL')}<br />`
                    if (scan.event){
                        if (scan.event.ip){
                            description += `IP: ${scan.event.ip}<br />`
                        }
                        if (scan.event.os){
                            description += `OS: ${scan.event.os}<br />`
                        }
                        if (scan.event.browserName){
                            description += `Browser: ${scan.event.browserName} ${scan.event.browserVersion}<br />`
                        }
                        if (scan.event.referrer){
                            description += `Referrer: ${scan.event.referrer}<br />`
                        }
                        //no longer need to see the SKU

                        // if (scan.sku){
                        //     description += `SKU: ${scan.sku}<br />`
                        // }
                        if (scan.batchRef){
                            description += `Batch Ref: ${scan.batchRef}`
                        }
                        if  (scan.uri){
                            description += `URI: <a target='_blank' href='${scan.uri}'>${scan.uri}</a><br />`
                        }
                        
                    }
                    description += "</p>"
                    description = sanitizeHtml(description, {disallowedTagsMode: 'escape'})

                    if(this.disabled)
                    {
                      description = `
                      <p>To access this feature, you need to upgrade to a BouncePage Team or Enterprise account!</p>
                      `
                    }

                    return {
                        type: "Feature",
                        properties: {
                            description: description
                            // upgrade: upgrade
                        },
                        geometry: {
                            type: "Point",
                            coordinates: scan.geog
                        }
                    }
                })
            }
        },
        mapInitialized (map) {
            this.map = map;
        },
        mapLoaded(map) {
            map.addSource("scans", {
                type: "geojson",
                data: this.renderGeoData(),
                cluster: true,
                clusterMaxZoom: 14, // Max zoom to cluster points on
                clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
            });
                
            map.addLayer({
                id: "clusters",
                type: "circle",
                source: "scans",
                filter: ["has", "point_count"],
                paint: {
                    "circle-color": [
                        "step",
                        ["get", "point_count"],
                        "#51bbd6",
                        100,
                        "#f1f075",
                        750,
                        "#f28cb1"
                    ],
                    "circle-radius": [
                        "step",
                        ["get", "point_count"],
                        20,
                        100,
                        30,
                        750,
                        40
                    ]
                }
            });
                
            map.addLayer({
                id: "cluster-count",
                type: "symbol",
                source: "scans",
                filter: ["has", "point_count"],
                layout: {
                    "text-field": "{point_count_abbreviated}",
                    "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
                    "text-size": 12
                }
            });
                
            map.addLayer({
                id: "unclustered-point",
                type: "circle",
                source: "scans",
                filter: ["!", ["has", "point_count"]],
                paint: {
                    "circle-color": "#11b4da",
                    "circle-radius": 6,
                    "circle-stroke-width": 1,
                    "circle-stroke-color": "#fff"
                }
            });

            // inspect a cluster on click
            map.on('click', 'clusters', function (e) {
                var features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] });
                var clusterId = features[0].properties.cluster_id;
                map.getSource('scans').getClusterExpansionZoom(clusterId, function (err, zoom) {
                    if (err) return;
            
                    map.flyTo({
                        center: features[0].geometry.coordinates,
                        zoom: zoom
                    });
                });
            });
            
            map.on('mouseenter', 'clusters', function () {
                map.getCanvas().style.cursor = 'pointer';
            });
            map.on('mouseleave', 'clusters', function () {
                map.getCanvas().style.cursor = '';
            });

            map.on('click', 'unclustered-point', function (e) {
                var coordinates = e.features[0].geometry.coordinates.slice();
                var description = e.features[0].properties.description;
                var upgrade = e.features[0].properties.upgrade;
                
                // Ensure that if the map is zoomed out such that multiple
                // copies of the feature are visible, the popup appears
                // over the copy being pointed to.
                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }
                const mapboxgl = require('mapbox-gl/dist/mapbox-gl')
                
                new mapboxgl.Popup()
                    .setLngLat(coordinates)
                    .setHTML(description)
                    .addTo(map);
            });
                
            map.on('mouseenter', 'unclustered-point', function () {
                map.getCanvas().style.cursor = 'pointer';
            });
                
            map.on('mouseleave', 'unclustered-point', function () {
                map.getCanvas().style.cursor = '';
            });
        },
        reloadMapData(){
            if (this.map && this.map.getSource('scans')) {
                this.map.getSource('scans').setData(this.renderGeoData())
            }
        }
    },

}
</script>

<style lang="scss">
    #map {
        width: 100%;
        height: 300px;
    }
    // media query for mobile
    @media (min-width: 767px) {
        .map {
            height: 500px;
        }
    }
    .empty-analytics {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 450px;
    }
    .empty-analytics p {
        font-size: 1.5rem;
        font-weight: bold;
        color: white !important;
    }
    .empty-icon {
        width: 48px;
        height: 48px;
        stroke: white;
    }
    .empty-title {
        margin-bottom: 6px;
        margin-top: 6px;
    }
    .card *{
        color: black !important;
    }
    .mapboxgl-popup-content {
        color: #444;
    }
</style>
