<template>
  <v-container>
    <modals-container> </modals-container>
    <v-row>
      <v-col>
        <v-toolbar elevation="1" dense>
          <v-toolbar-title>Analytics</v-toolbar-title>
        </v-toolbar>
        <v-banner
          v-if="plan && plan.tier == 'free'"
          single-line
          :sticky="sticky"
          color="primary"
          dense
        >
          <span class="black--text font-weight-medium">
            To see more detailed analytics, you'll need to upgrade to a Team
            plan.
          </span>
          <template v-slot:actions>
            <v-btn @click="manageSubscription" color="black"> Change Plan </v-btn>
          </template>
        </v-banner>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader
          v-if="!loaded"
          class="mx-auto"
          type="card-text, card-text, card-text"
        ></v-skeleton-loader>
        <v-card v-else shaped>
          <v-card-title>
            <analytics-search-bar
              :filter="searchFilter"
              :getAnalytics="getAnalytics"
            ></analytics-search-bar>
          </v-card-title>
          <analytics-map-view
            :disabled="plan && plan.tier == 'free'"
            :scans="scans"
          ></analytics-map-view>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card shaped>
          <v-card-title>
            Daily Scan Count
            <v-tooltip top v-if="plan && plan.tier == 'free'">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="primary"
                  @click="manageSubscription"
                  :loading="isLoading"
                  style="margin-left: 20px"
                >
                  <v-icon>mdi-lock-open-variant</v-icon>
                  Unlock Export
                </v-btn>
              </template>
              <span>
                Upgrade to a Team plan to unlock the ability to export analytics
                <br />
                as well as many other exciting features!
              </span>
            </v-tooltip>
            <div
              style="margin-left: 20px"
              v-else
            >
              <export-modal
                :data="this.scans"
                :exportType="'analytics'"
              ></export-modal>
            </div>
          </v-card-title>
          <v-card-text>
            <apexchart
              style="color: black"
              type="line"
              height="250"
              :options="scanChartOptions"
              :series="groupedScans"
            ></apexchart>
          </v-card-text>
          
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
let _ = require("lodash");
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import VueApexCharts from "vue-apexcharts";

import AnalyticsSearchBar from "../components/analytics/AnalyticsSearchBar";
import AnalyticsMapView from "../components/analytics/AnalyticsMapView";

import ExportModal from "./modals/ExportModal";
import bugsnagClient from "../utils/bugsnag";

export default {
  name: "Analytics",
  components: {
    AnalyticsSearchBar,
    AnalyticsMapView,
    ExportModal,
    apexchart: VueApexCharts,
  },
  data() {
    return {
      scanChartOptions: {
        chart: {
          height: 250,
          type: "line",
          shadow: {
            enabled: true,
            color: "#FFFFFF",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1,
          },
          toolbar: {
            show: true,
          },
        },
        colors: ["#FFFFFF", "#FFFFFF"],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: "straight",
        },
        grid: {
          borderColor: "#FFFFFF",
          row: {
            colors: ["#484848", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
        },
        markers: {
          size: 6,
        },
        xaxis: {
          type: "datetime",
        },
        yaxis: {
          title: {
            text: "Daily Scans",
            color: "#FFFFFF",
          },
          min: 0,
        },
        title: {
          color: "#FFFFFF",
        },
        legend: {
          position: "top",
          horizontalAlign: "right",
          floating: true,
          offsetY: -5,
          offsetX: -5,
        },
      },
      scans: [],
      groupedScans: [],
      loaded: false,
      searchFilter: null,
    };
  },
  mounted() {
    let pageId = this.$route.query.pageId;
    if (pageId) {
      this.filters = [
        {
          filter: "batch",
          val: pageId,
        },
      ];
      this.getAnalytics({
        filters: this.filters,
      });
    } else {
      this.getAnalytics();
    }
  },
  computed: {
    ...mapGetters("account", {
      plan: "plan",
    }),
  },
  methods: {
    manageSubscription() {
      this.isLoading = true;
      this.$http
        .get(`/subscription?upgrade=true`)
        .then((subRes) => {
          console.log(subRes);
          window.location.replace(subRes.data);
        })
        .catch((err) => {
          bugsnagClient.notify(err);
          this.$notify({
            type: "error",
            title: "Failed to open manage subscription.",
            text: "Please try again later.",
          });
        });
    },
    ...mapActions("notifications", ["populateNotifications"]),
    getAnalytics(params) {
      this.loaded = false;
      this.searchFilter = params;
      this.$http
        .post("/scans", {
          ...params,
        })
        .then((res) => {
          this.scans = _.filter(res.data.scans, function (scan) {
            if (_.isNil(scan.geog)) return false;
            return true;
          });
          let groupedByDate = _.groupBy(res.data.scans, function (scan) {
            return moment(scan.createdAt).startOf("day").format();
          });
          this.groupedScans = [
            {
              data: _.map(groupedByDate, function (scans, dateKey) {
                return {
                  x: dateKey,
                  y: scans.length,
                };
              }),
            },
          ];
          this.loaded = true;
        })
        .catch((err) => {
          this.loaded = true;
          this.scans = [];
          this.groupedScans = [
            {
              data: [],
            },
          ];
          bugsnagClient.notify(err);
          this.$notify({
            type: "error",
            title: "Failed to retrieve analytics.",
            text: "Please try again later.",
          });
        });
    },
    exportAnalytics() {
      this.$modal.show(
        ExportModal,
        {
          data: this.scans,
          exportType: "analytics",
          finishedCallback: () => {},
        },
        {
          adaptive: true,
          minHeight: 200,
          scrollable: false,
        }
      );
    },
    openScanDetail(id) {
      console.log("scan detail opened");
    },
  },
};
</script>
<style lang="scss" scoped>
#map-holder {
  height: 500px;
  position: relative;
}

.search {
  overflow: visible;
}

.filter {
  display: flex;
  flex-direction: row;
  width: 100%;
}

#filterSelect {
  min-width: 200px;
  padding-right: 16px;
}

.actions {
  flex-grow: 1;
  display: inline-flex;
  justify-content: flex-end;
}

.searchHolder {
  display: flex;
  flex-grow: 1;
  height: 100%;
  align-items: flex-end;
  display: block;
  padding-top: 24px;
}
</style>
