<template>
    <v-dialog
      v-model="dialog"
      max-width="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          v-bind="attrs"
          v-on="on"
          v-if="!listItem"
        >
          <v-icon>mdi-application-import</v-icon>
        </v-btn>
        <v-list-item
          link
          v-bind="attrs"
          v-on="on"
          v-if="listItem"
        >
        <v-list-item-title>Domain Selector</v-list-item-title>
        </v-list-item>
      </template>
      <v-card>
        <v-card-title>
          {{ title ? title : "Domain Selector" }}
        </v-card-title>
        <v-treeview
        selected-color="primary"
        hoverable
        :items="domains"
        >
        <template slot="append" slot-scope="{ item }">
          <div class="d-flex align-center justify-space-between">
            <v-btn>Go to {{item.name}}</v-btn>
        </div>
        </template>
      </v-treeview>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="dialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="finished"
          >
            Select
          </v-btn>
      </v-card>
    </v-dialog>
  </template>
  <script>
  import { mapActions, mapGetters } from 'vuex';

  export default {
    name: "domainSelector",
    props: ["listItem", "title", "text"],
    data() {
      return {
        domain: "",
        dialog: false,
      };
    },
    computed: {
        ...mapGetters('account', {
            domains: 'domains'
        })
    },
    mounted() {
      return this.loadDomains()
    },
    methods: {
      ...mapActions('account', [
            'loadDomains'
        ]),
      finished() {
        this.$emit("addedDomain", this.domain);
        this.dialog = false;
      },
    },
  }
  </script>
  