<template>
    <v-dialog v-model="dialog" max-width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-list-item link v-bind="attrs" v-on="on">
          <v-list-item-title>Custom domains</v-list-item-title>
        </v-list-item>
      </template>
      <v-card class="d-flex flex-column" :loading="loading">
        <v-toolbar dark>
          <v-btn icon dark @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Custom domains</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4">

          <v-banner v-if="!isPaid" sticky color="primary" dense>
            <span class="black--text font-weight-medium">
              To enable custom domains, you'll need to upgrade to the
              Plus or Team plan.
            </span>
            <template v-slot:actions>
              <subscription-modal></subscription-modal>
            </template>
          </v-banner>
  
          <v-select
            clearable
            label="Select domain to link"
            :items="domains"
            variant="outline"
            item-text="name"
            item-value="id"
            persistent-hint
            v-model="internalSelectedDomain"
            ></v-select>

        <v-banner :key="internalSelectedDomain" v-if="isLinked(internalSelectedDomain)" dense>
            <span class="font-weight-medium">
            This custom domain is already linked to another Bounce Page.
            </span> 
        </v-banner>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                @click="update"
                :disabled="isLinked(internalSelectedDomain)"
            > Save link </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>
  </template>
  <script>
  import SubscriptionModal from "../../views/modals/SubscriptionModal";
  
  export default {
    name: "DomainModal",
    components: {
      SubscriptionModal
    },
    props: {
      isPaid: {
        type: Boolean,
        default: false
      },
      domains: {
        type: Array,
        default: () => []
      },
      domainId: {
        type: String
      }
    },
    data() {
      return {
        internalSelectedDomain: this.domainId,
        dialog: false,
        loading: false,
      };
    },
    mounted() {
        console.log(this.domains)
    },
    methods: {
      update() {
        this.$emit("finished", {
          domainId: this.internalSelectedDomain
        });
        this.dialog = false;
      },
      isLinked() {
        const domain = this.domains.find(d => d.id === this.internalSelectedDomain)
        return domain && domain.links && domain.links.length
      },
    },
  }
  </script>
  