<template>
  <div
    style="
      -webkit-transform: scale(0.65);
      transform: scale(0.65);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      border: 0;
      top: 0;
    "
  >
    <div class="smartphone">
      <div class="content">
      <div class="redirect justify-center align-center d-flex flex-column" v-if="redirectionEnabled">
        <v-icon
          x-large
          style="font-size:8rem"
        >mdi-arrow-right-box</v-icon>
        <span class="text-h3 text-center">Redirection enabled</span>
      </div>
        <iframe
          v-else
          style="
            width: 100% !important;
            height: 100% !important;
            border: none;
            pointer-events: none;
          "
          id="template"
        ></iframe>
        <!-- width=375,height=812 -->
      </div>
    </div>
  </div>
</template>
// 

<script>
import bugsnagClient from "../utils/bugsnag";

export default {
  name: "Preview",
  data() {
    return {
      templateObj: "",
      interval: null,
    };
  },
  props: ["redirectionEnabled", "link"],
  mounted() {
    this.preview();
    this.interval = setInterval(
      function () {
        if (typeof Storage !== "undefined") {
          if (this.templateObj != localStorage.getItem("template")) {
            this.templateObj = localStorage.getItem("template");
            this.renderTemplate();
          }
        } else {
          document.getElementById("result").innerHTML =
            "Sorry, your browser does not support Web Storage...";
        }
      }.bind(this),
      1000
    );
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    renderTemplate() {
      console.log("rendering");
      if (this.redirectionEnabled) return;
      const data = localStorage.getItem("template");
      this.$http
        .post(`/preview`, data)
        .then((res) => {
          document.getElementById("template").srcdoc = res.data;
        })
        .catch((err) => {
          bugsnagClient.notify(err);
          let res = err.response.data;
          console.log(res.split(/:(.+)/)[1]);
          this.$notify({
            type: "error",
            title: "Failed to get preview HTML",
            text:
              "Please check the payload for the following issue(s):" +
              res
                .split(/:(.+)/)[1]
                .replaceAll(
                  " Must validate one and only one schema (oneOf),",
                  ""
                ),
            duration: 10000,
          });
        });
    },
    preview() {
      if (this.templateObj == "") {
        this.templateObj = localStorage.getItem("template");
        this.renderTemplate();
      }
    },
  },
};
</script>
<style>
html,
body {
  margin: 0px;
  padding: 0px;
}
#full {
  background: #0f0;
}
#bar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  min-height: 42px;
  padding: 8px;
  height: fit-content;
  /* background: white; */
  text-align: center;
}

/* phone css */
/* The device with borders */
.smartphone {
  position: relative;

  width: 390px;
  margin: auto;
  border: 16px #5f5f5f solid;
  border-top-width: 60px;
  border-bottom-width: 60px;
  border-radius: 36px;
}

/* The horizontal line on the top of the device */
.smartphone:before {
  content: "";
  display: block;
  width: 60px;
  height: 5px;
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 10px;
}

/* The circle on the bottom of the device */
.smartphone:after {
  content: "";
  display: block;
  width: 35px;
  height: 35px;
  position: absolute;
  left: 50%;
  bottom: -65px;
  transform: translate(-50%, -50%);
  background: #333;
  border-radius: 50%;
}

/* The screen (or content) of the device */
.smartphone .content {
  width: 360px;
  height: 640px;
  background: black;
}

.redirect {
  height: 100%;
}
</style>