<template>
  <v-container class="p-0 m-0">
    <v-row>
      <v-col cols="12" sm="12" md="6">
        <v-autocomplete
          v-model="selectedTemplate"
          :items="templates"
          :loading="searching"
          :search-input.sync="searchTemplate"
          color="white"
          hide-no-data
          hide-selected
          clearable
          item-text="name"
          item-value="id"
          label="Select Bounce Page"
          placeholder="Start typing to Search"
          prepend-icon="mdi-database-search"
          return-object
        ></v-autocomplete>
      </v-col>
      <v-col cols="8" sm="8" md="4">
        <date-range-picker
          ref="picker"
          opens="center"
          :locale-data="{ firstDay: 1, format: 'YYYY-MM-DDTHH:mm:ss.sssZ' }"
          :maxDate="maxDate"
          showWeekNumbers
          showDropdowns
          autoApply
          closeOnEsc
          v-model="scanRange"
          linkedCalendars
        >
          <template v-slot:input="picker">
            <v-text-field
              :value="`${ picker.startDate.toLocaleDateString(local, { year: 'numeric', month: 'long', day: 'numeric'}) } - ${ picker.endDate.toLocaleDateString(locale, { year: 'numeric', month: 'long', day: 'numeric'}) }`"
              label="Date Range"
              readonly
              prepend-icon="mdi-calendar-range"
            />
          </template>
        </date-range-picker>
      </v-col>
      <v-col cols="4" sm="4" md="2" class="searchHolder">
        <v-btn @click="search" color="primary">Search</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
const _ = require("lodash");
import Vue from "vue";
import moment from "moment";
import { PlusCircleIcon, Trash2Icon } from "vue-feather-icons";
import DateRangePicker from 'vue2-daterange-picker'
import bugsnagClient from "../../utils/bugsnag";

export default {
  name: "AnalyticsSearchBar",
  components: {
    PlusCircleIcon,
    Trash2Icon,
    DateRangePicker
  },
  props: {
    getAnalytics: {
      default: (params) => {},
      type: Function,
    },
    filter: {
      default: () => {
        return {
          fromDate: moment().subtract(1, "months").toDate(),
          toDate: moment().add(1, "days").toDate(),
          filters: [{
            filter: "any",
            val: "",
          }],
        }
      },
      type: Array,
    },
  },
  data() {
    return {
      templates: [],
      searching: false,
      selectedTemplate: null,
      searchTemplate: null,
      scanRange: {
        startDate: this.filter.fromDate,
        endDate: this.filter.toDate,
      },
      maxDate: moment().add(1, 'days').toISOString()
    };
  },
  watch: {
    searchTemplate (val) {
      if (this.templates.length > 0) return
      if (this.searching) return
      this.searching = true

      Vue.axios.get("/templates", {
        params: {
          page: 1,
          limit: 10,
          search: val,
          sort: {
            field: "",
            type: "",
          },
        },
      }).then((res) => {
        this.templates = res.data.templates;
        this.count = res.data.count;
      }).finally(() => {
        this.searching = false;
      });
    },
  },
  methods: {
    search() {
      console.log(this.scanRange)
      const publishedBatchId = _.get(this.selectedTemplate, "meta.publishedBatchId", null);
      this.getAnalytics({
        // selectedPage: this.selectedTemplate,
        fromDate: this.scanRange.startDate,
        toDate: this.scanRange.endDate,
        filters: publishedBatchId ? [{
          filter: "batch",
          val: publishedBatchId,
        }] : [{
          filter: "any",
          val: "",
        }],
      }).catch((err) => {
        bugsnagClient.notify(err);
        this.$notify({
          type: "error",
          title: "Failed to retrieve analytics for time period.",
          text: "Please try again later.",
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.search {
  overflow: visible;
}

.filter {
  display: flex;
  flex-direction: row;
  width: 100%;
}

#filterSelect {
  min-width: 200px;
  // padding-right: 16px;
  background-color: white;
  border-radius: 4px;
  margin-right: 16px;
  margin-bottom: 6px;
}

.actions {
  flex-grow: 1;
  display: inline-flex;
  justify-content: flex-end;
}

.searchHolder {
  display: flex;
  flex-grow: 1;
  height: 100%;
  align-items: flex-end;
  margin-top: 1.4rem;
}
</style>
