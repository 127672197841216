import Vue from 'vue'
import Router from 'vue-router'

import Signup from './views/Signup.vue';
import Login from './views/Login.vue';
import PasswordReset from './views/PasswordReset.vue';
import Dashboard from './views/Dashboard.vue';
import Forgot from './views/Forgot.vue';

import Home from './views/Home.vue';
// import Batches from './views/Batches.vue';
// import Batch from './views/Batch.vue';
import Templates from './views/Templates.vue';
import Analytics from './views/Analytics.vue';
// import Files from './views/Files.vue';
// import Forms from './views/Forms.vue'
import Links from './views/Links.vue';
import Link from './views/Link.vue';
import Settings from './views/Settings.vue';
import Admin from './views/Admin.vue';
import AdminOrgs from './views/AdminOrgs.vue';
import FullPreview from './views/FullPreview.vue';

import TemplateEditor from './views/TemplateEditor';
import Editor from './views/Editor.vue';

import NotFound from './views/404.vue';

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard/editor'
    },
    { 
      name: 'login',
      path: '/login', 
      component: Login, 
      meta: { }
    },
    {
      name: 'forgot',
      path: '/forgot',
      component: Forgot,
      meta: { }
    },
    {
      name: 'signup',
      path: '/signup',
      component: Signup,
      meta: { }
    },
    {
      name: 'reset',
      path: '/password',
      component: PasswordReset,
      meta: {}
    },
    {
        path: '/dashboard',
        component: Dashboard,
        meta: { auth: true },
        children: [
          {
            path: 'editor',
            name: 'editor_list',
            component: Editor
          },
          {
            path: 'editor/:id',
            name: 'editor',
            component: Editor
          },
          {
            path: 'analytics',
            name: 'analytics',
            component: Analytics
          },
          // {
          //   path: 'files',
          //   name: 'files',
          //   component: Files
          // },
          // {
          //   path: 'forms',
          //   name: 'forms',
          //   component: Forms
          // },
          {
            path: 'settings',
            name: 'settings',
            component: Settings
          },
          {
            path: 'admin/organizations',
            name: 'admin',
            component: Admin
          },
          {
            path: 'admin/organizations/:id',
            name: 'adminOrgs',
            component: AdminOrgs
          }
        ]
    },
    {
      path: '/404',
      name: '404',
      component: NotFound
    },
    {
      path: '/preview',
      name: 'preview',
      component: FullPreview
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
})
