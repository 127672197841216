<template>
    <v-container>
        <modals-container>
        </modals-container>
        <v-row>
            <v-col>
                <v-toolbar elevation="1" dense>
                    <v-toolbar-title>Settings</v-toolbar-title>
                </v-toolbar>
            </v-col>
        </v-row>
        <v-row>

            <v-col>
                <v-skeleton-loader v-if="!plan" class="mx-auto" type="card-heading, card-text, card-text, card-text">
                </v-skeleton-loader>
                <v-card v-else shaped>
                    <v-card-text>
                        <div>Plan</div>
                        <p class="text-h4 text--primary">
                            {{ plan.tier | planTier }}
                        </p>
                        <div class="text--primary">
                            You are subscribed to the {{ plan.tier | planTier }} plan.<br />
                            {{ plan.tier | planDescription }}
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text color="primary" @click="manageSubscription">
                            Manage Subscription
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
            <v-col>
                <v-skeleton-loader v-if="!identity" class="mx-auto"
                    type="card-heading, card-text, card-text, card-text"></v-skeleton-loader>
                <v-card v-else shaped>
                    <v-card-text>
                        <div>Account Details</div>
                        <p>Username: {{ identity.username }}</p>
                        <div class="text--primary">
                            Please quote your username when contacting us.
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <change-password-modal></change-password-modal>
                        <!-- <v-btn
                            text
                            color="primary"
                            @click="changePassword"
                        >
                            Change Password
                        </v-btn> -->
                        <v-btn text color="yellow" @click="contactSupport">
                            Contact Support
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-skeleton-loader v-if="!organization" class="mx-auto" type="card-text, card-text"></v-skeleton-loader>
                <v-card v-else shaped :loading="savingOrganization">
                    <v-card-title>
                        Organization Details
                    </v-card-title>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-text-field v-model="displayName" label="Display Name" required></v-text-field>

                            <v-text-field v-model="address.street_address" label="Street Address"></v-text-field>

                            <v-text-field v-model="address.city" label="City"></v-text-field>

                            <v-text-field v-model="address.state" label="State"></v-text-field>

                            <v-text-field v-model="address.country" label="Country"></v-text-field>

                            <v-btn text color="primary" @click="saveOrganization" :disabled="!valid">
                                Save
                            </v-btn>

                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-row>
            <v-col>
                <v-skeleton-loader v-if="!apikeys" class="mx-auto" type="card-text, card-text, card-text">
                </v-skeleton-loader>
                <v-card v-else-if="apikeys.length == 0" shaped>
                    <div class="apiTitle">
                        <v-card-title>
                            API Keys
                        </v-card-title>
                    </div>
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                            hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="apikeysColumns" :items="apikeys" :items-per-page="5" :search="search"
                        class="elevation-1"></v-data-table>
                </v-card>
            <v-col>
                <v-skeleton-loader v-if="!domains" class="mx-auto" type="card-text, card-text, card-text">
                </v-skeleton-loader>
                <v-card shaped >
                    <div class="apiTitle">
                        <v-card-title>
                            Custom Domains <a class="ml-6" target="_blank" href="https://bounce.page/d/customdomains">View Setup FAQ</a>
                        </v-card-title>
                        <add-domain-modal @added="addDomain" @cta="showUpgrade = true" :plan="plan"/>
                    </div>
                    <!-- subscription modal -->
                    <v-dialog v-model="showUpgrade" max-width="450">
                        <v-card>
                            <v-card-title class="text-h5"> Upgrade Plan </v-card-title>
                            <v-card-text>
                            Upgrade your plan to access custom domains, embed HTML and other features!
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="secondary" text @click="showUpgrade = false">
                                Cancel
                            </v-btn>
                            <v-btn color="primary" text @click="manageSubscription">
                                Upgrade Subscription
                            </v-btn>
                            </v-card-actions>
                        </v-card>
                        </v-dialog>
                    <!-- end modal -->
                    <v-card-title>
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line
                            hide-details></v-text-field>
                    </v-card-title>
                    <v-data-table :headers="domainColumns" :items="domains" :items-per-page="5" :search="search"
                        class="elevation-1">
                        <template v-slot:item.actions="{ item }">
                            <v-icon
                                small
                                @click="deleteDomain(item.id)"
                            >
                                mdi-delete
                            </v-icon>
                            </template>
                    </v-data-table>
                </v-card>
            </v-col>
            </v-col>
        </v-row>
        </v-row>
    </v-container>
</template>
<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { PlusCircleIcon } from 'vue-feather-icons';

import bugsnagClient from '../utils/bugsnag';

import PaymentMethod from '../components/PaymentMethod';
import SubscriptionModal from './modals/SubscriptionModal';
import ChangePasswordModal from './modals/ChangePasswordModal';
import AddDomainModal from '../components/AddDomainModal.vue';


export default {
    name: 'Settings',
    components: {
        PlusCircleIcon, PaymentMethod,
        ChangePasswordModal,
        AddDomainModal
    },
    filters: {
        planTier: function (tier) {
            if (!tier) return ''
            switch (tier) {
                case 'free':
                    return 'Free';
                case 'starter_m':
                    return 'Premium (Monthly)'
                case 'plus_m':
                    return 'Plus (Monthly)'
                case 'starter_a':
                    return 'Premium (Annual)'
                case 'plus_a':
                    return 'Plus (Annual)'
                case 'bouncepage_tier1':
                    return 'AppSumo (Tier 1)'
                case 'bouncepage_tier2':
                    return 'AppSumo (Tier 2)'
                default:
                    return '';
            }
        },
        planDescription: function (tier) {
            if (!tier) return ''
            switch (tier) {
                case 'free':
                    return 'The Free plan includes one Bounce Page.';
                case 'starter_m':
                    return 'The Premium plan includes unlimited Bounce Pages.'
                case 'plus_m':
                    return 'The Plus plan includes 5 Bounce Pages.'
                case 'starter_a':
                    return 'The Premium plan includes unlimited Bounce Pages.'
                case 'plus_a':
                    return 'The Plus plan includes 5 Bounce Pages.'
                case 'bouncepage_tier1':
                    return 'The Tier 1 plan includes 5 Bounce Pages.'
                case 'bouncepage_tier2':
                    return 'The Tier 2 plan includes unlimited Bounce Pages.'
                default:
                    return '';
            }
        }
    },
    data() {
        return {
            showUpgrade: false,
            search: "",
            valid: true,
            templateDialog: true,
            savingOrganization: false,
            displayName: '',
            brands: [],
            address: {
                street_address: '',
                city: '',
                state: '',
                country: ''
            },
            apikeysColumns: [
                {
                    text: 'Name',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: 'API Key', value: 'key' },
                { text: 'Expires at', value: 'expiresAt' },
            ],
            domainColumns: [ 
                {
                text: 'Hostname',
                align: 'start',
                sortable: false,
                value: 'name',
                },
                { text: 'Status', value: 'status' },
                { text: 'Actions', value: 'actions', sortable: false },
            ]
        };
    },
    computed: {
        ...mapGetters('account', {
            identity: 'identity',
            organization: 'organization',
            plan: 'plan',
            apikeys: 'apikeys',
            domains: 'domains'
        })
    },
    methods: {
        ...mapActions('account', [
            'loadIdentity',
            'loadOrganization',
            'loadPlan',
            'loadAPIKeys',
            'loadDomains'
        ]),
        addDomain(name) {
            this.$http.post(`/domains`,{
                name: name
            }).then(() => {this.loadDomains()}).catch((err) => {
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to update Domain.',
                    text: 'Please try again later.'
                })
            })
        },
        changeSubscription() {
            this.$modal.show(SubscriptionModal, {
                finishedCallback: () => {
                    // refresh credits
                }
            }, {
                adaptive: true,
                minHeight: 400,
                height: 'auto',
                scrollable: false
            })
        },
        manageSubscription() {
            
            this.$http.get(`/subscription`).then((subRes) => {
                window.open(subRes.data);
            }).catch((err) => {
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to open manage subscription.',
                    text: 'Please try again later.'
                })
            })
        },
        changePassword() {
            this.$modal.show(ChangePasswordModal, {
            }, {
                adaptive: true,
                minHeight: 400,
                scrollable: false,
                height: 'auto',
            })
        },
        
        saveOrganization() {
            this.savingOrganization = true;
            this.$http.put('/organization', {
                displayName: this.displayName,
                properties: {
                    address: this.address,
                    brands: this.brands
                }
            }).then((res) => {
                this.savingOrganization = false;
            }).catch((err) => {
                this.savingOrganization = false;
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to update organization.',
                    text: 'Please try again later.'
                })
            })
        },
        amountFormatter(amount) {
            let a = amount / 100;
            return `$${a}`;
        },
        newDomain() {
            this.$modal.show(AddDomainModal,{
            }, {
                adaptive: true,
                minHeight: 400,
                scrollable: false,
                height: 'auto'
            })
        },
        deleteDomain(id) {
            this.$http.delete("/domains/"+id).then(() => {this.loadDomains()}).catch((err) => {
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'Error',
                    title: 'Failed to delete domain of id '+id,
                    text: 'Please try again later'
                })
            })
        },
        contactSupport() {
            window.open('https://bounce.page/company/contact.html', '_blank');
        },
        callToAction() {

        }
    },
    created() {
        this.loadIdentity()
            .then(this.loadPlan())
            .then(() => {
                return this.loadOrganization().then(() => {
                    this.displayName = this.organization.displayName;
                    if (this.organization.properties.brands && this.organization.properties.brands.length) {
                        this.brands = this.organization.properties.brands;
                    }
                    this.address = Vue.util.extend(this.address, this.organization.properties.address);
                })
            })
            .then(this.loadAPIKeys())
            .then(this.loadDomains())
            .catch((err) => {
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to load details for your organization.',
                    text: 'Please try again later.'
                })
            })
    }
}
</script>
<style lang="scss" scoped>
.small-label {
    font-size: 12px;
}

.apiTitle{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
</style>
