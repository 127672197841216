<template>
  <v-text-field v-model="colorVal" :hint="hint" :label="label">
    <template v-slot:append>
      <v-menu
        v-model="menu"
        top
        nudge-bottom="105"
        nudge-left="16"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle" v-on="on" />
        </template>
        <v-card>
          <v-card-text class="pa-0">
            <v-color-picker v-model="colorVal" flat />
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>
<script>
import debounce from "lodash/debounce";

export default {
  name: "ColorPicker",
  props: ["label", "hint", "color"],
  computed: {
    swatchStyle() {
      const { colorVal, menu } = this;
      return {
        backgroundColor: colorVal,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },
  },
  watch: {
    colorVal: {
      handler: debounce(function(newColorVal, oldColorVal) {
        this.$emit("colorChanged", newColorVal);
      }, 200),
    },
  },
  data() {
    return {
      menu: false,
      colorVal: this.color,
    };
  },
};
</script>