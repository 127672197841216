<template>
    <iframe
    style="
        width: 100% !important;
        height: 100% !important;
        border: none;
    "
    id="template"
    ></iframe>
  </template>  
  <script>
  import bugsnagClient from "../utils/bugsnag";
  
  export default {
    name: "FullPreview",
    data() {
      return {
        templateObj: "",
        interval: null,
      };
    },
    props: ["redirectionEnabled", "link"],
    mounted() {
        window.$chatwoot.toggleBubbleVisibility("hide");
      this.preview();
      this.interval = setInterval(
        function () {
          if (typeof Storage !== "undefined") {
            if (this.templateObj != localStorage.getItem("template")) {
              this.templateObj = localStorage.getItem("template");
              this.renderTemplate();
            }
          } else {
            document.getElementById("result").innerHTML =
              "Sorry, your browser does not support Web Storage...";
          }
        }.bind(this),
        1000
      );
    },
    destroyed() {
      clearInterval(this.interval);
    },
    methods: {
      renderTemplate() {
        console.log("rendering");
        if (this.redirectionEnabled) return;
        const data = localStorage.getItem("template");
        this.$http
          .post(`/preview`, data)
          .then((res) => {
            document.getElementById("template").srcdoc = res.data;
          })
          .catch((err) => {
            bugsnagClient.notify(err);
            let res = err.response.data;
            console.log(res.split(/:(.+)/)[1]);
            this.$notify({
              type: "error",
              title: "Failed to get preview HTML",
              text:
                "Please check the payload for the following issue(s):" +
                res
                  .split(/:(.+)/)[1]
                  .replaceAll(
                    " Must validate one and only one schema (oneOf),",
                    ""
                  ),
              duration: 10000,
            });
          });
      },
      preview() {
        if (this.templateObj == "") {
          this.templateObj = localStorage.getItem("template");
          this.renderTemplate();
        }
      },
    },
  };
  </script>
  <style>
  html,
  body {
    margin: 0px;
    padding: 0px;
  }
  #full {
    background: #0f0;
  }
  #bar {
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    min-height: 42px;
    padding: 8px;
    height: fit-content;
    /* background: white; */
    text-align: center;
  }
  .redirect {
    height: 100%;
  }
  </style>