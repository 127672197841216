<template>
  <v-dialog
    v-model="dialog"
    max-width="500"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        v-if="!listItem"
      >
        <v-icon>mdi-application-import</v-icon>
      </v-btn>
      <v-list-item
        link
        v-bind="attrs"
        v-on="on"
        v-if="listItem"
      >
        <v-list-item-title>Import from Linktree</v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title>
        {{ title ? title : "Import from Linktree" }}
      </v-card-title>
      <v-card-text>
        {{ text ? text : "This is a beta feature that accesses your linktree account and attempts to extract and apply your linktree links to your BouncePage" }}
      </v-card-text>
      <v-card-actions>
          <v-card-text class="pt-4">
        <v-text-field 
        v-model="user" 
        label="user" 
        hint="enter the username for your linktree link" 
        required
        prefix='https://linktr.ee/'
        ></v-text-field>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card-text>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="finished"
        >
          Import
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "ltImport",
  props: ["listItem", "title", "text"],
  data() {
    return {
    user: "",
      dialog: false,
    };
  },
  methods: {
    finished() {
      this.$emit("imported", this.user);
      this.dialog = false;
    },
  },
}
</script>
