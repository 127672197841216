<template>
    <v-container>
        <v-app-bar
            app
            color="black"
            flat
            >
            <v-container class="py-0 fill-height">
                <a href="https://bounce.page">
                <v-img
                    max-height="80"
                    max-width="200"
                    src="../assets/img/mark-circle.svg"
                ></v-img>
                </a>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    :to="{ name: 'login'}"
                >
                    Sign in
                </v-btn>
            </v-container>
        </v-app-bar>
        <v-container class="mt-15">
        <v-row class="justify-center d-flex">
            <v-col class="login">
                <v-card
                    shaped
                >
                    <v-card-title>
                        Forgot your Password?
                    </v-card-title>
                    <v-card-text v-if="!resetsent">
                        <p>
                            We'll sort it out. <br />Enter the email address associated with your account, and we'll send you a link to reset your password.
                        </p>
                        <v-alert
                            border="left"
                            dense
                            type="error"
                            v-if="error"
                        >{{error.message}}</v-alert>
                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                        >
                            <v-text-field
                                v-model="reset.email"
                                label="Email Address"
                                required
                            ></v-text-field>

                            <v-card-actions class="d-flex justify-end">
                                <v-btn
                                    text
                                    small
                                    color="secondary"
                                    @click="() => this.$router.push({name: 'login'})"
                                >
                                    Return to Sign In
                                </v-btn>
                                <v-btn
                                    color="primary"
                                    @click="requestReset"
                                    :disabled="reset.email.length == 0"
                                >
                                    Reset Password
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card-text>
                    <v-card-text v-else>
                        <p>Password reset successfully requested. Please check your email for a reset link.</p>
                    </v-card-text>
                    <v-overlay
                        :value="isLoading"
                    >
                        <v-progress-circular
                            indeterminate
                            size="64"
                        ></v-progress-circular>
                    </v-overlay>
                </v-card>
            </v-col>
        </v-row>
        </v-container>
    </v-container>
</template>
<script>
import axios from 'axios';
import bugsnagClient from '../utils/bugsnag';

export default {
    name: 'Forgot',
    data() {
        return {
            context: 'forgot',
            reset: {
                email: ''
            },
            isLoading: false,
            resetsent: false,
            error: null,
            valid: true,
        };
    },
    methods: {
        requestReset() {
            if (this.reset.email == '') {
                this.error = "Missing required email address";
                return;
            }
            this.isLoading = true;
            axios.post(`${process.env.VUE_APP_PAIZA_ENDPOINT}/auth/requestreset`, this.reset)
            .then((res) => {
                this.resetsent = true;
            })
            .catch((err) => {
                bugsnagClient.notify(err);
                this.$notify({
                    type: 'error',
                    title: 'Failed to reset password.',
                    text: 'Please try again later.'
                })
            })
            .finally(() => {
                this.isLoading = false;
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .login {
        max-width: 600px;
    }
    .btn {
        margin-top:1rem;
    }
</style>
