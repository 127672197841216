<template>
  <v-dialog
    v-model="dialog"
    max-width="290"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        v-if="!listItem"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-list-item
        link
        v-bind="attrs"
        v-on="on"
        v-if="listItem"
        class="red"
      >
        <v-list-item-title>Delete</v-list-item-title>
      </v-list-item>
    </template>
    <v-card>
      <v-card-title>
        {{ title ? title : "Delete element?" }}
      </v-card-title>
      <v-card-text>
        {{ text ? text : "Are you sure you want to delete this element?" }}
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          text
          @click="dialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          text
          @click="finished"
        >
          Delete
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "DeleteDialog",
  props: ["listItem", "title", "text"],
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    finished() {
      this.$emit("finished");
      this.dialog = false;
    },
  },
}
</script>
