<template>
<div class="confirmDialog">
    {{message}}
    <div class="buttonHolder">
        <o-button buttonStyle="alt" @click.native="optionSelected(false)">Cancel</o-button>
        <o-button @click.native="optionSelected(true)">{{primaryButtonTitle}}</o-button>
    </div>
</div>
</template>

<script>
import Vue from 'vue';
import OButton from './OButton';

export default {
  name: 'ConfirmDialog',
  props: {
    finishedCallback: {
        default: () => {}
    },
    message: {
        default: 'Are you sure?',
        type: String
    },
    primaryButtonTitle: {
        default: 'Ok',
        type: String
    }
  },
  components: {
      OButton
  },
  methods: {
    optionSelected(confirmed) {
        if (this.finishedCallback) this.finishedCallback(confirmed);
        this.$emit('close')
    }
  }
}
</script>
<style lang="scss" scoped>
  .confirmDialog {
      padding: 16px;
      text-align: left;
      display: flex;
      font-size: 18px;
      color: white;
  }
  .buttonHolder {
      position: absolute;
      bottom: 16px;
      display: flex;
      right: 16px;
      .obutton {
          margin-left: 8px;
      }
  }
</style>
